import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import * as EpicAutoLinkTemplate from 'app/client/core/views/templates/epicAutoLink.html?caveman';
import * as InlineGroupMentionTemplate from 'app/client/core/views/templates/inlineGroupMention.html?caveman';
import * as InlineMentionNotFoundTemplate from 'app/client/core/views/templates/inlineMentionNotFound.html?caveman';
import * as InlineUserMentionTemplate from 'app/client/core/views/templates/inlineUserMention.html?caveman';
import * as LoadingAutoLinkTemplate from 'app/client/core/views/templates/loadingAutoLink.html?caveman';
import * as StoryAutoLinkTemplate from 'app/client/core/views/templates/storyAutoLink.html?caveman';
import * as VideoEmbedIframeTemplate from 'app/client/core/views/templates/videoEmbedIframe.html?caveman';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import BaseUtils from '../_frontloader/baseUtils';
import Constants from './constants';
import EpicModel from '../models/epic';
import FigmaController from '../controllers/figma';
import MiroController from '../controllers/miro';
import Iterate from './iterate';
import Log from './log';
import ProfileModel from '../models/profile';
import GroupModel from '../models/group';
import StoryLookupController from '../controllers/storyLookup';
import StoryModel from '../models/story';
import OrganizationModel from '../models/organization';
import Utils from './utils';
import { innerTextMatchesMiroLink, isMiroLink } from '@clubhouse/shared/utils/miroLiveEmbed';
import { getLoomEmbedUrl, isLoomLink } from '@clubhouse/shared/utils/loomLiveEmbed';
import { getYoutubeEmbedUrl, isYoutubeLink } from '@clubhouse/shared/utils/youtubeLiveEmbed';
import Files from './files';
import { _parseAroundCodeTags, _parseOutsideCodeTags, _splitByClosingCodeTag, _splitByCodeTag, emojify, sanitize, sanitizeAndEmojify } from '@clubhouse/shared/utils/format';
import _ from 'lodash';
const exports = {};
const MENTION_LINK_MARKDOWN_PATTERN = /\[@([^\]]+)\]\((?:shortcutapp|clubhouse)\:\/\/(?:members|groups)\/([^)]+)\)/gi;
const MENTION_LINK_HTML_PATTERN = /<a(?!=href)[^>]*?href="(?:shortcutapp|clubhouse)\:\/\/(members|groups)\/([^"]*)"[^>]*?>@(.*?)<\/a>/gi;
const NAKED_MENTION_LINK_PATTERN = /@([\w\-\.]*[\w\-])/g;

// We include both old and new prefixes in the set to consider for formatting and autocompletion
// detection in both bundles so we can support:
// - existing content that uses the old `ch` prefix
// - existing users that are used to the `ch` prefix
// - content created on shortcut but viewed on clubhouse during the transition period
//
// However, we swap the order based on the bundle of the brand, since the first prefix
// is used when rewriting the story number fragment on auto-complete, and we want to use
// the default prefix for the current brand.
exports.STORY_PREFIXES = [BRAND.STORY_NUMBER_PREFIX, BRAND.OTHER_BRAND_STORY_NUMBER_PREFIX, '#'];
exports.STORY_PREFIXES_RE = new RegExp(`^(${exports.STORY_PREFIXES.join('|')})[0-9]+$`);
exports.sanitizeAndEmojify = sanitizeAndEmojify;
exports.sanitize = sanitize;
exports.emojify = emojify;
exports._parseOutsideCodeTags = _parseOutsideCodeTags;
exports._splitByCodeTag = _splitByCodeTag;
exports._splitByClosingCodeTag = _splitByClosingCodeTag;
exports.pluralize = (num, singular, plural, prefixSingular, prefixPlural) => {
  const prefix = prefixSingular && prefixPlural ? num === 1 ? prefixSingular + ' ' : prefixPlural + ' ' : '';
  return prefix + (num || 0) + ' ' + (num === 1 ? singular : plural);
};
exports.pluralizeWord = (count, singular, plural) => count === 1 ? singular : plural;
exports.fullName = (user, userMakingChange, fallbackSuggestion) => {
  const isCurrentUser = ProfileModel.isCurrentProfile(user);
  const userID = _.get(user, 'id');
  const fallback = fallbackSuggestion || 'Somebody';
  if (!userID) {
    return fallback;
  }

  // This is used by activity feed templates, e.g. "You added yourself as an owner"
  if (_.get(userMakingChange, 'id') === userID) {
    return isCurrentUser ? 'yourself' : 'themselves';
  }
  const fullName = isCurrentUser ? 'You' : _.get(user, 'name', _.get(user, 'profile.name', fallback));
  return exports.sanitize(fullName);
};
exports.timePeriodInMinutes = minutes => {
  const hours = minutes / 60;
  const days = hours / 24;
  if (minutes < 60) {
    return exports.pluralize(Math.round(minutes), 'minute', 'minutes');
  }
  if (hours < 24) {
    return exports.pluralize(Math.round(hours), 'hour', 'hours');
  }
  return exports.pluralize(Math.round(days), 'day', 'days');
};
exports.currentTime = (date // Accepts a moment object
) => {
  return date.format(Constants.SHORT_DATE_TIME_NO_YEAR);
};
exports.sanitizeHref = str => {
  return Utils.exists(str) ? (str + '').replace('javascript:', '').replace('vbscript:', '').replace('data:', '') : '';
};
exports.sanitizeColor = (color, fallback) => {
  // Current fallback values are derived from color
  // constants we have and don't need to be sanitized
  return exports._isValidColor(color) ? color : fallback;
};
exports._isValidColor = (color = '') => {
  if (!color) {
    // Catch falsy values. TODO: Check why the default argument isn't catching this
    return false;
  } else if (color.indexOf(';') !== -1) {
    // Catch ending the style early to inject other properties
    return false;
  } else if (color.charAt(0) === '#') {
    // Match Hex values at correct lengths
    const trimmedColor = color.substring(1);
    return [3, 4, 6, 8].indexOf(trimmedColor.length) > -1 && !isNaN(Number.parseInt(trimmedColor, 16));
  } else {
    /* Regex to catch the rest, explained below:
      /(
           (rgb|hsl)a?                         Match hsl, rgb, hsla, or rgba
           \(                                  Match (
              (
                 ?:\d+%?(?:deg|rad|grad|turn)?    Match 0, 0%, 00%, 0deg, 0rad, etc.
                 (?:,|\s)+                        Match a comma and/or space to separate them
              ){2,3}                           Repeat 2 or 3 times
              [\s\/]*[\d\.]+%?                 Match the last value, period and slash allowed
           \)                                  Match )
      )/
    */
    return /(rgb|hsl)a?\((\d+%?(deg|rad|grad|turn)?(?:,|\s)+){2,3}[\s\/]*[\d\.]+%?\)/i.test(color);
  }
};
exports._addMentionsToText = str => {
  return (str || '').replace(MENTION_LINK_HTML_PATTERN, (wholeMatch, type, id, mentionName) => {
    if (type === 'groups') {
      const group = GroupModel.getById(id);
      return group ? InlineGroupMentionTemplate.render(group) : InlineMentionNotFoundTemplate.render({
        mention_name: mentionName,
        id,
        type
      });
    }

    // TODO: Understand the difference between members and profiles, and which one we want to support here.
    const profile = ProfileModel.getAllDetailsByMemberId(id) || ProfileModel.getAllDetailsById(id);
    return profile ? InlineUserMentionTemplate.render({
      profile
    }) : InlineMentionNotFoundTemplate.render({
      mention_name: mentionName,
      id,
      type
    });
  });
};
exports.getStoryForLink = id => {
  const parsedId = BaseUtils.toNumber(id);
  const story = StoryModel.getById(parsedId);
  if (!story) {
    StoryLookupController.add(parsedId);
    return story;
    // This was a nice-to-have prefetch behavior, but it looks like
    // a specific comment is causing an infinite loop on the dashboard page.
    // This is the comment: https://useshortcut.slack.com/archives/clubhouse-api/p1485121316000007
    // Slack discussion: https://useshortcut.slack.com/archives/general/p1485134282000002
    // } else if (!StoryModel.isFullyLoaded(story)) {
    //   StoryModel.fetchStory(story.id);
  }
  if (!story.story_type_icon) {
    StoryModel.normalize(story);
  }
  return story;
};
exports.getEpicForLink = id => {
  const parsedId = BaseUtils.toNumber(id);
  const epic = EpicModel.getById(parsedId);
  if (epic) {
    return EpicModel.normalize(epic);
  }
};
exports.getCurrentHost = () => {
  return window.location.host;
};
function wrapInStoryLookupSpan(id, wholeMatch, href = '') {
  return `<span data-story-lookup data-id="${id}" data-href="${href}">${wholeMatch}</span>`;
}
function _replaceEpicStoryLink(wholeMatch, urlStr, innerText, source, options) {
  options = options || {};
  let url;
  try {
    url = new URL(urlStr);
  } catch (err) {
    return wholeMatch;
  }

  // Need to replace characters escaped by marked V10 to ensure correct formats are used for comparison below.
  innerText = innerText.replace(/&amp;/g, '&');
  const parsedStoryURL = url.pathname.match(/^\/([^\/]+)\/story\/(\d+)\/?([^\/]+)?$/);
  if (urlIsValidWorkspaceLink(url, parsedStoryURL) && urlStr === innerText) {
    return handleStoryLinkFromURL(url, wholeMatch, parsedStoryURL, options?.renderLoadingIfNotFound);
  }
  const parsedEpicURL = url.pathname.match(/^\/([^/]+)\/epic\/(\d+)\/?([^/]+)?$/);
  if (urlIsValidWorkspaceLink(url, parsedEpicURL) && urlStr === innerText) {
    return handleEpicLinkFromURL(wholeMatch, parsedEpicURL, options?.renderLoadingIfNotFound);
  }

  // Adding Figma iframe parsing in here so we don't have to parse links twice each time.
  if (options.renderEmbeds !== false && FigmaController.isFigmaLink(urlStr) && urlStr === innerText) {
    return FigmaController.render(urlStr);
  }
  if (options.renderEmbeds !== false && isMiroLink(urlStr) && innerTextMatchesMiroLink(urlStr, innerText)) {
    return MiroController.render(urlStr, source);
  }
  if (options.renderEmbeds !== false && isLoomLink(urlStr) && urlStr === innerText) {
    return VideoEmbedIframeTemplate.render({
      urlStr: getLoomEmbedUrl(urlStr)
    });
  }
  if (options.renderEmbeds !== false && isYoutubeLink(urlStr) && urlStr === innerText) {
    return VideoEmbedIframeTemplate.render({
      urlStr: getYoutubeEmbedUrl(urlStr)
    });
  }
  return wholeMatch;
}

// replace a given URL with an HTML video
function _replaceVideoUrl(wholeMatch, urlStr) {
  let url;
  try {
    url = new URL(urlStr);
  } catch (err) {
    return wholeMatch;
  }
  if (Files.isVideo(url.href)) {
    return `<video src=${url.href} width="360" style="display: inline-block; max-width: 100%;">
      Your browser does not support the video tag.
    </video>`;
  }
  return wholeMatch;
}
const _linkRE = /<a.*?href="(.+?)?".*?>(.*?)<\/a>/gi;
// match a story number with one of our prefixes without a non-number suffix
const _storyNumbersRE = new RegExp(`(?:${exports.STORY_PREFIXES.join('|')})([0-9]+)`, 'gi');

// replace regular anchor tag links with other pieces of content
exports.addLinksToText = (str, source, options) => {
  return str.replace(_linkRE, (a, b, c) => _replaceEpicStoryLink(a, b, c, source, options)).replace(_storyNumbersRE, _replaceStoryID).replace(_linkRE, _replaceVideoUrl);
};
function _replaceStoryID(wholeMatch, id, matchIndex, wholeString) {
  // this function intentionally checks from less expensive to more
  // expensive cases to maybe squeeze out a little performance, since
  // this will get called many times for a long string that has lots
  // of Story references.

  if (matchIndex + wholeMatch.length < wholeString.length) {
    const invalidRightChar = wholeString.charAt(matchIndex + wholeMatch.length).match(/[a-zA-Z&\[]/);
    if (invalidRightChar) {
      return wholeMatch;
    }
  }
  if (matchIndex > 0) {
    const invalidLeftChar = wholeString.charAt(matchIndex - 1).match(/[a-zA-Z0-9_\/\-+=.&;\]]/);
    if (invalidLeftChar) {
      return wholeMatch;
    }
  }
  const right = wholeString.slice(matchIndex + wholeMatch.length);
  const isInsideAnchor = right.match(/^[^>]*<\/a>/);
  if (isInsideAnchor) {
    return wholeMatch;
  }
  const left = wholeString.slice(0, matchIndex);
  const isInsideTag = !!(left.match(/<[^>]+$/) && right.match(/^[^>]*>/));
  if (isInsideTag) {
    return wholeMatch;
  }
  const story = exports.getStoryForLink(id);
  if (!story) {
    return wrapInStoryLookupSpan(id, wholeMatch);
  }
  return StoryAutoLinkTemplate.render(story);
}

// This is used when turning epic/story links into "button" links. We
// need to honor both domains, since both can exist in the content.
function urlIsValidWorkspaceLink(url, parsedURL) {
  const [match, workspace, id] = parsedURL || [null, null, null];
  return !!(match && workspace && id) && (url.host === exports.getCurrentHost() || url.host.match(/^(app(-[^.]+)?\.clubhouse\.io|app\.shortcut(-[^.]+)?\.com)$/)) && workspace === OrganizationModel.getCurrent().url_slug;
}
function handleStoryLinkFromURL(url, wholeMatch, parsedURL, showSpinnerIfNotFound) {
  const [,, id] = parsedURL;
  const story = exports.getStoryForLink(id);
  if (story) {
    return StoryAutoLinkTemplate.render({
      ...story,
      href: url.href
    });
  } else if (showSpinnerIfNotFound) {
    return LoadingAutoLinkTemplate.render({
      link: wholeMatch
    });
  } else {
    return wrapInStoryLookupSpan(id, wholeMatch, url.href);
  }
}
function handleEpicLinkFromURL(wholeMatch, parsedURL, showSpinnerIfNotFound) {
  const [,, id] = parsedURL;
  const epic = exports.getEpicForLink(id);
  if (epic) {
    return EpicAutoLinkTemplate.render(epic);
  } else if (showSpinnerIfNotFound) {
    return LoadingAutoLinkTemplate.render({
      link: wholeMatch
    });
  } else {
    return wholeMatch;
  }
}
const replaceCharactersThatCauseMarkedToLoop = str => {
  return str.replace(/(_{39,})/g, (match, p1) => p1.replaceAll('_', '\\_'));
};

/**
 * Ensures up to two new lines are present before fenced code blocks in a Markdown string, except at the beginning.
 * This function adds new lines before fenced code blocks, ensuring there are exactly two new lines if fewer are present.
 * It does not add new lines if the code block is at the start of the Markdown content or if two new lines already precede it.
 * This function specifically targets fenced code blocks delineated by triple backticks.
 *
 * Downside: it's also ensuring two new lines are the closing mark.
 *
 * We need this because of the following issue in marked library:
 * https://github.com/markedjs/marked/issues/3208.
 *
 * @param {string} markdown - The Markdown string to process.
 * @returns {string} The processed Markdown string with ensured spacing before fenced code blocks.
 */
exports.ensureBlankLineBeforeCodeBlocks = markdown => {
  // Regex to match any number of new lines (even none) before each set of triple backticks, including the start of the string
  const regex = /(^|\n{1,2})```/g;
  return markdown.replace(regex, (match, p1) => {
    // Determine if the match is at the start of the string or has fewer than two new lines
    const isStartOfString = match.startsWith('```');
    const newLinesNeeded = p1.length < 2 ? '\n'.repeat(2 - p1.length) : '';

    // If at the start of the string, don't add new lines. Otherwise, add up to two new lines.
    return isStartOfString ? match : `${p1}${newLinesNeeded}\`\`\``;
  });
};
exports.markdownify = (str, source = 'unknown', options) => {
  options = options || {};
  if (_.isString(str)) {
    try {
      if (options.inline) {
        // Strip new line characters (shouldn't matter for inline element parsing)
        // GitHub issue: https://github.com/chjj/marked/issues/824
        str = str.replace(/\n/g, '');
        str = marked.parseInline(replaceCharactersThatCauseMarkedToLoop(str));
      } else {
        str = marked.parse(exports.ensureBlankLineBeforeCodeBlocks(replaceCharactersThatCauseMarkedToLoop(str)));
      }
    } catch (e) {
      Log.error(e, {
        str,
        inline: !!options.inline
      });
    }

    // Markdown links inside code blocks aren't converted to html links.
    // While we parse around backticks, these strip back custom markdown
    // links for mentions parsed within an indented code block.
    str = exports._parseInsideCodeTags(str, exports.convertCustomMarkdownMentionsToNaked);
    str = exports._parseOutsideCodeTags(str, _parseHTMLBrs);
    str = exports._parseOutsideCodeTags(str, (...props) => _parseDecorations(...props, source, options));
    str = exports.emojify(str);
    str = DOMPurify.sanitize(str, {
      ADD_TAGS: ['iframe', 'em-emoji'],
      ADD_ATTR: ['allowfullscreen', 'target', 'shortcodes'],
      FORBID_TAGS: ['form', 'style'],
      ALLOWED_URI_REGEXP: /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|cid|xmpp|xxx|outlook|zpl):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i
    });
  }
  return str;
};

// Support emoji, mentions, story links and inline markdown elements.
exports.formatWithLimitedMarkdown = (str, source) => {
  if (_.isString(str)) {
    // Replace code blocks with inline code blocks.
    str = str.replace(/```/g, '`');
    str = _parseOutsideBackticks(str, exports.sanitize);
    str = exports.markdownify(str, source);
  }
  return str;
};
function _parseHTMLBrs(str) {
  return str.replace(/&lt;br\s?\/?&gt;/g, '<br/>');
}
function _parseDecorations(str, source, options) {
  if (_.isString(str)) {
    str = exports.addLinksToText(str, source, options);
    str = exports._addMentionsToText(str);
  }
  return str;
}
exports.parseOutsideMarkdownCodeBlocks = (str, fn) => {
  const chunks = str.split(/```/g);
  const parsed = _parseOddItems(chunks, chunk => {
    return _parseOutsideBackticks(chunk, fn);
  });
  return parsed.join('```');
};
function _parseOutsideBackticks(str, fn) {
  const chunks = str.split(/`/g);
  const parsed = _parseOddItems(chunks, fn);
  return parsed.join('`');
}
function _parseOddItems(chunks, fn) {
  const parsed = [];
  Iterate.each(chunks, (chunk, i) => {
    parsed.push(i % 2 ? chunk : fn(chunk));
  });
  return parsed;
}
exports._parseInsideCodeTags = (str, fn) => {
  return _parseAroundCodeTags(str, fn, true);
};
exports.emojiNameToEmoji = name => {
  return exports.emojify(':' + name + ':');
};
exports.convertCustomMarkdownMentionsToNaked = str => {
  return (str || '').replace(MENTION_LINK_MARKDOWN_PATTERN, (wholeMatch, mentionName) => {
    return '@' + mentionName;
  });
};
exports.generateCustomMentionMarkdown = entity => {
  const type = entity.entity_type === 'group' ? 'groups' : 'members';
  return `[@${entity.mention_name}](shortcutapp://${type}/${entity.id})`;
};
exports.createMentionMapping = (markdownLink, mentionName, id) => {
  return {
    formatted: markdownLink,
    mention_name: mentionName,
    id
  };
};
exports.extractMentionDetails = str => {
  return _.map(str.match(MENTION_LINK_MARKDOWN_PATTERN), mentionLink => {
    const parts = new RegExp(MENTION_LINK_MARKDOWN_PATTERN).exec(mentionLink);
    return exports.createMentionMapping(mentionLink, parts[1], parts[2]);
  });
};
exports.getAllNakedMentions = (str = '') => {
  return _.map(str.match(NAKED_MENTION_LINK_PATTERN), mention => {
    return mention.substr(1); // Strip @
  });
};
exports.toReadableList = list => {
  const _list = [...list];
  const lastItem = _list.pop();
  const commaSeparatedItems = _list.join(', ');
  return commaSeparatedItems ? `${commaSeparatedItems} and ${lastItem}` : lastItem;
};
export { exports as default };