import "core-js/modules/es.array.push.js";
import { resetPageCSS } from 'app/client/core/js/modules/resetPageCSS';
import css from 'app/client/oauth/less/app.less?url';
import ApplicationModel from '../../../core/js/models/application';
import Async from '../../../core/js/modules/async';
import Boot from '../../../core/js/modules/boot';
import InstallationModel from '../../../core/js/models/installation';
import Layout from '../../../core/js/modules/layout';
import OAuthModel from '../../../core/js/models/oAuth';
import OauthPageController from '../controllers/oauthPage';
import OrganizationModel from '../../../core/js/models/organization';
import UserModel from '../../../core/js/models/user';
import Url from '../../../core/js/modules/url';
import NotionIntegration from '../../../integrations/js/controllers/notionIntegration';
import ZendeskIntegration from '../../../integrations/js/controllers/zendeskIntegration';
import * as Event from '../../../core/js/_frontloader/event';
const exports = {};
const _init = () => {
  Layout.renderContent('<div id="auth"></div>');

  // If we are authing Notion, we don't need to grab any dependencies, so return early
  const authUrl = Url.parsePath().auth;
  if (authUrl === 'notion') {
    NotionIntegration.renderInterstitial();
    Event.trigger('pageRendered', 'OauthPage');
    return;
  }
  if (authUrl === 'zendesk') {
    ZendeskIntegration.renderInterstitial();
    Event.trigger('pageRendered', 'OauthPage');
    return;
  }
  const dependencies = [ApplicationModel.fetchAll, UserModel.fetchAll];
  if (OrganizationModel.getAllActive().length === 1) {
    // If there's only 1 org, we don't need to ask which org before fetching.
    dependencies.push(InstallationModel.fetchAll, OAuthModel.fetchAll);
  }
  Async.eachInParallelThen(dependencies, () => {
    OauthPageController.render();
  });
};
const _reInit = _init;
exports.boot = async () => {
  await resetPageCSS(css);
  Boot.initializePage(_init, _reInit);
};
export { exports as default };