import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import Collection from '../_frontloader/collection';
import ColumnModel from './column';
import EventModel from './event';
import Iterate from '../modules/iterate';
import Log from '../modules/log';
import Utils from '../modules/utils';
import filter from 'lodash/filter';
import _ from 'lodash';
const exports = {};

/*

Event definition entities:

{
  "id": "4058.github/pull-request/open.master",
  "event_id": 4058,
  "disabled": false,
  "name": "Pull request opened",
  "type": "github/pull-request/open",
  "match_name": "master",
  "workflow_state_name": "Ready for Review",
  "workflow_state_id": 2199
},
{
  "id": "4059.github/branch/merge.master,
  "event_id": 4059,
  "disabled": false,
  "name": "Branch merged",
  "type": "github/branch/merge",
  "match_name": "master",
  "workflow_state_name": "Ready for Deploy",
  "workflow_state_id": 1495
}

*/

Collection.create('EventDefinition', exports);
const TYPES = [{
  id: 'branchAssociated',
  type: '/branch/associated',
  name: 'Branch associated with Story',
  match_type: 'none',
  providers: ['bitbucket', 'github', 'gitlab']
}, {
  id: 'commitAssociated',
  type: '/commit/associated',
  name: 'Commit associated with Story',
  match_type: 'none',
  providers: ['bitbucket', 'github', 'gitlab']
}, {
  id: 'pullOpen',
  type: '/pull-request/open',
  name: 'Pull request opened',
  match_type: 'branch',
  providers: ['bitbucket', 'github', 'gitlab']
}, {
  id: 'pullLabeled',
  type: '/pull-request/labeled',
  name: 'Pull request labeled',
  match_type: 'label',
  // bitbucket doesn't support labels
  providers: ['github', 'gitlab']
}, {
  id: 'reviewRequested',
  type: '/pull-request/review-requested',
  name: 'Pull request review requested',
  match_type: 'branch',
  // gitlab doesn't report to us when a reviewer is assigned
  providers: ['bitbucket', 'github']
}, {
  id: 'pullApproved',
  type: '/pull-request/approved',
  name: 'Pull request approved',
  match_type: 'branch',
  providers: ['bitbucket', 'github', 'gitlab']
}, {
  id: 'branchMerge',
  type: '/branch/merge',
  name: 'Branch merged',
  match_type: 'branch',
  providers: ['bitbucket', 'github', 'gitlab']
}];
exports.typesForProvider = provider => filter(TYPES, t => t.providers.includes(provider));
exports.transformFromEvent = evt => {
  Iterate.each(evt.definition, definition => {
    const column = ColumnModel.getById(definition.workflow_state_id);
    if (!column) {
      Log.error(new Error('Column not found from event definition'), {
        definition
      });
      return false;
    }
    const team = ColumnModel.getTeamFromColumn(column);
    const eventType = _.find(TYPES, type => {
      return evt.name.match(type.type);
    });
    const obj = {
      id: `${evt.id}.${evt.name}.${Utils.slugify(definition.match_name, {
        toLowerCase: false
      })}`,
      event_id: evt.id,
      disabled: evt.disabled,
      name: eventType.name,
      type: evt.name,
      match_name: definition.match_name,
      match_type: eventType.match_type,
      team_name: team.name,
      team_id: team.id,
      workflow_state_id: definition.workflow_state_id,
      workflow_state_name: column ? column.name : '<em>Unknown</em>'
    };
    exports.update(obj);
  });
};
exports.transformAll = () => {
  exports.flush();
  EventModel.each(evt => {
    exports.transformFromEvent(evt);
  });
  exports.flush(exports.sortBy('match_name'));
};
exports.getMatchingDefinition = (evt, state) => {
  return _.find(evt.definition, {
    match_name: state.match_name
  });
};
exports.getDefinitionsForWorkflowState = state => {
  return exports.filter({
    workflow_state_id: state.id
  });
};
exports.getDefinitionsForType = type => {
  return _.sortBy(exports.filter(evt => {
    return evt.type.match('^' + type);
  }), 'type');
};
exports.saveNew = (state, callback) => {
  const evt = EventModel.get({
    name: state.type,
    team_id: state.team_id
  });
  if (evt) {
    if (evt.disabled) {
      EventModel.restoreEvent(evt, (err, evt) => {
        if (err) {
          callback(err);
        } else {
          exports.addDefinitionToExistingEvent(evt, state, callback);
        }
      });
    } else {
      exports.addDefinitionToExistingEvent(evt, state, callback);
    }
  } else {
    const newEvent = {
      name: state.type,
      definition: [{
        match_name: state.match_name,
        workflow_state_id: state.workflow_state_id
      }],
      team_id: state.team_id
    };
    EventModel.saveNew(newEvent, callback);
  }
};
exports.addDefinitionToExistingEvent = (evt, state, callback) => {
  if (exports.getMatchingDefinition(evt, state)) {
    callback('This event handler already exists.');
  } else {
    const newDefinition = evt.definition.slice();
    newDefinition.push({
      match_name: state.match_name,
      workflow_state_id: state.workflow_state_id
    });
    EventModel.updateEvent(evt, {
      definition: newDefinition
    }, callback);
  }
};
exports.updateWorkflowState = (eventDefinition, workflow_state_id, callback) => {
  const evt = EventModel.getById(eventDefinition.event_id);
  Iterate.each(evt.definition, d => {
    if (d.match_name === eventDefinition.match_name) {
      d.workflow_state_id = workflow_state_id;
    }
  });
  EventModel.updateEvent(evt, {
    definition: evt.definition
  }, callback);
};
exports.deleteEventDefinition = (eventDefinition, callback) => {
  const evt = EventModel.getById(eventDefinition.event_id);
  _.remove(evt.definition, {
    match_name: eventDefinition.match_name
  });
  EventModel.updateEvent(evt, {
    definition: evt.definition
  }, callback);
};

/*
-  exports.disableExistingEvent = function (name, callback) {
-    var evt = exports.get({ name: name });
-    if (evt) {
-      exports.deleteEvent(evt, callback);
-    } else {
-      callback(false);

*/

export { exports as default };