import { resetPageCSS } from 'app/client/core/js/modules/resetPageCSS';
import css from 'app/client/story/less/app.less?url';
import ApplicationState from '../../../core/js/modules/applicationState';
import Boot from '../../../core/js/modules/boot';
import ConsolidatedFetch from '../../../core/js/modules/consolidatedFetch';
import * as Event from '../../../core/js/_frontloader/event';
import Is from '../../../core/js/modules/is';
import Layout from '../../../core/js/modules/layout';
import Lightbox from '../../../core/js/modules/lightbox';
import StoryHistoryModel from '../../../core/js/models/storyHistory';
import StoryModel from '../../../core/js/models/story';
import StoryStoryController from '../controllers/storyStory';
import Updates from '../../../core/js/modules/updates';
import Url from '../../../core/js/modules/url';
import View from '../../../core/js/modules/view';
const exports = {};
const _init = () => {
  Layout.renderContent('<div id="story"></div>');
  ApplicationState.setLastView();
  Lightbox.loadLibraryIfMissing();
  Updates.init(_handleUpdates);
  _fetchDataNeededToRenderPage(() => {
    StoryStoryController.render();
  });
  View.genericResizeHandler();
  Event.on('resize storyEditorAdded', View.genericResizeHandler);
};
function _fetchDataNeededToRenderPage(callback) {
  const path = Url.parsePath();
  const storyID = path.story;
  ConsolidatedFetch.fetchConsolidatedDataAndActivity(() => {
    StoryModel.fetchStory(storyID, () => {
      if (!Is.mobile()) {
        StoryHistoryModel.fetch(StoryModel.getById(storyID));
      }
      callback();
    });
  });
}
function _handleUpdates() {
  StoryStoryController.updateInPlace();
}
exports.boot = async () => {
  await resetPageCSS(css);
  Boot.initializePage(_init, _init);
};
export { exports as default };