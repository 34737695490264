import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import * as CodeBlockControlsTemplate from 'app/client/core/views/templates/codeBlockControls.html?caveman';
import ApplicationState from '../modules/applicationState';
import { copyToClipboard } from '@clubhouse/shared/utils/copyToClipboard';
import { success, error } from 'utils/message';
import _ from 'lodash';
const exports = {};
const APPLICATIONSTATE_KEY = 'codeBlock.wordWrapSetting';
const HTML_CLASS_NAME = 'word-wrapped-code';
const DEFAULT_SETTING = false; // default to wrapped

exports.init = () => {
  if (_getWordWrapSetting()) {
    $('html').addClass(HTML_CLASS_NAME);
  } else {
    $('html').removeClass(HTML_CLASS_NAME);
  }
};
exports.insertControls = el => {
  const context = el ? $(el) : $('.markdown-formatted');
  const html = CodeBlockControlsTemplate.render({
    isWordWrapped: _getWordWrapSetting()
  });
  context.find('.code-block-controls').remove();
  context.find('pre code').each(function () {
    $(this).after(html);
  });
};
exports.clear = () => {
  return ApplicationState.set(APPLICATIONSTATE_KEY, null);
};
function _getWordWrapSetting() {
  const setting = ApplicationState.get(APPLICATIONSTATE_KEY);
  return _.isBoolean(setting) ? setting : DEFAULT_SETTING;
}
function _setWordWrapSetting(value) {
  return ApplicationState.set(APPLICATIONSTATE_KEY, value);
}

// ----- Actions -----

exports.copy = e => {
  const $btn = $(e.target);
  const $codeBlock = $btn.closest('pre').find('code');
  const text = $codeBlock.text();
  if (copyToClipboard(text)) success('Copied to clipboard!');else error('Could not copy to clipboard...');
  return false;
};
exports.toggle = () => {
  const wordWrapEnabled = !_getWordWrapSetting();
  _setWordWrapSetting(wordWrapEnabled);
  exports.init();
  exports.insertControls();
  return false;
};
export { exports as default };