import Settings from 'app/client/core/js/modules/settings';
import Url from 'app/client/core/js/modules/url';
import View from 'app/client/core/js/modules/view';
import { Savio } from 'pages/integrations/Savio';
const exports = {};
exports.title = `Savio Integration | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/settings/integrations/savio';
};
exports.open = async () => {
  Settings.open({
    template: View.componentToTemplate(Savio, 'integrations-savio'),
    route: exports.route,
    title: exports.title
  });
  return false;
};
export { exports as default };