import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import { useEffect, useMemo, useState } from 'react';
import ProfileModel from 'app/client/core/js/models/profile';
import { useEntities, useEntity } from './collection';
export const findProfileByEmail = email => ProfileModel.findBy({
  email_address: email
});
export const getProfileByUsername = username => ProfileModel.getDetailsByUsername(username);
export const getProfileByGlobalId = globalId => ProfileModel.get({
  global_id: globalId
});
export const getProfileById = id => ProfileModel.getById(id);
export const getProfilesByIds = ids => ProfileModel.mapIDsToProfileDetails(ids);
export const getAllActiveProfileDetails = (...args) => ProfileModel.getAllActiveProfileDetails(...args);
export const getAllActiveProfileDetailsAndSpeciallyInclude = (...args) => ProfileModel.getAllActiveProfileDetailsAndSpeciallyInclude(...args);
export const getAllActiveOwners = id => ProfileModel.getAllActiveOwners(id);
export const getCurrentUserProfileDetails = (...args) => ProfileModel.getCurrentUserProfileDetails(...args);
export const getAllDetailsById = (...args) => ProfileModel.getAllDetailsById(...args);
export const getProfileDetails = rawProfile => ProfileModel.mapProfileDetails(rawProfile);
export const isActiveProfile = profile => Boolean(profile) && !profile.disabled && !profile.deactivated;
export const updateProfile = profile => new Promise((resolve, reject) => ProfileModel.updateProfile(profile, (err, res) => {
  if (err) reject(err);else resolve(res);
}));
export const useProfiles = ({
  ids
} = {}) => {
  const {
    entities
  } = useEntities({
    model: ProfileModel
  });
  const filtered = useMemo(() => {
    if (!ids) return entities;
    return entities.filter(details => {
      if (!profileMatchesIds(details, ids)) return false;

      // Add additional filters here

      return true;
    });
  }, [entities, ids]);
  const profiles = filtered.map(getProfileDetails);
  return {
    profiles
  };
};
export const useCurrentProfile = () => {
  useEntity({
    model: ProfileModel,
    id: getCurrentUserProfileDetails()?.id
  });
  return getCurrentUserProfileDetails();
};
const profileMatchesIds = ({
  id
}, ids) => {
  return ids.includes(id);
};
export const useOptimizedProfiles = ({
  filter
}) => {
  // TODO: This should use useOptimizedEntities, but currently doesn't work because it requires the full object for rendering
  let {
    entities: profiles
  } = useEntities({
    model: ProfileModel
  });
  if (filter) {
    profiles = filter(profiles);
  }
  return profiles.map(getProfileDetails);
};
export const useGetCurrentUserProfileDetails = () => {
  const [user, setUser] = useState(getCurrentUserProfileDetails());

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!user) {
      setUser(getCurrentUserProfileDetails());
    }
    /** If `user` is not available on initial render,
     *  we only want to attempt to "refetch" the user once before "giving up".
     **/
  }, []);
  return user;
};
export const groupStoriesByOwnerId = ({
  stories = []
}) => {
  return stories.reduce((acc, story) => {
    const profiles = getProfilesByIds(story.owner_ids || []);
    profiles.forEach(profile => {
      const current = acc[profile?.id];
      acc[profile?.id] = {
        ...current,
        profile,
        count: (current?.count || 0) + 1
      };
    });
    return acc;
  }, {});
};
export const getAllRoles = () => ProfileModel.getAllRoles();