import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.some.js";
import GroupModel from 'app/client/core/js/models/group';
import TeamModel from 'app/client/core/js/models/team';
import WorkflowModel from 'app/client/core/js/models/workflow';
import ApplicationState from 'app/client/core/js/modules/applicationState';
import { useTeamScopeId } from 'components/team-navigation';
import { useAppState } from 'utils/appState';
import { getCurrentPage } from 'utils/navigation';
import { useEntities } from './collection';
import { getTeamByTeamScopedId, useTeamScopedTeam } from './group';
export const getById = id => TeamModel.getById(id);
export const getByIdOrDefault = id => getById(id) || TeamModel.get();
export const hasTeams = () => TeamModel.size() > 1;
export const getActive = context => TeamModel.getActive(context);
export const setActive = (team, context) => TeamModel.setActive(team, context);
export const setActiveTeamById = (teamId, context) => TeamModel.setActiveTeamById(teamId, context);
export const getAll = () => TeamModel.all();
export const useTeams = () => {
  const {
    entities: teams
  } = useEntities({
    model: TeamModel
  });
  return {
    teams
  };
};
export const useTeamScopedWorkflows = () => {
  const {
    teams: workflows
  } = useTeams();
  const currentTeam = useTeamScopedTeam();
  return getWorkflowsFilteredByTeam(currentTeam, workflows);
};

/*
 * @deprecated React components should use useTeamScopedWorkflows instead
 */
export function deprecatedGetTeamScopedWorkflowsForNonReactAccess() {
  const currentGroup = getTeamByTeamScopedId();
  const all = TeamModel.all();
  return getWorkflowsFilteredByTeam(currentGroup, all);
}
export function getWorkflowsFilteredByTeam(team, workflows) {
  if (!team) return workflows;
  return workflows.filter(({
    workflow
  }) => (team.workflow_ids ?? []).includes(workflow.id));
}
export function getWorkflowStatesForTeam(team) {
  return TeamModel.getWorkflowStatesForTeam(team);
}
export function useDefaultTeamScopedWorkflow() {
  const workflows = useTeamScopedWorkflows();
  const [allWorkActiveWorkflowId] = useAppState({
    appStateKey: 'ActiveTeam.stories'
  });
  return workflows.find(workflow => workflow.id === allWorkActiveWorkflowId) ?? workflows[0];
}

/* @deprecated React components should use useDefaultTeamScopedWorkflow */
export function deprecatedGetDefaultTeamScopedWorkflowForNonReactAccess() {
  const workflows = deprecatedGetTeamScopedWorkflowsForNonReactAccess();
  const allWorkActiveWorkflowId = ApplicationState.get('ActiveTeam.stories');
  return workflows.find(workflow => workflow.id === allWorkActiveWorkflowId) ?? workflows[0];
}
export const useActiveTeam = context => {
  let page = context || getCurrentPage();
  const {
    value: teamId
  } = useTeamScopeId();
  const defaultWorkflow = useDefaultTeamScopedWorkflow();
  const appStateKey = TeamModel.getWorkflowApplicationStateKey(teamId, page);

  // This can happen if user opens a story in a new tab and closes it.
  if (page === 'story') {
    page = 'stories';
  }
  return useAppState({
    appStateKey,
    default: defaultWorkflow?.id
  });
};
export const getLabel = isPlural => TeamModel.getLabel({
  isPlural
});
export const createNewWorkflow = async team => await TeamModel.Promises.saveNew(team);
export const deleteWorkflow = async team => TeamModel.Promises.deleteTeam(team);
export const updateWorkflow = async (team, updates, selectedGroups) => {
  await TeamModel.Promises.updateTeam(team, updates);
  await _updateWorkflowGroups(team.workflow.id, selectedGroups, 'workflow update');
};
export const updateWorkflowGroups = async (team, groupSelection, teamUpdated = 'workflow update') => await _updateWorkflowGroups(team.workflow.id, groupSelection, teamUpdated);
const _updateWorkflowGroups = (workflowId, groupSelection, teamUpdated) => {
  const alreadyAssociatedGroups = WorkflowModel.getAssociatedGroups(workflowId);
  const removalUpdates = alreadyAssociatedGroups.filter(group => !groupSelection.some(selected => selected.id === group.id)).map(({
    id,
    workflow_ids
  }) => {
    const filteredWorkflowIds = workflow_ids.filter(wId => Boolean(WorkflowModel.getById(wId)) && wId !== workflowId);
    return GroupModel.Promises.updateGroupWorkflows({
      id,
      workflow_ids: filteredWorkflowIds
    }, {
      teamUpdated
    });
  });
  const additionUpdates = groupSelection.filter(selected => !alreadyAssociatedGroups.some(group => group.id === selected.id)).map(({
    id,
    workflow_ids
  }) => {
    const existingWorkflowIds = workflow_ids.filter(wId => Boolean(WorkflowModel.getById(wId)));
    return GroupModel.Promises.updateGroupWorkflows({
      id,
      workflow_ids: [...existingWorkflowIds, workflowId]
    }, {
      teamUpdated
    });
  });
  return Promise.all([...additionUpdates, ...removalUpdates]);
};
export const fetchAllTeams = () => new Promise(resolve => TeamModel.fetchAll(resolve));