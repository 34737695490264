import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.some.js";
import CompanyModel from 'app/client/core/js/models/company';
import UserModel from 'app/client/core/js/models/user';
import { useEntities, useEntity } from './collection';
import { useCurrentOrg } from './organization';
export const useCompany = id => {
  const {
    entity
  } = useEntity({
    model: CompanyModel,
    id
  });
  return entity;
};
export const useCurrentCompanyId = () => {
  const {
    org
  } = useCurrentOrg();
  const company = org && CompanyModel.getFromOrg(org);
  return company?.id;
};
export const useCurrentCompany = () => {
  const id = useCurrentCompanyId();
  return useCompany(id);
};
export const useCompanies = () => {
  const {
    entities
  } = useEntities({
    model: CompanyModel
  });
  return entities;
};
export const useCompanyByOrg = orgId => {
  const companies = useCompanies();
  return companies.find(company => company.organizations.some(org => org.id === orgId));
};
export const getCompanyById = id => CompanyModel.getById(id);
export const all = () => CompanyModel.all();
export const getCurrentID = () => CompanyModel.getCurrentID();
export const getCurrent = () => CompanyModel.getCurrent();
export const fetch = id => CompanyModel.Promises.fetch(id);
export const getDashboardUrl = company => CompanyModel.getDashboardUrl(company);
export const getFromOrg = org => CompanyModel.getFromOrg(org);
export const canReferOthers = () => CompanyModel.canReferOthers();
export const getCompanyProfileForLoggedInUser = id => UserModel.getCompanyProfileForLoggedInUser(id);