import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import HotReloadController from './hotReload';
import Segment from '../modules/segment';
import Url from '../modules/url';
import { EVENTS } from 'utils/monitoring';
const exports = {};
exports.viewOnSearchPage = query => {
  Segment.sendEvent(EVENTS.Interaction_Search, {
    source: 'navigation',
    selection: 'view in stories page'
  });
  HotReloadController.loadURL(Url.getSlugPath() + '/search#' + query, 'search');
  return false;
};
exports.toggleDrawer = function () {
  const $icon = $(this).find('.fa');
  if ($icon.hasClass('fa-caret-down')) {
    $icon.attr('class', 'fa fa-caret-right');
  } else {
    $icon.attr('class', 'fa fa-caret-down');
    Segment.sendEvent(EVENTS.Interaction_Search, {
      source: 'navigation',
      selection: 'expand operators'
    });
  }
  $('.search-operators-drawer .drawer-contents').slideToggle();
  return false;
};
export { exports as default };