import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import * as EditProfileTemplate from 'app/client/settingsShared/views/templates/editProfile.html?caveman';
import { Icon } from '@clubhouse/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import Async from '../../../core/js/modules/async';
import * as Event from '../../../core/js/_frontloader/event';
import FlashController from '../../../core/js/controllers/flash';
import Format from '../../../core/js/modules/format';
import IconModel from '../../../core/js/models/icon';
import Is from '../../../core/js/modules/is';
import Url from '../../../core/js/modules/url';
import UserModel from '../../../core/js/models/user';
import Validator from '../../../core/js/modules/validator';
import View from '../../../core/js/modules/view';
import SettingsPage from '../../../settings/js/controllers/settingsPage';
import Layout from 'app/client/core/js/modules/layout';
import { ManageProfilePage } from 'pages/manage-profile/ManageProfilePage';
import _ from 'lodash';
import moment from 'moment';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};
const PARENT_SELECTOR = '#edit-profile-form';
const PARENT_ICON_SELECTOR = '#edit-profile-icon-form';
exports.title = `Your Account | ${BRAND.NAME}`;
exports.route = () => {
  const slug = Url.getSlugPath() || '/organizations';
  return slug + '/settings/account';
};
exports.open = () => {
  SettingsPage.loadPane(exports);
};
exports.render = onComplete => {
  const withinOrg = Is.withinOrg();
  onComplete = typeof onComplete === 'function' ? onComplete : () => {};
  const html = EditProfileTemplate.render({
    subnav: withinOrg ? [] : SettingsPage.getSubnav(),
    active: 'editProfile',
    withinOrg
  });
  $(SettingsPage.PARENT_SELECTOR).html(html);
  const fns = [UserModel.fetchCurrentUserWithoutRedirect];
  if (withinOrg) {
    fns.push(UserModel.fetchAll);
  }
  Async.eachInParallelThen(fns, () => {
    const html = View.componentToTemplate(ManageProfilePage, 'manage-profile').render();
    document.getElementById('waiting-for-profile-content').innerHTML = html;
    SettingsPage.onDestroy('EditProfile', () => {
      Validator.destroy(PARENT_SELECTOR);
    });
    onComplete();
  });
};

// ----- Actions -----

exports.updateCurrentTime = function () {
  return $('#current-time').text(Format.currentTime(moment().utcOffset($(this).val())));
};
exports.openChooser = () => {
  $(PARENT_ICON_SELECTOR).find('.html-file-upload').click();
  return false;
};
exports.saveIcon = files => {
  IconModel.updateUserIcon(_.head(files).id, (err, res) => {
    if (err) {
      FlashController.error(PARENT_ICON_SELECTOR, 'We were unable to save your icon.', res);
    } else {
      exports.render(() => {
        FlashController.success(PARENT_ICON_SELECTOR, 'New icon saved!');
        Layout.renderProfileFromCurrentUser();
        Event.trigger('profileUpdated');
      });
    }
  });
  return false;
};
exports.renderInfoIcon = (id = '') => View.renderComponentDelayed({
  componentKey: 'infoIcon' + id,
  component: _jsx(DeprecatedIconAdapter, {
    width: 30,
    fill: "var(--iconBlueColor)",
    children: _jsx(Icon, {
      icon: "InfoCircle"
    })
  })
}).html;
exports.renderWarnIcon = (id = '', fill = 'var(--iconYellowColor)') => View.renderComponentDelayed({
  componentKey: 'warnIcon' + id,
  component: _jsx(DeprecatedIconAdapter, {
    width: 30,
    fill: fill,
    children: _jsx(Icon, {
      icon: "Warn"
    })
  })
}).html;
exports.renderLinkIcon = (id = '') => View.renderComponentDelayed({
  componentKey: 'linkIcon' + id,
  component: _jsx(DeprecatedIconAdapter, {
    width: 30,
    children: _jsx(Icon, {
      icon: "Link"
    })
  })
}).html;
exports.renderUploadIcon = () => View.renderComponentDelayed({
  componentKey: 'uploadIcon',
  component: _jsx(DeprecatedIconAdapter, {
    width: 16,
    fill: "currentColor",
    children: _jsx(Icon, {
      icon: "Upload"
    })
  })
}).html;
exports.renderSyncIcon = id => View.renderComponentDelayed({
  componentKey: 'syncIcon' + id,
  component: _jsx(DeprecatedIconAdapter, {
    fill: "var(--iconGreenColor)",
    children: _jsx(Icon, {
      icon: "Sync"
    })
  })
}).html;
exports.renderClearIcon = id => View.renderComponentDelayed({
  componentKey: 'clearIcon' + id,
  component: _jsx(DeprecatedIconAdapter, {
    children: _jsx(Icon, {
      icon: "Clear"
    })
  })
}).html;
exports.renderPencilIcon = id => View.renderComponentDelayed({
  componentKey: 'pencilIcon' + id,
  component: _jsx(DeprecatedIconAdapter, {
    width: 12,
    fill: "currentColor",
    children: _jsx(Icon, {
      icon: "Edit"
    })
  })
}).html;
exports.renderTrashIcon = id => View.renderComponentDelayed({
  componentKey: 'trashIcon' + id,
  component: _jsx(DeprecatedIconAdapter, {
    width: 14,
    fill: "currentColor",
    children: _jsx(Icon, {
      icon: "Trash"
    })
  })
}).html;
exports.deleteIcon = () => {
  IconModel.updateUserIcon(null, (err, res) => {
    if (err) {
      FlashController.error(PARENT_ICON_SELECTOR, 'We were unable to delete your icon.', res);
    } else {
      exports.render(() => {
        FlashController.success(PARENT_ICON_SELECTOR, 'Icon deleted.');
        Layout.renderProfileFromCurrentUser();
        Event.trigger('profileUpdated');
      });
    }
  });
  return false;
};
export { exports as default };