import "core-js/modules/es.array.push.js";
import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.some.js";
import before from 'lodash/before';
import EmptyStories from '@clubhouse/assets/png/empty-stories.png';
import * as Event from 'app/client/core/js/_frontloader/event';
import Globals from 'app/client/core/js/_frontloader/globals';
import AddNewStoryController from 'app/client/core/js/controllers/addNewStory';
import SampleWorkspaceController from 'app/client/core/js/controllers/sampleWorkspace';
import StoryDialogController from 'app/client/core/js/controllers/storyDialog';
import StoryLinkController from 'app/client/core/js/controllers/storyLink';
import ViewSettingsController from 'app/client/core/js/controllers/viewSettings';
import ColumnModel from 'app/client/core/js/models/column';
import EpicModel from 'app/client/core/js/models/epic';
import EstimateScaleModel from 'app/client/core/js/models/estimateScale';
import FilterModel from 'app/client/core/js/models/filter';
import IterationModel from 'app/client/core/js/models/iteration';
import ProfileModel from 'app/client/core/js/models/profile';
import ProjectModel from 'app/client/core/js/models/project';
import StoryModel from 'app/client/core/js/models/story';
import ApplicationState from 'app/client/core/js/modules/applicationState';
import Constants from 'app/client/core/js/modules/constants';
import FasterMoment from 'app/client/core/js/modules/fasterMoment';
import Is from 'app/client/core/js/modules/is';
import Iterate from 'app/client/core/js/modules/iterate';
import Log from 'app/client/core/js/modules/log';
import Tests from 'app/client/core/js/modules/tests';
import Utils from 'app/client/core/js/modules/utils';
import View from 'app/client/core/js/modules/view';
import * as ColumnTemplate from 'app/client/stories/views/templates/column.html?caveman';
import * as ColumnStoriesTemplate from 'app/client/stories/views/templates/columnStories.html?caveman';
import * as CompletedColumnDateHeaderTemplate from 'app/client/stories/views/templates/completedColumnDateHeader.html?caveman';
import * as LoadOldStoriesButtonTemplate from 'app/client/stories/views/templates/loadOldStoriesButton.html?caveman';
import { toggleHelpHub } from 'components/shared/command-bar/CommandBar';
import { Button } from 'components/shared/DeprecatedButton';
import { EmptyStateImage } from 'components/shared/EmptyStateComponent';
import { StoriesPageTable } from 'components/shared/stories';
import { GROUP_BY } from 'components/shared/table/GroupBySelect/stories';
import { StoriesNotFound } from 'components/stories/StoriesNotFound';
import { areCustomFieldsEnabled } from 'data/entity/customField';
import { isProjectsFeatureEnabled } from 'data/entity/feature';
import { getTeamScopedCollectionizeId } from 'data/entity/group';
import * as StoryEntity from 'data/entity/story';
import * as Stories from 'data/page/stories';
import { finishActiveTransaction } from 'utils/profiler/sentry/useFinishActiveTransaction';
import DragAndDrop from './draganddrop';
import DragToScroll from './dragToScroll';
import ColumnController from '../controllers/column';
import EpicController from '../controllers/epic';
import IterationController from '../controllers/iteration';
import ProjectController from '../controllers/project';
import SidebarController from '../controllers/sidebar';
import StoriesColumnModel from '../models/storiesColumn';
import StoriesEpicModel from '../models/storiesEpic';
import StoriesFilterModel from '../models/storiesFilter';
import StoriesIterationModel from '../models/storiesIteration';
import StoriesProjectModel from '../models/storiesProject';
import StoriesStoryModel from '../models/storiesStory';
import _ from 'lodash';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};
const NS = '.StoriesViewModule';
exports.MIN_COLUMN_WIDTH = 255;
exports.TABLE_VIEW = 'table';
exports.KANBAN_VIEW = 'kanban';
exports.toggleNoStoriesFoundTable = () => {
  const $tableView = $('#stories-table');
  const numStories = _getStories().length;
  if (numStories > 0) {
    exports.hideNoStoriesFound();
    $tableView.show();
  } else if (numStories === 0) {
    exports.showNoStoriesFound();
    $tableView.hide();
  }
};
exports.showColumnView = () => {
  const $tableAndHeader = $('.react-stories-table-content');
  const $columns = $('#columns');
  exports.hideNoStoriesFound();
  document.body.classList.remove('story-table-view');
  $columns.show();
  $tableAndHeader.show();
  exports.onResize();
};
exports.getViewType = () => {
  return ApplicationState.get('storiesPage.viewType') || exports.KANBAN_VIEW;
};
exports.setViewType = viewType => {
  ApplicationState.set('storiesPage.viewType', viewType);
  Event.trigger('storiesView:viewType');
};
exports.getTableGroupBy = () => {
  const groupBy = ApplicationState.get('storiesPage.tableGroupBy');
  return groupBy === undefined ? GROUP_BY.STATE : groupBy;
};
exports.setTableGroupBy = value => {
  ApplicationState.set('storiesPage.tableGroupBy', value);
  Event.trigger('storiesView:groupBy');
};
const _getStories = () => {
  const displayStoriesNotInEpic = EpicModel.shouldDisplayStoriesNotInEpics();
  const displayStoriesNotInIteration = StoriesIterationModel.shouldDisplayStoriesNotInIterations();
  const stories = [];
  const usesIterations = Tests.usesIterations();
  const _areCustomFieldsEnabled = areCustomFieldsEnabled();
  const areProjectsEnabled = isProjectsFeatureEnabled();
  const teamId = getTeamScopedCollectionizeId();
  Iterate.each(ColumnModel.allFromActiveWorkflow(), column => {
    if (!column.active) {
      return;
    }
    const columnStories = StoryModel.filter(story => {
      return _storyIsNotFiltered(story, column, {
        displayStoriesNotInEpic,
        displayStoriesNotInIteration,
        usesIterations,
        areCustomFieldsEnabled: _areCustomFieldsEnabled,
        areProjectsEnabled,
        teamId
      });
    });
    stories.push(...columnStories);
  });
  return stories;
};
const renderTableView = () => {
  document.body.classList.add('story-table-view');
  $('#columns').hide();
  $('#stories-table').show();
  const stories = _getStories();
  View.renderComponent({
    mountNode: document.querySelector('#stories-table'),
    componentKey: 'reactStoriesTable',
    component: StoriesPageTable,
    props: {
      groupBy: exports.getTableGroupBy(),
      initialSortBy: exports.getTableGroupBy() === GROUP_BY.STATE ? 'priority' : 'id',
      initialSortDirection: 'ascending',
      onSort: ({
        newSort
      }) => {
        if (newSort === 'priority') {
          exports.setTableGroupBy(GROUP_BY.STATE);
        }
      },
      stories
    }
  });
};
exports.hideNoStoriesFound = () => {
  $('#no-stories-found').hide();
};
exports.showNoStoriesFound = () => {
  $('#no-stories-found').show();
  exports.positionLoadingDialog();
  $('#columns').css({
    minWidth: 'inherit'
  });
  if (!ProjectModel.hasAnyStoriesInWorkspace()) {
    _renderCreateAStory();
  } else {
    const columns = ColumnModel.allDone() || [];
    _renderNoStoriesFoundComponent({
      workflowStateIds: columns.map(col => col.id),
      buttonProps: {
        text: 'Reset All Filters',
        onClick: () => {
          // Enable all Group filters
          Stories.selectAllGroups();
          Stories.selectAllProjects();
          StoriesFilterModel.setAllInactive();
          StoriesIterationModel.displayStoriesNotInIterations();
          StoriesIterationModel.setAllActive();
          StoriesEpicModel.setAllActive();
          EpicModel.displayStoriesNotInEpics();
          StoriesColumnModel.setAllActive();
          StoriesProjectModel.setAllActive();
          SidebarController.renderGroupsSidebar(); // re-render the group react component

          if (isProjectsFeatureEnabled()) {
            SidebarController.renderProjectsSidebar();
          }
          if (areCustomFieldsEnabled()) {
            Stories.resetFieldFilters();
            SidebarController.renderCustomFieldsSidebar();
          }
          FilterModel.trigger('updated');
          ColumnModel.trigger('updated');
          ColumnController.drawColumnListNav();
          ProjectController.render();
          IterationController.render();
          EpicController.render();
          exports.drawMissingColumns();
          exports.hideDisabledColumns();
          exports.renameColumns();
          exports.resetAndDrawStories();
          Event.trigger('spaceUpdate');
        },
        size: Button.sizes.xsmall
      }
    });
  }
};
const _renderCreateAStory = () => {
  return _renderNoStoriesFoundComponent({
    title: 'Start with a Story',
    media: _jsx(EmptyStateImage, {
      wrapperWidth: "72px",
      children: _jsx("img", {
        src: EmptyStories,
        alt: "",
        style: {
          width: '100%'
        }
      })
    }),
    description: `Stories are the basic unit of work where collaboration happens.`,
    buttonProps: {
      text: 'Create Story',
      onClick: AddNewStoryController.render,
      color: Button.colors.green
    },
    sampleButtonProps: !SampleWorkspaceController.isSampleObserver() ? {
      text: 'View Sample Stories',
      onClick: SampleWorkspaceController.join
    } : null,
    loadMoreEnabled: false
  });
};
const _renderNoStoriesFoundComponent = ({
  title,
  description,
  buttonProps,
  sampleButtonProps,
  media,
  loadMoreEnabled,
  workflowStateIds
} = {}) => {
  const learnButtonProps = {
    text: 'Learn about Stories',
    onClick: toggleHelpHub
  };
  View.renderComponentConditionally({
    containerSelector: '#no-stories-found',
    component: StoriesNotFound,
    props: {
      title,
      description,
      media,
      buttonProps,
      sampleButtonProps,
      loadMoreEnabled,
      workflowStateIds,
      onOldStoriesLoaded: exports.drawStories,
      learnButtonProps
    }
  });
};
exports.updateColumnDimensions = () => {
  if (Is.mobile()) {
    return false;
  }
  const shouldCollapseEmptyColumns = ApplicationState.get('CollapseEmptyColumns');
  const $html = $('html');
  const $container = $('#columns');
  const $columns = $container.find('.column').not('.column-is-closing');
  if ($columns.length === 0) {
    $container.width('90%');
    return false;
  }

  // Tally empty columns.
  let emptyColumnCount = 0;
  $columns.each(function () {
    const $column = $(this);
    const entity = Utils.getModelFromContext(this);
    if ($column.find('.js-story-card').length === 0 && !ColumnModel.isDone(entity)) {
      $column.addClass('empty-column');
      emptyColumnCount++;
    } else {
      $column.removeClass('empty-column');
    }
  });
  const isRaven = document.body.classList.contains('using-raven-nav');

  // Calculate column width.
  // We can't calculate off the DOM as they'll be mid transition but we can get them using css variables
  const styles = getComputedStyle(document.body);

  // Sidebar/menu widths are defined in dimensions.less
  const menuWidth = Number.parseInt(styles.getPropertyValue('--menuWidth'));
  const hiddenMenuWidth = Number.parseInt(styles.getPropertyValue('--hiddenMenuWidth'));
  const sidebarBaseWidth = Number.parseInt(styles.getPropertyValue('--sidebarWidth'));

  // Column width and margin are defined in columns.less
  const emptyColumnBaseWidth = Number.parseInt(styles.getPropertyValue('--emptyColumnWidth'));
  const columnMargin = isRaven ? 0 : Number.parseInt(styles.getPropertyValue('--columnMargin'));
  const sidebarWidth = !$html.hasClass(SidebarController.HIDDEN_CLASSNAME) ? sidebarBaseWidth : hiddenMenuWidth;
  const columnCount = $columns.length;
  // Margin to the right of the columns, if no horizontal scrolling needed.
  const EXTRA_SPACE = 15;
  const windowMargin = isRaven ? EXTRA_SPACE : EXTRA_SPACE + sidebarWidth + menuWidth;
  const emptyColumnWidth = emptyColumnBaseWidth + columnMargin; // Empty columns have an extra margin at the right
  let calculatedColumnWidth = 0;
  const availableWidth = $(isRaven ? '#stories-page-wrapper' : window).width() - windowMargin;
  if (shouldCollapseEmptyColumns && emptyColumnCount > 0 && emptyColumnCount !== columnCount) {
    // Revise available width and recalculate
    calculatedColumnWidth = (availableWidth - emptyColumnWidth * emptyColumnCount) / (columnCount - emptyColumnCount);
  } else {
    calculatedColumnWidth = availableWidth / columnCount;
  }
  const finalColumnWidth = Math.max(exports.MIN_COLUMN_WIDTH, calculatedColumnWidth - columnMargin);
  if (shouldCollapseEmptyColumns && emptyColumnCount === columnCount) {
    $columns.width(Math.floor(calculatedColumnWidth - columnMargin));
  } else {
    $columns.width(Math.floor(finalColumnWidth));
  }
  if (shouldCollapseEmptyColumns && emptyColumnCount > 0 && emptyColumnCount !== columnCount) {
    $columns.filter('.empty-column').width(emptyColumnBaseWidth);
  }
  let guessedMinWidth = 0;
  if (shouldCollapseEmptyColumns) {
    guessedMinWidth = $columns.not('.empty-column').length * finalColumnWidth + emptyColumnCount * emptyColumnWidth + columnCount * columnMargin;
  } else {
    guessedMinWidth = columnCount * finalColumnWidth + columnCount * columnMargin;
  }
  guessedMinWidth = Math.max(availableWidth, guessedMinWidth);
  const isContracting = Number.parseInt($container.css('minWidth'), 10) < guessedMinWidth;
  if (Utils.animationDisabled() || isContracting) {
    $container.stop(true, true).css({
      minWidth: guessedMinWidth
    });
  } else {
    // CSS transition is 200ms
    const transition = 350;
    $container.stop(true, true).animate({
      minWidth: guessedMinWidth
    }, transition);
  }
};
exports.init = () => {
  Event.onlyOn('StoryDensity.Change.StoriesView', () => {
    exports.drawStories();
    exports.onResize();
    exports.positionLoadingDialog();
  });
  Event.onlyOn('CollapseEmptyColumns.Change', () => {
    exports.updateColumnDimensions();
  });
  if (Is.mobile()) {
    Event.onlyOn('updateVisibleColumn.StoriesView', _updateColumnsOnSwipe);
  }
  ViewSettingsController.applyViewSettingsFromAppState();
  Event.onlyOn('pageDestroy.storiesViewInit', () => {
    Event.off('pageDestroy.storiesViewInit');
    Event.off('StoryDensity.Change.StoriesView');
    Event.off('updateVisibleColumn.StoriesView');
    SidebarController.destroy();
  });
};
let onResizeTimeoutId = null;
exports.onResize = () => {
  exports._storeColumnHeight();
  exports.updateColumnDimensions();
  exports.setColumnHeight();
  _updateColumnsOnResize();
  clearTimeout(onResizeTimeoutId);
  onResizeTimeoutId = setTimeout(() => {
    exports.setColumnHeight();
    _updateColumnsOnResize();
  }, 350);
};
exports.positionLoadingDialog = () => {
  const left = $(window).width() / 2 + $('#sidebar').width() / 2;
  $('#no-stories-found, #loading-screen').css({
    left
  });
};
exports._storeColumnHeight = () => {
  Globals.set('columnHeight', exports._getColumnHeight());
  return Globals.get('columnHeight');
};
exports._getStoredColumnHeight = () => {
  return Globals.get('columnHeight') || exports._storeColumnHeight();
};
exports._getColumnHeight = () => {
  const heightOffset = 20; // Bottom window margin
  const bucketElement = $(`${Is.mobile() ? '.mobile-visible-column ' : ''}.column-bucket`).first();
  let columnOffset = 125;
  if (bucketElement.length > 0) {
    columnOffset = bucketElement.offset().top;
  }
  return $(window).height() - columnOffset - heightOffset;
};
exports._calculateColumnHeight = context => {
  const columnHeight = exports._getStoredColumnHeight();
  const scrollBottomMargin = 6;
  context.find('.column-bucket').height(columnHeight);
  context.find('.column').height(columnHeight + scrollBottomMargin + $('.column-title').outerHeight());
  return columnHeight;
};
exports.setColumnHeight = () => {
  const columns = $('#columns');
  const columnHeight = exports._calculateColumnHeight(columns);
  columns.find('.column').not('.column-is-closing').each(function () {
    const column = $(this);
    if (column.hasClass('empty-column')) {
      column.find('.bucket-content').height(columnHeight);
    } else {
      column.find('.bucket-content').css({
        height: 'auto',
        minHeight: columnHeight - 50 // 50px buffer to prevent a temporary scrollbar
      });
    }
  });
};
exports._destroyScrollHandler = () => {
  if (Is.mobile()) {
    $(window).off('scroll' + NS);
  } else {
    $('#columns .column-bucket').off('scroll' + NS);
  }
};
exports._initScrollHandler = () => {
  if (Is.mobile()) {
    if (document.body.classList.contains('using-raven-nav')) {
      $('#columns .column-bucket').on('scroll' + NS, _.throttle(_onRavenMobileColumnScroll, 10));
    } else {
      $(window).on('scroll' + NS, _.throttle(_onMobileColumnScroll, 10));
    }
  } else {
    $('#columns .column-bucket').on('scroll' + NS, _.throttle(_onColumnScroll, 10));
  }
};
exports._initDragToScroll = () => {
  DragToScroll.init('.column-bucket, .bucket-content', document.body.classList.contains('using-raven-nav') ? '.react-stories-table-wrapper' : '#content');
};
function _onColumnScroll() {
  const THRESHOLD_HEIGHT = 400;
  if (this.scrollTop > this.scrollHeight - this.clientHeight - THRESHOLD_HEIGHT) {
    // TODO: Handle cases where the user scrolls to the very bottom.
    // Right now it just keeps incrementing and redrawing.
    const column = Utils.getModelFromContext(this, 'Column');
    if (column) {
      exports.drawMoreStoriesInColumn(column);
    }
  }
}
function _onRavenMobileColumnScroll() {
  const THRESHOLD_HEIGHT = 50;
  if (SidebarController.isClosed() && this.scrollTop > this.scrollHeight - this.clientHeight - THRESHOLD_HEIGHT) {
    // TODO: Handle cases where the user scrolls to the very bottom.
    // Right now it just keeps incrementing and redrawing.
    const column = ColumnController.getVisibleColumn();
    if (column) {
      exports.drawMoreStoriesInColumn(column);
    }
  }
}
function _onMobileColumnScroll() {
  const THRESHOLD_HEIGHT = 300;
  if (SidebarController.isClosed() && window.pageYOffset > document.body.scrollHeight - window.innerHeight - THRESHOLD_HEIGHT) {
    // TODO: Handle cases where the user scrolls to the very bottom.
    // Right now it just keeps incrementing and redrawing.
    const column = ColumnController.getVisibleColumn();
    if (column) {
      exports.drawMoreStoriesInColumn(column);
    }
  }
}
function _scrollColumnsToTop() {
  $('#columns .column-bucket').scrollTop(0);
}
exports.redrawAllColumns = () => {
  Log.debug('View: redrawAllColumns fn executed');
  exports._destroyScrollHandler();
  DragToScroll.destroy();
  $('#columns .column').remove();
  Iterate.each(ColumnModel.allActiveFromActiveWorkflow(), column => {
    exports.drawColumn(column);
  });
  exports._initScrollHandler();
  exports._initDragToScroll();
};
exports.drawMissingColumns = () => {
  exports._destroyScrollHandler();
  Iterate.each(ColumnModel.allActiveFromActiveWorkflow(), column => {
    const element = StoriesColumnModel.toElement(column);
    if (element.length === 0) {
      exports.drawColumn(column);
    }
  });
  exports._initScrollHandler();
};
exports.hideDisabledColumns = () => {
  exports._destroyScrollHandler();
  Iterate.each(ColumnModel.allFromActiveWorkflow(), column => {
    if (!column.active) {
      exports.removeColumn(column);
    }
  });
  exports._initScrollHandler();
};
exports.renameColumns = () => {
  Iterate.each(ColumnModel.allActiveFromActiveWorkflow(), column => {
    const element = StoriesColumnModel.toElement(column);
    if (element.length > 0) {
      element.find('.column-name').text(column.name);
    }
  });
};
exports.drawColumn = (column, width) => {
  const html = ColumnTemplate.render(column);
  const element = exports.attachColumn(column, html);
  if (width && !Is.mobile()) {
    element.width(width);
  }
  return element;
};
exports.attachColumn = (column, html) => {
  const activeColumns = ColumnModel.allActiveFromActiveWorkflow();
  if (activeColumns[0].id === column.id) {
    return View.attach(html, '#columns', 'prependTo');
  }
  let precedingColumn;
  Iterate.each(activeColumns, (activeColumn, index) => {
    if (activeColumn.id === column.id) {
      precedingColumn = $('#columns .column[data-id="' + activeColumns[index - 1].id + '"]')[0];
    }
  });
  if (precedingColumn) {
    return View.attach(html, precedingColumn, 'insertAfter');
  }
  return View.attach(html, '#columns');
};
exports.addColumn = (column, width) => {
  exports._destroyScrollHandler();
  DragToScroll.destroy();
  DragAndDrop.destroy();
  column.active = true;
  column.archived = false;
  ColumnModel.update(column);
  StoriesColumnModel.resetStoryLimitForColumn(column);
  const element = exports.drawColumn(column, width);
  exports._drawStoriesInColumn(column);
  element.css({
    marginLeft: 0,
    opacity: 0
  }).animate({
    marginLeft: 12,
    opacity: 1
  }, Utils.animationDisabled() || Is.mobile() ? 0 : 150);
  exports._afterColumnUpdate();
  exports._initScrollHandler();
  exports._initDragToScroll();
};
exports.removeColumn = column => {
  exports._destroyScrollHandler();
  DragAndDrop.destroy();
  column.active = false;
  ColumnModel.update(column);
  StoriesColumnModel.resetStoryLimitForColumn(column);
  const columnElement = $('#columns .column[data-id="' + column.id + '"]');
  if (Utils.animationDisabled() || Is.mobile()) {
    columnElement.remove();
  } else if (columnElement.hasClass('empty-column')) {
    const shim = $('<div>').insertAfter(columnElement);
    shim.css({
      height: 100,
      width: 37,
      float: 'left'
    }).animate({
      width: 1
    }, 100, () => {
      shim.remove();
    });
    columnElement.remove();
  } else {
    columnElement.addClass('column-is-closing').css({
      width: 1
    }).animate({
      marginLeft: 0,
      marginRight: 0,
      opacity: 0
    }, 100);
    setTimeout(() => {
      columnElement.remove();
    }, 200);
  }
  exports._afterColumnUpdate();

  // Deferring by 200ms in case we're animating the column closing.
  setTimeout(exports._initScrollHandler, 200);
};
exports._afterColumnUpdate = () => {
  ColumnController.drawColumnListNav();
  exports.onResize();
  const profile = ProfileModel.getCurrentUserProfileDetails();
  setTimeout(() => {
    DragAndDrop.initStorySorting({
      preventDrop: Is.observer(profile)
    });
  }, 50);
};
exports.resetAndDrawStories = () => {
  _scrollColumnsToTop();
  StoriesColumnModel.resetColumnStoryLimits();
  exports.drawStories();
};
exports.isInKanbanView = () => exports.getViewType() === exports.KANBAN_VIEW;
exports.drawStories = updates => {
  if (exports.isInKanbanView()) {
    document.body.classList.remove('story-table-view');
    $('#stories-table').hide();
    initColumnCache();
    Log.debug('View: Drawing stories...');
    DragAndDrop.destroy();

    // We need to iterate through all states in active workflow
    // so that we can update the column counts.
    Iterate.each(ColumnModel.allFromActiveWorkflow(), column => {
      exports._drawStoriesInColumn(column, updates);
    });
    exports._afterColumnUpdate();
    StoryDialogController.update();
    exports.showColumnView();
    View.unmountDetachedComponents();
    finishTransactionAfterStoryCardsAreRendered();
  } else {
    renderTableView();
    exports.toggleNoStoriesFoundTable();
  }
};
const finishTransactionAfterStoryCardsAreRendered = (retryCount = 0) => {
  if (retryCount > 10) {
    return;
  }
  const storyCard = document.querySelector('.js-story-card');
  if (!storyCard || storyCard.children.length > 0) {
    return finishActiveTransaction({
      'VC.viewType': 'kanban'
    });
  }
  setTimeout(() => finishTransactionAfterStoryCardsAreRendered(retryCount + 1), 10);
};
const _hasUpdatedStoryInColumn = (column, nextStoriesInColumn, {
  updated_stories
}) => {
  if (!updated_stories) {
    return false;
  }
  const $columnElement = getColumnElement(column);
  const expectedStoryIds = new Set(nextStoriesInColumn.map(story => story.id));
  const columnWillHaveStory = storyId => expectedStoryIds.has(storyId);
  const columnHaveStory = storyId => $columnElement.children(`.story[data-id="${storyId}"]`).length > 0;

  // Re-render column if it will have a modified story, or if it had a story that was moved to a new column
  if (updated_stories.modified?.some(story => columnWillHaveStory(story.id) || columnHaveStory(story.id))) {
    return true;
  }

  // Re-render column if it contains a deleted story
  if (updated_stories.deleted?.some(storyId => columnHaveStory(storyId))) {
    return true;
  }

  // Re-render if stories have moved to or from this column
  if (updated_stories.position_map?.some(update => columnWillHaveStory(update.id) || columnHaveStory(update.id))) {
    return true;
  }
  return false;
};
const _columnNeedsUpdate = (column, nextStoriesInColumn, updates) => {
  // No updates == initial render
  if (!updates) {
    return true;
  }

  // Re-render everything when un-optimized changes happen.
  if (!StoryEntity.supportsAllRelatedChanges(updates)) {
    return true;
  }

  // Check for changes to stories in this column
  if (_hasUpdatedStoryInColumn(column, nextStoriesInColumn, updates)) {
    return true;
  }

  // Check for changes to entities related to stories in this column.
  if (nextStoriesInColumn.some(StoryEntity.hasRelatedEntitiesChanged.bind(null, updates))) {
    return true;
  }
  return false;
};
exports._drawStoriesInColumn = (column, updates) => {
  const stories = exports.getVisibleStoriesInColumn(column);
  column.storyCount = stories.length;
  column.pointCount = StoryModel.totalPoints(stories);
  _updateColumnStoryCount(column);
  const shouldUpdate = _columnNeedsUpdate(column, stories, updates);
  if (column.active && shouldUpdate) {
    exports.replaceAllStoriesInColumn(column, stories);
  }
};
exports.getPreviousAndNextStories = story => {
  const stories = exports.getVisibleStoriesInColumn({
    id: story.workflow_state_id
  }, {
    readFromCache: true
  });
  const storyIndexInColumn = stories.findIndex(s => s.id === story.id);
  return {
    prevStory: stories[storyIndexInColumn - 1],
    nextStory: stories[storyIndexInColumn + 1]
  };
};
const columnCache = new Map();
const initColumnCache = () => {
  columnCache.clear();
  Event.onlyOn('pageDestroy.storiesViewResetColumnCache', () => {
    Event.off('pageDestroy.storiesViewResetColumnCache');
    columnCache.clear();
  });
};
exports.getVisibleStoriesInColumn = (column, {
  readFromCache = false
} = {}) => {
  if (readFromCache && columnCache.has(column.id)) {
    return columnCache.get(column.id);
  }
  const displayStoriesNotInEpic = EpicModel.shouldDisplayStoriesNotInEpics();
  const displayStoriesNotInIteration = StoriesIterationModel.shouldDisplayStoriesNotInIterations();
  const usesIterations = Tests.usesIterations();
  const _areCustomFieldsEnabled = areCustomFieldsEnabled();
  const areProjectsEnabled = isProjectsFeatureEnabled();
  const teamId = getTeamScopedCollectionizeId();
  const stories = StoryModel.filter(story => {
    return _storyIsNotFiltered(story, column, {
      displayStoriesNotInEpic,
      displayStoriesNotInIteration,
      usesIterations,
      areCustomFieldsEnabled: _areCustomFieldsEnabled,
      areProjectsEnabled,
      teamId
    });
  });
  columnCache.set(column.id, stories);
  return stories;
};
function _updateColumnsOnResize() {
  Iterate.each(ColumnModel.allFromActiveWorkflow(), column => {
    const stories = exports.getVisibleStoriesInColumn(column, {
      readFromCache: true
    });
    _ensureColumnHasScrollbar(column, stories);
  });
}
function _updateColumnsOnSwipe() {
  const column = ColumnController.getVisibleColumn();
  const stories = exports.getVisibleStoriesInColumn(column, {
    readFromCache: true
  });
  _ensureColumnHasScrollbar(column, stories);
}
function _fillStoryColumn(column, stories) {
  const currentRenderedStoryCount = _getRenderedStoryCountInColumn(column);
  // [sc-239152] Doubling story count value to address a tricky rendering bug
  const minimumStoryCount = getMinimumStoryCountInColumn() * 2;

  // Minimum Story count will be 0 when the column has no height
  if (!minimumStoryCount) return;
  if (currentRenderedStoryCount >= minimumStoryCount) return;
  exports.drawMoreStoriesInColumn(column, stories, minimumStoryCount - currentRenderedStoryCount);
}
const getMinimumStoryCountInColumn = () => {
  const storyCardHeight = ViewSettingsController.density.getMinimumCardHeight();
  const columnHeight = exports._getStoredColumnHeight();
  return Math.ceil(columnHeight / storyCardHeight);
};
function _ensureColumnHasScrollbar(column, stories) {
  _needsMoreStories(column, stories, () => {
    _fillStoryColumn(column, stories);
  });
}
function _needsMoreStories(column, stories, renderFn) {
  const currentRenderedStoryCount = _getRenderedStoryCountInColumn(column);
  if (stories.length > currentRenderedStoryCount) {
    const el = getColumnElement(column)[0];

    // Don't calculate this before column is open at least 100px.
    if (el?.clientWidth > 100 && el?.parentElement.scrollHeight <= el?.parentElement.clientHeight) {
      renderFn();
    }
  }
}
function _storyIsNotFiltered(story, column, {
  displayStoriesNotInEpic,
  displayStoriesNotInIteration,
  usesIterations,
  areCustomFieldsEnabled,
  areProjectsEnabled,
  teamId
}) {
  const projectId = story.project_id ?? Stories.NO_ENTITY_ID;
  if (story.workflow_state_id !== column.id) {
    return false;
  }
  if (StoryModel.shouldSkipFilters(story.id)) {
    return true;
  }
  if (story.archived === true) {
    return false;
  }
  if (areProjectsEnabled && !Stories.isProjectSelected({
    id: projectId
  })) {
    return false;
  }
  const notFilteredByEpic = displayStoriesNotInEpic && !story.epic_id || StoriesStoryModel.hasActiveEpic(story);
  if (!notFilteredByEpic) {
    return false;
  }
  if (usesIterations) {
    if (story.iteration_id && !_.get(IterationModel.getById(story.iteration_id), 'active')) {
      return false;
    }
    if (!story.iteration_id && !displayStoriesNotInIteration) {
      return false;
    }
  }
  const groupId = story.group_id ?? Stories.NO_ENTITY_ID;
  if (teamId && teamId !== story.group_id) {
    return false;
  } else if (!Stories.getIsGroupSelected({
    id: groupId
  })) {
    return false;
  }
  if (areCustomFieldsEnabled && !Stories.matchesCustomFieldFilters(story.custom_fields)) {
    return false;
  }
  return StoryModel.isNotFiltered(story);
}
function _getRenderedStoryCountInColumn(column) {
  // The completed columns might also include date header elements, so we need to explicitly scope it to stories.
  return getColumnElement(column).children('.story').length;
}
exports.drawMoreStoriesInColumn = (column, stories, incrementSize) => {
  const $columnElement = getColumnElement(column);
  if ($columnElement.length === 0) {
    return false;
  }
  _removeOldStoriesButtonFromColumn($columnElement);
  stories = stories || exports.getVisibleStoriesInColumn(column, {
    readFromCache: true
  });
  const STORY_LIMIT_INCREMENT = incrementSize || StoriesColumnModel.STORY_LIMIT_INCREMENT;
  const currentRenderedStoryCount = _getRenderedStoryCountInColumn(column);
  const storiesToAdd = stories.slice(currentRenderedStoryCount, currentRenderedStoryCount + STORY_LIMIT_INCREMENT);
  const columnIsDone = ColumnModel.isDone(column);
  let lastStory = _fetchLastStoryInColumn($columnElement);
  if (storiesToAdd.length > 0) {
    StoriesColumnModel.setStoryLimitForColumn(column, currentRenderedStoryCount + STORY_LIMIT_INCREMENT);
    Iterate.each(storiesToAdd, story => {
      if (columnIsDone) {
        _appendMovedAtDate($columnElement, story, lastStory);
      }
      _appendStoryToColumn($columnElement, story);
      lastStory = story;
    });

    // This allows the sortable library to pick up the appended stories.
    if ($columnElement.sortable('instance')) {
      $columnElement.sortable('refresh');
    }
  }
  _appendLoadOldStoriesButtonToColumn(column, $columnElement);
};
function _fetchLastStoryInColumn($columnElement) {
  const storyElement = $columnElement.children('.story').last();
  if (storyElement.length > 0) {
    return Utils.getModelFromContext(storyElement);
  }
}
function _removeOldStoriesButtonFromColumn($columnElement) {
  $columnElement.find('.load-old-stories').remove();
}
function _appendLoadOldStoriesButtonToColumn(column, $columnElement) {
  const columnIsDone = ColumnModel.isDone(column);
  if (columnIsDone) {
    $columnElement.append(LoadOldStoriesButtonTemplate.render({
      workflowStateId: column.id,
      isLoading: ColumnModel.isLoadingOldStories(column)
    }));
  }
}
function _shouldRenderMovedAtDate(story, lastStory) {
  if (!lastStory) {
    return true;
  }
  return FasterMoment.isNextWeek(lastStory.moved_at || lastStory.completed_at, story.moved_at || story.completed_at);
}
function _appendMovedAtDate($columnElement, story, lastStory) {
  if (_shouldRenderMovedAtDate(story, lastStory)) {
    $columnElement.append(_renderMovedAtDate(story));
  }
}
function _renderMovedAtDate(story) {
  return CompletedColumnDateHeaderTemplate.render({
    dateString: _formatMovedAtDate(story.moved_at || story.completed_at)
  });
}
function _formatMovedAtDate(dateString) {
  if (FasterMoment.isThisWeek(dateString)) {
    return 'This Week';
  }
  const sunday = FasterMoment.getPrevSunday(dateString);
  return 'Week of ' + Constants.MONTHS[sunday.getMonth()].substr(0, 3) + ' ' + sunday.getDate() + ', ' + sunday.getFullYear();
}
function _appendStoryToColumn(columnElement, story) {
  const html = StoryLinkController.renderStoryCard(story);
  columnElement.append(html);
}
const getColumnElement = column => $('#column-bucket-content-' + column.id);
exports.replaceAllStoriesInColumn = (column, stories) => {
  const limit = StoriesColumnModel.getStoryLimitForColumn(column);
  const columnIsDone = ColumnModel.isDone(column);
  const $columnElement = getColumnElement(column);
  const $parent = $columnElement.parents('.column-bucket');
  const parentScrollTop = $parent.scrollTop() || 0;
  let rendered = false;
  const setScroll = before(5, () => {
    // if the child is smaller than the parent
    // we may still be rendering, we don't know yet.
    if ($parent.find('.bucket-content').height() < $parent.height()) {
      // re-check on the next animationFrame
      requestAnimationFrame(setScroll);
    } else {
      // If the child is larger, we must be done rendering.
      $parent.scrollTop(parentScrollTop);
    }
  });
  if ($columnElement.length) {
    let lastStory = null;
    rendered = View.renderTemplate({
      template: ColumnStoriesTemplate,
      templateData: {
        stories,
        limit,
        shouldRenderMovedAtDate: story => {
          let result = false;
          if (columnIsDone) {
            result = _shouldRenderMovedAtDate(story, lastStory);
            lastStory = story;
          }
          return result;
        },
        format: _formatMovedAtDate
      },
      templateMountNode: $columnElement[0]
    });
    if (parentScrollTop > 0) {
      requestAnimationFrame(setScroll);
    }
    if (rendered) {
      _appendLoadOldStoriesButtonToColumn(column, $columnElement);
      if (Is.mobile()) {
        if (!stories.length) {
          $parent.addClass('empty-column');
        } else {
          $parent.removeClass('empty-column');
        }
      }
    }
  }
};
function _updateColumnStoryCount(column) {
  ColumnModel.updateIfValid(column);
  const isUsingPoints = EstimateScaleModel.isUsingPoints();
  const countValue = isUsingPoints ? column.pointCount : column.storyCount;
  const sidebarElement = $('#sidebar-column-' + column.id + ' .column-story-count');
  if (column.storyCount > 0) {
    sidebarElement.html(countValue).removeClass('hidden');
  } else {
    sidebarElement.html('').addClass('hidden');
  }
}
export { exports as default };