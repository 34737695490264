import * as InviteLinkFormTemplate from 'app/client/login/views/templates/inviteLinkForm.html?caveman';
import { InviteLinkForm } from 'components/invite/InviteLinkForm';
import Url from '../../../core/js/modules/url';
import InviteModel from '../../../core/js/models/invite';
import InviteLinkModel from '../../../core/js/models/inviteLink';
import User from '../../../core/js/modules/user';
import UserModel from '../../../core/js/models/user';
import OrganizationModel from '../../../core/js/models/organization';
import View from '../../../core/js/modules/view';
import Utils from '../../../core/js/modules/utils';
import { setPageTitle } from 'utils/page';
const exports = {};
exports.init = () => {
  setPageTitle(`Invite Link | ${BRAND.NAME}`);
  exports.render();
};
exports.render = () => {
  View.renderWithComponents({
    template: InviteLinkFormTemplate,
    templateMountNode: document.querySelector('#form-container'),
    components: {
      inviteLinkForm: {
        component: InviteLinkForm,
        props: {
          contextProps: {
            Url,
            Utils,
            User,
            Model: {
              Invite: InviteModel,
              InviteLink: InviteLinkModel,
              Organization: OrganizationModel,
              User: UserModel
            }
          }
        }
      }
    }
  });
  Utils.fadePageIn();
};
export { exports as default };