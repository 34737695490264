import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import Collection from '../../../core/js/_frontloader/collection';
import CustomFieldModel from '../../../core/js/models/customField';
import InstallationModel from '../../../core/js/models/installation';
import ProfileModel from '../../../core/js/models/profile';
import ProjectModel from '../../../core/js/models/project';
import Backend from '../../../core/js/modules/backend';
import _ from 'lodash';
const exports = {};
const SENTRY_MUTABLE_KEYS = ['permission_id', 'project_id', 'field_id', 'value_id'];
Collection.create('SentryProject', exports);
exports.fetchAll = () => {
  const sentry = InstallationModel.getSentry();
  return new Promise((resolve, reject) => {
    Backend.get(`/api/private/installations/${sentry.id}/sentry-projects`, {
      onSuccess: resolve,
      onError: reject
    });
  }).then(res => {
    res.forEach(sentryProject => {
      exports.update(sentryProject, 'sentry_project_slug');
    });
  });
};
exports.updateSentryProject = (sentry_project_slug, data) => {
  const sentry = InstallationModel.getSentry();
  const mutableData = _.pick(exports.get({
    sentry_project_slug
  }), SENTRY_MUTABLE_KEYS);
  data = _.assign(mutableData, data);
  return new Promise((resolve, reject) => {
    Backend.put(`/api/private/installations/${sentry.id}/sentry-projects/${sentry_project_slug}`, {
      onSuccess: resolve,
      onError: reject,
      data: _.omitBy(data, _.isNil)
    });
  }).then(() => {
    exports.update({
      sentry_project_slug,
      ...data
    }, 'sentry_project_slug');
  });
};
exports.normalize = sentryProject => {
  sentryProject.project = ProjectModel.getById(sentryProject.project_id);
  sentryProject.requester = ProfileModel.getAllDetailsById(sentryProject.permission_id);
  sentryProject.fieldValue = CustomFieldModel.getValueById(sentryProject.field_id, sentryProject.value_id);
};
export { exports as default };