import Settings from 'app/client/core/js/modules/settings';
import Url from 'app/client/core/js/modules/url';
import View from 'app/client/core/js/modules/view';
import { Guru } from 'pages/integrations/Guru';
const exports = {};
exports.title = `Guru Integration | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/settings/integrations/guru';
};
exports.open = async () => {
  Settings.open({
    template: View.componentToTemplate(Guru, 'integrations-guru'),
    route: exports.route,
    title: exports.title
  });
  return false;
};
export { exports as default };