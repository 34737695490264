import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import * as PanelTemplate from 'app/client/core/views/templates/panel.html?caveman';
import AutocompleteController from './autocomplete';
import DropdownModel from '../models/dropdown';
import PanelModel from '../models/panel';
import Utils from '../modules/utils';
import View from '../modules/view';
import { SelectState } from '@clubhouse/shared/utils/isSelectOpen';
import _ from 'lodash';
const exports = {};
const DEFAULT_OPTIONS = {
  disallowClose: false,
  maxHeight: 350,
  leftOffset: 0,
  showCloseButton: true,
  topOffset: 4,
  width: null,
  center: false,
  aria: {}
};
exports.open = options => {
  const existingPanel = PanelModel.findByTarget(options.target);
  if (existingPanel) {
    exports.close(existingPanel);
  }
  const panel = {};
  const id = Utils.simpleHash('panel' + Date.now()) + Date.now();
  panel.options = _.assignIn({}, DEFAULT_OPTIONS, options);
  panel.id = panel.options.id || id;
  const html = PanelTemplate.render({
    html: panel.options.html,
    id: panel.id,
    showCloseButton: panel.options.showCloseButton,
    expanded: panel.options.expanded,
    center: panel.options.center,
    aria: panel.options.aria
  });
  View.attach(html, 'body');
  panel.element = $('#panel-' + panel.id);
  panel.overlay = panel.element.prev('.panel-overlay');
  panel.target = $(panel.options.target);
  if (!Utils.animationDisabled()) {
    panel.element.addClass('large-element-is-hiding');
    setTimeout(() => {
      if (panel.element) {
        panel.element.removeClass('large-element-is-hiding').addClass('element-is-open');
        if (panel.options && _.isFunction(panel.options.onOpen)) {
          panel.options.onOpen(panel);
        }
      }
    }, 0);
  } else if (panel.options && _.isFunction(panel.options.onOpen)) {
    panel.options.onOpen(panel);
  }
  _bindToKeys(panel);
  panel.element.find('.panel').css({
    width: `${options.width || 700}px`
  });
  document.body.classList.add('scroll-lock');
  PanelModel.update(panel);
  return panel;
};
exports.onMouseDownOutside = event => {
  if ($(event.target).hasClass('panel-container')) {
    const onMouseUp = e => {
      const target = $(e.target);
      document.body.removeEventListener('mouseup', onMouseUp);
      if (target.hasClass('panel-container')) {
        exports.closeById(target.data('id'));
      }
    };
    document.body.addEventListener('mouseup', onMouseUp);
  }
};
function _bindToKeys(panel) {
  $('body').on(`${panel.options.keyEvent || 'keyup'}.Panel.${panel.id}`, panel.options.onKeyUp || (e => {
    if (Utils.keyPressed(e, 'ESCAPE') && !AutocompleteController.isClosing) {
      if (_.isFunction(panel.options.beforeEscape) && panel.options.beforeEscape() === false || SelectState.isSelectOpen) {
        return;
      }
      exports.close(panel);
      return false;
    }
  }));
}
exports.close = function (panel) {
  // Handle cases where user clicks on the panel overlay or close button.
  if (Utils.isEvent(panel)) {
    panel = Utils.getModelFromContext(this);
  }
  if (DropdownModel.size() > 0 || AutocompleteController.isClosing || panel.options.disallowClose) {
    return false;
  }
  if (panel.options && _.isFunction(panel.options.onClose)) {
    panel.options.onClose();
  }
  ['element', 'overlay'].forEach(key => {
    if (panel[key]) {
      panel[key].remove();
      panel[key] = null;
    }
  });
  $('body').off('.Panel.' + panel.id);
  document.body.classList.remove('scroll-lock');
  PanelModel.remove({
    id: panel.id
  });
  panel?.options?.onRemove?.();
  return false;
};
exports.closeById = id => {
  const panel = PanelModel.getById(id);
  if (panel) {
    exports.close(panel);
  }
};

// TODO: When is it possible for multiple panels to exist on the page?
exports.closeAll = () => {
  PanelModel.each(panel => {
    if (panel) {
      exports.close(panel);
    }
  });
};
exports.isOpen = () => {
  return !!PanelModel.size();
};
exports.setExpanded = (id, expanded) => {
  const panel = PanelModel.getById(id);
  const {
    element
  } = panel;
  if (expanded) {
    element.addClass('expanded');
  } else {
    element.removeClass('expanded');
  }
};
export { exports as default };