import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import "core-js/modules/esnext.iterator.some.js";
import TimeSpentInWfStateScreenshot from '@clubhouse/assets/png/screenshots/example-time-spent-in-wf-state-chart.png';
import { calculateDuration } from '@clubhouse/shared/utils/date';
import * as AboutTheTimeSpentInWfStateChartTemplate from 'app/client/core/views/templates/reports/aboutTheTimeSpentInWfStateChart.html?caveman';
import { TimeSpentInWFStateReportCard } from 'components/reports/timeSpentInWFState/Report';
import { getOrgWorkingDays } from 'data/entity/organization';
import { NON_WORKING_DAYS_LABEL, importChartingLibraries, mergeNonWorkingData } from 'utils/charts';
import { workspaceUtcOffset } from 'utils/date';
import { calcAverage, median, q25, q75 } from 'utils/math';
import ReportsController from './reports';
import ReportModel from '../models/report';
import WorkflowModel from '../models/workflow';
import Constants from '../modules/constants';
import Url from '../modules/url';
import View from '../modules/view';
import moment from 'moment';
import _ from 'lodash';
const exports = {};
const SECONDS_IN_ONE_DAY = 86400;
const CHART_ID = ReportModel.CHARTS.TIME_SPENT_IN_WORKFLOW_STATE;
const DATE_FORMAT = ReportModel.DEFAULT_DATE_FORMAT;
const NUM_LEAD_DAYS = ReportModel.TIME_SPENT_IN_WF_STATE_NUM_LEAD_DAYS;
const DONE_STATE_TYPE = 'done';
exports.CONFIG_OPTIONS = {
  wf_state_type: {
    title: 'Type: ',
    defaultValue: 'stacked area',
    values: [{
      name: 'Stacked Area',
      value: 'stacked area'
    }, {
      name: 'Stacked Bar',
      value: 'stacked bar'
    }, {
      name: 'Line',
      value: 'line'
    }]
  },
  wf_state_calculation: {
    title: 'Calculation: ',
    defaultValue: 'average',
    values: [{
      name: 'Average',
      value: 'average'
    }, {
      name: '25th Percentile',
      value: '25th percentile'
    }, {
      name: '75th Percentile',
      value: '75th percentile'
    }]
  },
  wf_state_workflow: {
    title: 'Workflow: ',
    values: []
  }
};
exports.updateWorkflowConfig = (workflows = []) => {
  const config = exports.CONFIG_OPTIONS.wf_state_workflow;
  const lastValue = Url.getParamFromUrl('wf_state_workflow');
  config.values = workflows.map(wf => ({
    name: wf.name,
    value: `${wf.id}`
  }));
  if (!lastValue || !config.values.some(wf => wf.value === lastValue)) {
    config.defaultValue = config.values[0]?.value;
    Url.updateParamInUrl('wf_state_workflow', config.defaultValue);
  }
};
exports.renderChartComponent = ({
  chartData,
  errorMsg,
  options
}) => {
  const mountNode = document.getElementById(`${CHART_ID}-chart`);
  if (mountNode) {
    View.renderComponent({
      mountNode,
      component: TimeSpentInWFStateReportCard,
      props: {
        chartId: CHART_ID,
        chartData,
        errorMsg,
        renderChart: () => exports.renderChart({
          chartData,
          options
        })
      }
    });
  }
};
exports.openHelp = () => {
  ReportsController.openAboutThisChart({
    template: AboutTheTimeSpentInWfStateChartTemplate
  }, {
    imgSrc: TimeSpentInWfStateScreenshot
  });
  return false;
};
exports.getChartConfig = key => {
  return Url.getParamFromUrl(key) || exports.CONFIG_OPTIONS[key]?.defaultValue;
};
const _doCalculation = values => {
  const calculationType = exports.getChartConfig('wf_state_calculation');
  switch (calculationType) {
    case '25th percentile':
      return q25(values);
    case 'median':
      return median(values);
    case '75th percentile':
      return q75(values);
    default:
      return calcAverage(values);
  }
};
const _getC3ChartType = () => {
  const chartType = exports.getChartConfig('wf_state_type');
  switch (chartType) {
    case 'stacked area':
      return 'area';
    case 'stacked bar':
      return 'bar';
    default:
      return chartType;
  }
};
const _getAllTimesPerStatePerDay = ({
  data,
  dates,
  activeWorkflowId
}) => {
  const workingDays = getOrgWorkingDays();
  const allTimesPerStatePerDay = {};
  dates.forEach(date => allTimesPerStatePerDay[date] = {});
  data.forEach(story => {
    // If story moved "left" and was completed again, we'll use the last completed date as the displayed date
    const datesStoryWasMovedToDone = [];
    // The keys of secondsPerState are workflow state ids.
    // The value at secondsPerState[wfx] will be an array, whose values are counts of seconds spent
    // in the workflow state with id wfx. Summing these values will give the total time this story
    // spent in that workflow state.
    const secondsPerState = {};
    story.history.forEach(historyEntry => {
      const {
        workflow_id,
        workflow_state_id,
        workflow_state_type,
        date_entered,
        seconds: secondsInState
      } = historyEntry;
      if (workflow_id === activeWorkflowId) {
        if (workflow_state_type === DONE_STATE_TYPE) {
          datesStoryWasMovedToDone.push(moment(date_entered.split('T')[0]));
        }

        // Depending on the parameters, calculateDuration may return a negative number. That will
        // throw off our computation here, so we make sure secondsToAdd is always at least zero.
        const calculated = calculateDuration([moment.utc(date_entered).format(), moment.utc(date_entered).add(secondsInState, 'seconds').format()], workspaceUtcOffset(), {
          includeDays: workingDays
        });
        const secondsToAdd = Math.max(0, calculated);

        // If story moved into a state multiple times, all times are consolidated into total seconds
        const totalTimeInState = (secondsPerState[workflow_state_id] || 0) + secondsToAdd;
        secondsPerState[workflow_state_id] = totalTimeInState;
      }
    });
    if (datesStoryWasMovedToDone.length) {
      const lastDoneDate = moment.max(datesStoryWasMovedToDone).format(DATE_FORMAT);
      // allTimesOnDoneDate is an empty object at allTimesPerStatePerDay[lastDoneDate]
      const allTimesOnDoneDate = allTimesPerStatePerDay[lastDoneDate] ?? {};

      // for each state id in secondsPerState, i.e. each state id where something happened
      Object.entries(secondsPerState).forEach(([stateId, secondsSpentInState]) => {
        // There may not be an array here yet, so default to an empty array
        allTimesOnDoneDate[stateId] = allTimesOnDoneDate[stateId] || [];
        allTimesOnDoneDate[stateId].push(secondsSpentInState);
      });
    }
  });
  return allTimesPerStatePerDay;
};
const _isDisplayedDate = ({
  interval,
  lastDisplayedDate,
  date,
  index
}) => {
  if (interval === 'month') {
    return !lastDisplayedDate || moment(lastDisplayedDate).month() !== moment(date).month();
  } else if (interval === 'week') {
    return index % 7 === 0;
  } else {
    return true;
  }
};
const _getLeadTimes = ({
  dates,
  interval,
  stateId,
  isDisplayedDate,
  lastDisplayedDate,
  allTimesPerStatePerDay,
  allTimesPerStatePerInterval
}) => {
  if (!isDisplayedDate) {
    return undefined;
  } else if (interval === 'month') {
    return [];
  } else if (lastDisplayedDate) {
    return allTimesPerStatePerInterval[lastDisplayedDate][stateId].slice(-NUM_LEAD_DAYS);
  } else {
    return [...Array(NUM_LEAD_DAYS).keys()].map(i => allTimesPerStatePerDay[dates[i]][stateId]).filter(x => x !== undefined);
  }
};

// Consolidate times per day into times per interval and include lead time per interval if not grouped by month
const _getAllTimesPerStatePerInterval = ({
  allTimesPerStatePerDay,
  dates,
  interval,
  stateIds
}) => {
  const allTimesPerStatePerInterval = {};
  let lastDisplayedDate;

  // The six extra days requested as lead time should not be included in display
  dates.slice(NUM_LEAD_DAYS).forEach((date, index) => {
    const isDisplayedDate = _isDisplayedDate({
      interval,
      lastDisplayedDate,
      date,
      index
    });
    const intervalForDate = isDisplayedDate ? date : lastDisplayedDate;
    if (isDisplayedDate) {
      allTimesPerStatePerInterval[date] = {};
    }
    stateIds.forEach(stateId => {
      if (isDisplayedDate) {
        allTimesPerStatePerInterval[date][stateId] = _getLeadTimes({
          dates,
          interval,
          stateId,
          isDisplayedDate,
          lastDisplayedDate,
          allTimesPerStatePerDay,
          allTimesPerStatePerInterval
        });
      }
      const timesForDate = allTimesPerStatePerDay[date][stateId] || [];
      allTimesPerStatePerInterval[intervalForDate][stateId].push(timesForDate);
    });
    lastDisplayedDate = intervalForDate;
  });
  return allTimesPerStatePerInterval;
};
const _formatData = ({
  data,
  dates,
  activeWorkflowId,
  stateIds,
  stateLabels,
  options
}) => {
  // Times spent in each state are added to a story's last completed date
  const allTimesPerStatePerDay = _getAllTimesPerStatePerDay({
    data,
    dates,
    activeWorkflowId
  });
  const interval = options.interval?.id;
  const allTimesPerStatePerInterval = _getAllTimesPerStatePerInterval({
    allTimesPerStatePerDay,
    dates,
    interval,
    stateIds
  });
  return _.reduce(allTimesPerStatePerInterval, (groups, times, date) => {
    groups[0].push(date);
    stateIds.forEach((stateId, index) => {
      const timesInState = times[stateId].flat();
      const avgDaysInState = _doCalculation(timesInState) / SECONDS_IN_ONE_DAY;
      groups[index + 1].push(avgDaysInState);
    });
    return groups;
  }, [['x'], ...stateLabels.map(l => [l])]);
};

// options.dateRange is the original requested date range. The data will include that range PLUS NUM_LEAD_DAYS days of lead time.
exports.renderChart = async ({
  chartData,
  options = {}
}) => {
  const {
    c3,
    d3
  } = await importChartingLibraries();
  const workflow = WorkflowModel.getById(exports.getChartConfig('wf_state_workflow')) || WorkflowModel.getActive();
  const orderedStates = workflow.states.slice().sort((a, b) => b.position - a.position);
  const colors = {};
  const orderedStateIds = [];
  const orderedStateLabels = [];
  orderedStates.forEach((state, index) => {
    orderedStateIds.push(state.id);
    orderedStateLabels.push(state.name);
    colors[state.name] = Constants.CHART_COLORS[index % Constants.CHART_COLORS.length];
  });
  const dates = [];
  const dateRange = options.dateRange;
  const date = dateRange.start.clone().subtract(NUM_LEAD_DAYS, 'days');
  do {
    dates.push(date.format(DATE_FORMAT));
    date.add(1, 'days');
  } while (date.diff(dateRange.end) <= 0);
  const chartType = _getC3ChartType();
  const data = _formatData({
    data: [...chartData],
    dates,
    activeWorkflowId: workflow.id,
    stateIds: orderedStateIds,
    stateLabels: orderedStateLabels,
    options
  });
  const workingDays = getOrgWorkingDays();
  const c3Input = {
    bindto: options.element || document.getElementById(CHART_ID),
    data: {
      x: 'x',
      xFormat: '%Y-%m-%d',
      type: chartType,
      columns: data,
      order: null,
      groups: chartType === 'line' ? undefined : [orderedStateLabels],
      colors
    },
    point: {
      show: false
    },
    line: {
      connectNull: true
    },
    grid: {
      focus: {
        show: false
      }
    },
    axis: {
      x: {
        type: 'timeseries',
        tick: {
          format: date => {
            const MAX_LABEL_COUNT = 5;
            const intervals = data[0].slice(1);
            const mod = Math.floor(intervals.length / MAX_LABEL_COUNT);
            const index = intervals.indexOf(moment(date).format(DATE_FORMAT));
            if (intervals.length <= MAX_LABEL_COUNT || index % mod === 0) {
              return moment(date).format('MMM D, YYYY');
            }
          },
          values: data[0].slice(1)
        }
      },
      y: {
        label: {
          text: 'Days',
          position: 'outer-middle'
        },
        tick: {
          format: n => Math.round(n * 100) / 100
        }
      }
    },
    tooltip: {
      position: function (data, width, height, element) {
        const {
          left
        } = c3.chart.internal.fn.tooltipPosition.apply(this, [data, width, height, element]);
        const chart = document.querySelector('.c3-zoom-rect');
        const chartHeight = chart?.clientHeight || 0;
        const mouse = d3.mouse(element);
        let tooltipTop = mouse[1] + 15;
        if (tooltipTop + height > chartHeight) {
          tooltipTop -= height + 30;
        }
        if (tooltipTop < 0) {
          tooltipTop = tooltipTop / 2;
        }
        return {
          top: tooltipTop,
          left
        };
      },
      /** Reverse the chart data fed to the tooltip to match the order of the plotted chart data. **/
      contents: function (data, ...rest) {
        return this.getTooltipContent(data?.reverse(), ...rest);
      },
      format: {
        title: date => moment(date).format('dddd, MMM D, YYYY'),
        value: (value, _, id) => {
          if (id === NON_WORKING_DAYS_LABEL) {
            return undefined;
          }
          return `${+value.toFixed(2)} ${value === 1 ? 'day' : 'days'}`;
        }
      }
    }
  };
  const workingDaysTooltips = {
    tooltip: {
      format: {
        title: date => {
          const myMo = moment(date);
          const longDate = myMo.format('dddd, MMM D, YYYY');
          if (!workingDays.includes(myMo.day())) {
            return `${longDate} (No Work)`;
          }
          return longDate;
        }
      }
    }
  };
  const c3InputWithNonWorkingDays = mergeNonWorkingData(workingDays, options.interval.id, c3Input, workingDaysTooltips);
  return c3.generate(c3InputWithNonWorkingDays);
};
export { exports as default };