import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.flat-map.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Model', 'Iteration'], IterationModel]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import * as DropdownStoryIterationTemplate from 'app/client/core/views/templates/dropdownStoryIteration.html?caveman';
import Format from './format';
import IterationModel from '../models/iteration';
import MessageController from '../controllers/message';
import AutocompleteController from '../controllers/autocomplete';
import * as IterationData from 'data/entity/iteration';
import { renderComponentToString } from 'utils/helpers';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon/SizedIcon';
import _ from 'lodash';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};
const toItem = (obj, index) => {
  const statusColor = {
    done: 'var(--iconGreenColor)',
    started: 'var(--iconBlueColor)'
  };
  const fill = statusColor[obj.status];
  return {
    customIconLeft: renderComponentToString(_jsx(SizedIcon, {
      icon: "Iteration",
      size: "18",
      customFill: fill
    })),
    className: 'iteration-item',
    order: index,
    name: `<span data-model="Iteration" data-id="${obj.id}">${Format.sanitize(obj.name)}</span>`,
    value: obj.id
  };
};
exports.getLabelForStatus = status => {
  const statusToLabel = {
    [IterationModel.STATUS_VALUES.UNSTARTED]: 'Unstarted',
    [IterationModel.STATUS_VALUES.STARTED]: 'Started',
    [IterationModel.STATUS_VALUES.DONE]: 'Done'
  };
  return statusToLabel[status];
};
exports.toValidStatusFilter = (statuses = []) => {
  return statuses.filter(exports.getLabelForStatus);
};
exports.getLabelForStatuses = statuses => {
  if (statuses.length === Object.values(IterationModel.STATUS_VALUES).length || statuses.length === 0) {
    return 'All';
  }
  const labels = statuses.map(exports.getLabelForStatus);
  if (statuses.length === 1) {
    return labels[0];
  }
  return `${statuses.length} States`;
};
const toStatusHeaderAndIterations = ({
  status,
  iterations
}) => {
  if (iterations.length === 0) {
    return [];
  }
  return [{
    html: `<div class="caption aligned-caption">${exports.getLabelForStatus(status)}</div>`
  }, ...iterations.map(toItem)];
};
const itemsOrNone = items => {
  if (items.length === 0) {
    return [{
      html: AutocompleteController.getNoItemsFoundHtml()
    }];
  }
  return items;
};
exports.getItemsForAutocomplete = ({
  emptyLabel = 'None',
  groupId
} = {}) => {
  let items = [];
  if (groupId) {
    const groupedData = IterationData.getIterationsByGroupAndState({
      iterations: IterationData.getAll(),
      groupIds: [groupId]
    });
    const myIterations = groupedData.set.flatMap(toStatusHeaderAndIterations);
    const otherIterations = groupedData.none.flatMap(toStatusHeaderAndIterations);
    if (myIterations.length > 0 || otherIterations.length > 0) {
      items = [{
        html: `<div class="caption group-caption">Selected Team's Iterations</div>`
      }, ...itemsOrNone(myIterations), {
        hr: true
      }, {
        html: `<div class="caption group-caption">Other Iterations</div>`
      }, ...itemsOrNone(otherIterations)];
    }
  } else {
    items = [...IterationData.groupByStatus({
      iterations: IterationData.getAll()
    }).flatMap(toStatusHeaderAndIterations)];
  }
  return [{
    name: `<em>${emptyLabel}</em>`,
    value: null
  }, ...(items.length > 0 ? [{
    hr: true
  }] : []), ...items];
};
const UPDATING_CLASS = 'is-updating';
exports.renderDropdown = (context, iterationId) => {
  $(context).html(DropdownStoryIterationTemplate.render({
    iteration_id: iterationId
  }));
};
exports.getIterationPickerConfig = ({
  context,
  getState,
  setValue = () => Promise.resolve(),
  initialGroupId
}) => {
  const iteration = IterationModel.getById(getState().iteration_id);
  const onError = err => {
    MessageController.error(err, {
      secondary: 'Unable to update iteration.'
    });
    $(context).removeClass(UPDATING_CLASS);
  };
  const onSuccess = ({
    iteration_id
  }) => {
    $(context).removeClass(UPDATING_CLASS);
    exports.renderDropdown(context, iteration_id);
  };
  return {
    items: exports.getItemsForAutocomplete({
      groupId: getState().group_id || initialGroupId
    }),
    preserveCaptions: true,
    key: 'iteration_id',
    inputValue: _.get(iteration, 'name'),
    value: _.get(iteration, 'id'),
    onResultsUpdate: ({
      hasResults,
      value,
      setNoResults
    }) => {
      if (!hasResults) {
        setNoResults(`Iteration <strong>${value}</strong> not found.`);
      }
    },
    targetSelector: '#story-dialog-iteration-dropdown',
    target: context,
    title: 'Update Iteration',
    showInput: true,
    width: 240,
    onApply: id => {
      $(context).addClass(UPDATING_CLASS);
      if (!id) {
        return setValue(null).then(onSuccess, onError);
      }
      const iteration = IterationModel.getById(id);
      if (!iteration) {
        return Promise.resolve(onError('Iteration is invalid.'));
      }
      if (getState().iteration_id === iteration.id) {
        return setValue(null).then(onSuccess, onError);
      }
      return setValue(iteration.id).then(onSuccess, onError);
    }
  };
};
export { exports as default };