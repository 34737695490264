import Settings from 'app/client/core/js/modules/settings';
import Url from 'app/client/core/js/modules/url';
import View from 'app/client/core/js/modules/view';
import { SureTriggers } from 'pages/integrations/SureTriggers';
const exports = {};
exports.title = `SureTriggers Integration | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/settings/integrations/suretriggers';
};
exports.open = async () => {
  Settings.open({
    template: View.componentToTemplate(SureTriggers, 'integrations-suretriggers'),
    route: exports.route,
    title: exports.title
  });
  return false;
};
export { exports as default };