import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import AddNewStoryController from '../controllers/addNewStory';
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import Iterate from '../modules/iterate';
import ProfileModel from './profile';
import StoryModel from './story';
import _ from 'lodash';
const exports = {};

/*

Example Linked File entity:

{
  created_at: "2016-03-29T20:47:49Z"
  description: null
  id: 7733
  mention_ids: []
  name: "adrian.jpg"
  size: 1136568
  story_ids: []
  thumbnail_url: "https://api-content.dropbox.com/..."
  type: "dropbox"
  updated_at: "2016-03-29T20:47:49Z"
  uploader_id: "5640d797-9e64-48b0-adde-4324a6bc219e"
  url: "https://www.dropbox.com/s/me7cqlo9fq8oq8k/adrian%20visa%20pic.jpg?dl=0"
}

*/

Collection.create('LinkedFile', exports);
exports.isValid = obj => {
  return obj && obj.id && obj.url;
};
exports.normalize = file => {
  file.uploader = ProfileModel.getAllDetailsById(file.uploader_id);
  file.name = file.name || exports.convertURLToName(file.url);
};
exports.convertURLToName = url => {
  return _.last(_.compact(url.split('/')));
};
exports.normalizeBoxFile = file => {
  return {
    name: file.name,
    type: 'box',
    url: file.url
  };
};
exports.normalizeDropboxFile = file => {
  return {
    name: file.name,
    size: file.bytes,
    thumbnail_url: file.thumbnailLink || file.icon,
    type: 'dropbox',
    url: file.link
  };
};
exports.normalizeGoogleFile = file => {
  return {
    name: file.name,
    description: file.description,
    content_type: file.mimeType,
    size: file.sizeBytes,
    thumbnail_url: file.iconUrl,
    type: 'google',
    url: file.url
  };
};
exports.normalizeURLFile = file => {
  return {
    name: exports.convertURLToName(file.path),
    description: file.description,
    type: 'url',
    url: file.path
  };
};
exports.updateIfValid = file => {
  if (exports.isValid(file)) {
    exports.update(file);
  }
};
exports.fetchAll = callback => {
  Backend.get('/api/private/linked-files', {
    onComplete: res => {
      exports.defaultFetchAllHandler(res, callback);
    }
  });
};
exports.addLinkedFile = (file, options, callback) => {
  options = options || {};
  callback = _.isFunction(callback) ? callback : _.noop;
  const isNewStory = StoryModel.isNew({
    id: options.story_id
  });
  if (isNewStory) {
    delete options.story_id;
  }
  Backend.post('/api/private/linked-files', {
    data: _.assign({}, file, options),
    onComplete: (res, xhr) => {
      if (xhr.status === 201) {
        exports.updateIfValid(res);
        if (isNewStory) {
          AddNewStoryController.attachLinkedFileToStory(res);
        } else {
          Iterate.each(res.story_ids, id => {
            const story = StoryModel.getById(id);
            StoryModel.attachLinkedFileToStory(story, res);
            StoryModel.updateStoryOnResponse(story);
          });
        }
        callback(null, res);
      } else {
        if (_.isArray(_.get(res, 'errors.url')) && res.errors.url.length > 0) {
          callback('Invalid URL used for attachment.');
        } else if (_.get(res, 'error')) {
          callback(res.error);
        } else {
          callback('Unable to create attachment.');
        }
      }
    }
  });
};
exports.addBoxFile = (file, options, callback) => {
  exports.addLinkedFile(exports.normalizeBoxFile(file), options, callback);
};
exports.addDropboxFile = (file, options, callback) => {
  exports.addLinkedFile(exports.normalizeDropboxFile(file), options, callback);
};
exports.addGoogleFile = (file, options, callback) => {
  exports.addLinkedFile(exports.normalizeGoogleFile(file), options, callback);
};
exports.addURLFile = (file, options, callback) => {
  exports.addLinkedFile(exports.normalizeURLFile(file), options, callback);
};
exports.isLinkedType = file => {
  return file.type && exports.isBox(file) || exports.isDropbox(file) || exports.isGoogle(file) || exports.isURL(file);
};
exports.isBox = file => {
  return file.type === 'box';
};
exports.isDropbox = file => {
  return file.type === 'dropbox';
};
exports.isGoogle = file => {
  return file.type === 'google';
};

// exports.isOneDrive = function (file) {
//   return file.type === 'onedrive';
// };

exports.isURL = file => {
  return file.type === 'url';
};
exports.getIcon = file => {
  const types = {
    box: 'fa-cloud-upload',
    dropbox: 'fa-dropbox',
    google: 'fa-google',
    url: 'fa-link'
  };
  return types[file.type] || types.url;
};
exports.getAllForStory = story => {
  return exports.filter(file => {
    return _.indexOf(file.story_ids, story.id) !== -1;
  });
};
exports.removeStoryFromFile = (file, story) => {
  const linkedFile = exports.getById(file.id);
  if (linkedFile) {
    linkedFile.story_ids = _.pull(linkedFile.story_ids, story.id);
    exports.update(linkedFile);
  }
};
exports.mergeInStory = story => {
  story.linked_files = _.uniqBy(_.compact((story.linked_files || []).concat(_.map(story.linked_file_ids, id => {
    return exports.getById(id);
  }))), 'id');
  return story;
};
exports.getRelatedStoryId = linkedFile => {
  return _.head(linkedFile.story_ids);
};
export { exports as default };