import Loading from '@clubhouse/assets/gif/shortcut-loading.gif';
import MayTheFourth from '@clubhouse/assets/png/may-the-forth_600x600.png';
import { FEATURE_TOGGLES, getFeatureToggle } from '@clubhouse/feature-toggles';
import * as OrgLoadingScreenTemplate from 'app/client/core/views/templates/orgLoadingScreen.html?caveman';
import UserModule from './user';
import View from './view';
import OrganizationModel from '../models/organization';
import _ from 'lodash';
const exports = {};
const _getLoadingContainer = () => document.getElementById('loading-screen');
const _getLoadingOverlayMountNode = () => document.getElementById('loading-overlay-root');
exports.init = () => {
  const data = _.assign({}, OrganizationModel.getCurrent(), {
    defaults: exports.getDefaultLoadingState()
  });
  const html = OrgLoadingScreenTemplate.render(data);
  const loadingContainer = _getLoadingContainer();
  if (loadingContainer) {
    loadingContainer.innerHTML = html;
  }
};
exports.getDefaultLoadingState = () => {
  const currentUser = UserModule.getCurrentUser();
  if (currentUser?.joy_enabled && getFeatureToggle(FEATURE_TOGGLES.MAY_THE_FOURTH)) {
    return {
      message: 'Let me see your identification!',
      url: MayTheFourth,
      spin: false,
      alt: '"Star Wars 12" by Karen_O&apos;D is marked with CC BY 2.0.'
    };
  }
  return {
    message: '',
    url: Loading,
    spin: false,
    alt: ''
  };
};
let unmountLoadingOverlay = () => {};
exports.renderLoadingOverlay = component => {
  const {
    unmountComponent
  } = View.renderComponent({
    component,
    mountNode: _getLoadingOverlayMountNode(),
    props: {}
  });
  unmountLoadingOverlay = unmountComponent;
};
exports.hide = () => {
  const loadingContainer = _getLoadingContainer();
  if (loadingContainer) {
    loadingContainer.innerHTML = '';
  }
  unmountLoadingOverlay();
};
export { exports as default };