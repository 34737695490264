import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.every.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.some.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { Icon } from '@clubhouse/shapes-ds';
import moment from 'moment';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons/DeprecatedIconAdapter';
import { convertAccentedCharacters, getIsDueSoon, getIsOverdue } from '@clubhouse/shared/utils';
import { renderComponentToString } from 'utils/helpers';
import Constants from './constants';
import Iterate from './iterate';
import Log from './log';
import Url from './url';
import BaseUtils from '../_frontloader/baseUtils';
import Globals from '../_frontloader/globals';
import _ from 'lodash';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};
const $ = window.$;

// This is used to overwrite data-on-click attributes, basically to
// guard against double-clicking.
exports.returnFalse = () => {
  return false;
};
exports.selectText = function () {
  const element = $(this).select();

  // TODO: Explain why we need to do this:
  setTimeout(() => {
    element.select();
  }, 0);
  return false;
};
exports.deferFocus = context => {
  setTimeout(() => {
    $(context).focus();
  }, 10);
};
exports.tokenizeQuery = query => {
  const tokens = [];
  let isQuoted = false;
  let token = '';
  const chunks = (query + '').split(/ (?=(?:(?:[^"]*"){2})*[^"]*$)/);
  /*
    // Splits on every space, with below capturing quotes...
    (?=       look ahead to see if there is:
    (?:       group, but do not capture (0 or more times):
    (?:       group, but do not capture (2 times):
     [^"]*    any character except: '"' (0 or more times)
     "        '"'
    ){2}      end of grouping
    )*        end of grouping
     [^"]*    any character except: '"' (0 or more times)
    $         before an optional \n, and the end of the string
    )         end of look-ahead
  */

  Iterate.each(chunks, chunk => {
    if (!chunk) {
      return;
    }
    const first = _.head(chunk);
    const last = _.last(chunk);
    if (isQuoted) {
      if (last === '"' || last === "'") {
        token += ' ' + chunk.slice(0, -1);
        tokens.push(token);
        isQuoted = false;
      } else {
        token += ' ' + chunk;
      }
    } else {
      if (first === '"' || first === "'") {
        token = chunk.slice(1);
        isQuoted = true;
        if (last === '"' || last === "'") {
          token = token.slice(0, -1);
          tokens.push(token);
          isQuoted = false;
        }
      } else {
        token = '';
        tokens.push(chunk);
      }
    }
  });
  if (tokens.length === 0 && token) {
    tokens.push(token);
  }
  return _.compact(tokens);
};
exports.parseJSON = (str, options) => {
  options = options || {};
  let parsed = null;
  if (_.isPlainObject(str) || _.isArray(str)) {
    return str;
  }
  if (!str) {
    return options.fallback || str;
  }
  try {
    parsed = JSON.parse(str);
  } catch (e) {
    Log.error(e, {
      invalidData: _.isString(str) ? str : JSON.stringify(str)
    });
    if (options.fallback) {
      parsed = options.fallback;
    }
  }
  return parsed;
};

// The url could include a host or be absolute, so we should check for both.
// pathname = /path/to/thing
// href = http://site.com/path/to/thing
exports.isNotCurrentPage = url => {
  return window.location.pathname !== url && window.location.href !== url;
};
exports.redirect = (url, shouldCheckHref = false) => {
  Log.debug('Redirecting to ' + url);
  if (shouldCheckHref ? new URL(url, window.location.origin).href !== window.location.href : exports.isNotCurrentPage(url)) {
    window.location.href = url;
  }
};
exports.reloadPage = () => {
  Log.debug('Reloading page...');

  // biome-ignore lint/correctness/noSelfAssign: I have no idea...
  window.location.href = window.location.href;
};
exports.reloadPageWithoutQueryParams = () => {
  Log.debug('Reloading page without query params...');
  window.location.href = window.location.pathname;
};
exports.redirectToSlug = slug => {
  Log.debug('Redirecting to slug ' + slug);
  window.location.href = exports.updateSlugInURL(slug);
};
exports.updateSlugInURL = newSlug => {
  const oldPath = '/' + Url.getCurrentSlug();
  const newPath = '/' + newSlug;
  if (oldPath === '/') {
    const newURL = new URL(window.location.href);
    newURL.pathname = `${newPath}${newURL.pathname}`;
    return newURL.toString();
  }
  return window.location.href.replace(oldPath, newPath);
};
exports.switchMobileDesktopView = () => {
  const mobileThreshold = 600;
  const htmlElement = $('html');
  const windowWidth = $(window).width();
  if (htmlElement.hasClass('desktop') && windowWidth <= mobileThreshold) {
    htmlElement.removeClass('desktop').addClass('mobile');
  } else if (htmlElement.hasClass('mobile') && windowWidth > mobileThreshold) {
    htmlElement.removeClass('mobile').addClass('desktop');
  }
};
exports.formData = context => {
  const data = {};
  $('input, select, textarea, button[type="button"]', context).each(function () {
    const name = exports.attr(this, 'name');
    if (name) {
      if ($(this)[0].type === 'checkbox') {
        data[name] = $(this).is(':checked');
      } else {
        data[name] = $(this).val();
      }
    }
  });
  return data;
};
exports.toFilesize = bytes => {
  if (!_.isNumber(bytes)) {
    return 'unknown';
  }
  if (bytes === 0) {
    return '0 Bytes';
  }
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']; // YB, ORLY?
  const k = 1000;
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
};
exports.toNumber = BaseUtils.toNumber;
exports.toMoney = str => {
  if ((str + '').indexOf('.') === -1) {
    return exports.toNumber(str);
  } else {
    return exports.toNumber(str).toFixed(2);
  }
};
exports.splice = (str, start, length, replacement) => {
  return str.substr(0, start) + replacement + str.substr(start + length);
};
exports.truncate = (str, len) => {
  len = len || 30;
  return str.length > len ? str.substr(0, len - 1) + '&hellip;' : str;
};
exports.data = (el, name, val) => {
  return exports.attr(el, 'data-' + name, val);
};
exports.getFirstElementWithPermaID = id => {
  return document.querySelector(`[data-perma-id="${id}"]`);
};
exports.attr = (el, name, val) => {
  if (el && el.jquery) {
    el = el[0];
  }

  // getAttribute/setAttribute = IE 9+
  if (el && el.getAttribute) {
    if (typeof val !== 'undefined') {
      el.setAttribute(name, val);
    } else {
      const attr = el.getAttribute(name);
      return exports.exists(attr) ? exports.toNumber(attr) : attr;
    }
  }
};
exports.splitCSV = str => {
  return _.map(str.split(','), row => {
    return row.trim();
  });
};
exports.isUnfocusedKeyPress = (e, key) => {
  return exports.keyPressed(e, key) && !exports.isFocusedInFormElement();
};
exports.keyPressed = (e, key, options = {}) => {
  const {
    shouldMatchWithAllModifiers
  } = options;
  const keys = {
    LEFT_CLICK: 1,
    MIDDLE_CLICK: 2,
    RIGHT_CLICK: 3,
    BACKSPACE: 8,
    SHIFT: 16,
    CTRL: 17,
    ALT_OPTION: 18,
    LEFT_CMD: 91,
    RIGHT_CMD: 93,
    TAB: 9,
    ENTER: 13,
    ESCAPE: 27,
    LEFT_ARROW: 37,
    UP_ARROW: 38,
    RIGHT_ARROW: 39,
    DOWN_ARROW: 40,
    DELETE: 46,
    TILDE: 192,
    A: 65,
    D: 68,
    J: 74,
    K: 75
  };
  if (_.isArray(key)) {
    let match = false;
    Iterate.each(key, k => {
      if (exports.keyPressed(e, k, options)) {
        match = true;
      }
    });
    return match;
  }
  let modifiers = [];
  if (_.includes(key, '+')) {
    modifiers = key.split('+');
    key = modifiers.pop();
  }
  if (_.includes(modifiers, 'CMD') && !e.metaKey || _.includes(modifiers, 'ALT') && !e.altKey || _.includes(modifiers, 'SHIFT') && !e.shiftKey || _.includes(modifiers, 'CTRL') && !e.ctrlKey) {
    return false;
  }
  if (!shouldMatchWithAllModifiers && modifiers.length === 0 && exports.isAnyModifierPressed(e)) {
    return false;
  }
  return !!(e && e.which === keys[key]);
};
exports.isAnyModifierPressed = e => e.metaKey || e.altKey || e.shiftKey || e.ctrlKey;
exports.exists = obj => {
  return typeof obj !== 'undefined' && obj !== null;
};
exports.isEvent = obj => {
  return !!(obj && obj.type && obj.target);
};

// exports.toAlphaNumeric = function (str) {
//   return (str + '').replace(/[^A-Za-z0-9\u00C0-\u017F]/g, '');
// };

exports.escapeRegex = str => {
  return (str || '').replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
};

// Ref: https://www.kernel.org/pub/software/scm/git/docs/git-check-ref-format.html
// Accented Regex: http://stackoverflow.com/a/11550799/440094
exports.slugify = (str, options) => {
  const defaults = {
    delimiter: '-',
    limit: 40
  };
  options = _.assignIn(defaults, options || {});
  let output = '';
  const words = (str + '').replace(/[^A-Za-z0-9\u00C0-\u017F]/g, ' ').replace(/\s\s+/g, ' ').trim().split(' ');
  Iterate.each(words, word => {
    if (output.length > options.limit) {
      return false;
    }
    output += word + ' ';
  });
  output = output.trim().replace(/ /g, options.delimiter);
  if (options.toLowerCase !== false) {
    output = output.toLowerCase();
  }
  return output;
};

// Commit message titles should be max 50 characters,
// so it should be capped at 40 so we have room for [ch1234].
exports.toCommitMessage = str => {
  return exports.slugify(str, {
    delimiter: ' ',
    toLowerCase: false
  });
};
exports.capitalize = str => {
  str = str + '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};
exports.chunk = (arr, count) => {
  count = count || 3;
  const chunks = [];
  const length = arr.length;
  _.times(count, n => {
    const chunk = [];
    for (let i = 0 + n; i < length; i = i + count) {
      chunk.push(arr[i]);
    }
    chunks.push(chunk);
  });
  return chunks;
};
exports.arrayMove = (arr, oldIndex, newIndex) => {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length;
    while (k-- + 1) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

// Original implementation:
// https://github.com/jarofghosts/loose-interleave/blob/master/index.js
exports._getMaxLengthOfArrays = arrays => {
  return _.max(_.map(arrays, arr => {
    return arr.length;
  }));
};

// Original implementation:
// https://github.com/jarofghosts/loose-interleave/blob/master/index.js
exports.interleave = arrays => {
  const maxLength = exports._getMaxLengthOfArrays(arrays);
  const result = [];
  for (let i = 0; i < maxLength; ++i) {
    Iterate.each(arrays, arr => {
      if (arr.length > i) {
        result.push(arr[i]);
      }
    });
  }
  return result;
};
exports.replaceInArray = (arr, oldItem, newItem) => {
  const index = _.indexOf(arr, _.find(arr, oldItem));
  arr.splice(index, 1, newItem);
};
exports.simpleHash = str => {
  if (str === 0) return str;
  let hash = 0;
  let i;
  let len;
  let c;
  for (i = 0, len = str.length; i < len; i++) {
    c = str.charCodeAt(i);
    hash = (hash << 5) - hash + c;
    hash |= 0; // Convert to 32bit integer
  }
  return hash < 0 ? hash * -1 : hash;
};
exports.cssify = str => {
  return convertAccentedCharacters(str).toLowerCase().replace(/[^a-z0-9\-\_]/g, '');
};
exports.suggestUsername = text => {
  return convertAccentedCharacters(text).toLowerCase().replace(/[^a-z0-9]/g, '');
};
exports.removeFromArrayByProperty = (arr, key, val) => {
  let i = 0;
  let obj;
  while (i < arr.length) {
    obj = arr[i];
    if (_.isPlainObject(obj) && obj[key] === val) {
      arr.splice(i, 1);
    } else {
      i++;
    }
  }
  return arr;
};
exports.sortByProperty = (arr, prop) => {
  if (_.isFunction(prop)) {
    return arr.sort(prop);
  }
  return arr.sort((a, b) => {
    return a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1;
  });
};
exports.objectLength = obj => {
  return obj ? _.map(obj, (n, i) => {
    return i;
  }).length : 0;
};
exports.objectSum = obj => {
  let sum = 0;
  Iterate.each(obj, name => {
    sum += exports.toNumber(name);
  });
  return sum;
};

// Assumes an element (or parent element) with data-model and data-id attributes.
exports.getModelIdFromContext = (context, type) => {
  const dataAttr = type ? '[data-model="' + type + '"]' : '[data-model]';
  const element = $(context).closest(dataAttr);
  if (element.length) {
    const id = exports.data(element, 'id');
    if (id) return id;
  }
  return false;
};

// Assumes an element (or parent element) with data-model and data-id attributes.
exports.getModelFromContext = (context, type) => {
  const dataAttr = type ? '[data-model="' + type + '"]' : '[data-model]';
  const element = $(context).closest(dataAttr);
  if (element.length) {
    const modelName = exports.data(element, 'model');
    const id = exports.data(element, 'id');
    if (modelName && id) {
      const model = App.Model[modelName].get({
        id
      });
      if (model) {
        return model;
      } else {
        Log.log('No model found with matching id', {
          model_id: id,
          model_name: modelName,
          type: 'Template'
        });
        return false;
      }
    } else {
      Log.log('Model element missing data-model or data-id attribute value.', {
        model_id: id,
        model_name: modelName,
        type: 'Template'
      });
      return false;
    }
  }
  return false;
};
exports.getElementBounds = element => {
  const offset = element && $(element).offset();
  return offset ? {
    bottom: offset.top + element.outerHeight(),
    left: offset.left,
    right: offset.left + element.outerWidth(),
    top: offset.top
  } : false;
};
exports.outsideBounds = (bounds, x, y) => {
  // Handle cases where click is actually a keypress:
  if (x === 0 && y === 0) {
    return false;
  }
  return x < bounds.left || x > bounds.right || y < bounds.top || y > bounds.bottom;
};
exports.nextClickOutsideElement = (element, callback) => {
  const eventName = 'click.NextOutsideElement.' + Date.now();
  exports.onOutsideBounds(eventName, element, callback);
};
exports.nextMoveOutsideElement = (element, callback) => {
  const eventName = 'mousemove.NextMoveOutsideElement' + Date.now();
  exports.onOutsideBounds(eventName, element, callback);
};
exports.nextMoveOutsideElements = (elements, callback) => {
  const eventName = 'mousemove.NextMoveOutsideElements' + Date.now();
  exports.onOutsideBoundsRange(eventName, elements, callback);
};
exports.onOutsideBounds = (eventName, element, callback) => {
  $('body').on(eventName, e => {
    // Also unbind if element doesn't exist anymore
    const bounds = element && exports.getElementBounds(element);
    if (!bounds || exports.outsideBounds(bounds, e.pageX, e.pageY)) {
      $('body').off(eventName);
      callback.call(element);
    }
  });
};
exports.onOutsideBoundsRange = (eventName, elements, callback) => {
  $('body').on(eventName, e => {
    const outsideBounds = _.every(elements, element => {
      if (_.isString(element)) {
        element = $(element);
        if (element.length === 0) {
          return true;
        }
      }
      return exports.outsideBounds(exports.getElementBounds(element), e.pageX, e.pageY);
    });
    if (outsideBounds) {
      $('body').off(eventName);
      callback(elements);
    }
  });
};
exports.animationDisabled = () => {
  return Globals.get('animationEnabled') === false;
};
exports.enableAnimation = () => {
  $('body').addClass('use-animation');
  Globals.set('animationEnabled', true);
};
exports.disableAnimation = () => {
  $('body').removeClass('use-animation');
  Globals.set('animationEnabled', false);
};
exports.setArchivedClass = (entity = {}) => {
  const className = 'archived-page';
  if (entity.archived) {
    $('html').addClass(className);
  } else {
    $('html').removeClass(className);
  }
};
exports.clearArchivedClass = () => {
  $('html').removeClass('archived-page');
};
exports.pushOnce = (collection, obj) => {
  const method = _.isPlainObject(obj) ? 'some' : 'includes';
  if (!_[method](collection, obj)) {
    collection.push(obj);
  }
  return collection;
};
exports.autoTabIndex = (context, shouldIncrement = true) => {
  let i = 100;
  $('[data-tabindex]').each(function () {
    $(this).attr('tabindex', shouldIncrement ? i++ : 0);
  });
  i = 1;
  $('[data-tabindex]', context).each(function () {
    $(this).attr('tabindex', shouldIncrement ? i++ : 0);
  });
};
exports.getStoryTypes = story => {
  return [{
    name: 'Feature',
    value: 'feature',
    customIconLeft: renderComponentToString(_jsx("span", {
      style: {
        marginRight: 6
      },
      children: _jsx(DeprecatedIconAdapter, {
        fill: "var(--iconYellowColor)",
        width: 20,
        children: _jsx(Icon, {
          icon: "Feature"
        })
      })
    })),
    className: story && story.story_type === 'feature' ? 'active' : ''
  }, {
    name: 'Bug',
    value: 'bug',
    customIconLeft: renderComponentToString(_jsx("span", {
      style: {
        marginRight: 6
      },
      children: _jsx(DeprecatedIconAdapter, {
        fill: "var(--iconRedColor)",
        width: 20,
        children: _jsx(Icon, {
          icon: "Bug"
        })
      })
    })),
    className: story && story.story_type === 'bug' ? 'active' : ''
  }, {
    name: 'Chore',
    value: 'chore',
    customIconLeft: renderComponentToString(_jsx("span", {
      style: {
        marginRight: 6
      },
      children: _jsx(DeprecatedIconAdapter, {
        fill: "var(--iconGrayHoverColor)",
        width: 20,
        children: _jsx(Icon, {
          icon: "Chore"
        })
      })
    })),
    className: story && story.story_type === 'chore' ? 'active' : ''
  }];
};

// Ref: https://stackoverflow.com/a/2117523/440094
exports.generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
};
exports.versionCompare = (a = '', b = '') => {
  if (a === b) {
    return 0;
  }
  const separator = '-';
  const [aVersion] = a.split(separator);
  const [bVersion] = b.split(separator);
  return Number.parseInt(aVersion, 10) > Number.parseInt(bVersion, 10) ? 1 : -1;
};
exports.dragDistance = () => {
  const xs = Globals.get('mouseupX') - Globals.get('mousedownX');
  const ys = Globals.get('mouseupY') - Globals.get('mousedownY');
  return Math.sqrt(xs * xs + ys * ys);
};
exports.scrollToTop = () => {
  // We use body for Chrome/Safari, html for Firefox.
  $('html, body').animate({
    scrollTop: 0
  }, 300);
};

// turns ['a', 'b', 'c', 'd'] into 'a, b, c, and d'
//  - ['a', 'b'] => 'a and b'
//  - ['a'] => 'a'
exports.grammaticalJoin = arr => {
  let str = '';
  Iterate.each(arr, (part, i) => {
    str += part;
    if (i === arr.length - 2) {
      // only include the comma if there are three or more items
      if (arr.length === 2) {
        str += ' and ';
      } else {
        str += ', and ';
      }
    } else if (i < arr.length - 2) {
      str += ', ';
    }
  });
  return str;
};
exports.rateLimit = (fn, rate) => {
  const x = Math.floor(Math.random() * rate);
  if (x === 0) {
    fn();
  }
};

/*
exports.findElementReferences = function (context) {
  context = context || window.App;

  Iterate.each(context, function (val, key) {
    if (_.isPlainObject(val) || _.isArray(val) || (val && !_.isString(val) && val.length)) {
      exports.findElementReferences(val);
    } else if (_.isElement(val)) {
      window.console.log('FOUND', key, val);
    }
  });
};
*/

exports.destroyDatepicker = element => {
  element.datepicker('destroy');

  // This appears to solve a memory leak with the datepicker module.
  // Ref: http://bugs.jquery.com/ticket/12171
  if ($.datepicker.dpDiv && $.datepicker.dpDiv.unbind) {
    $.datepicker.dpDiv.unbind();
  }
  $.datepicker.dpDiv = null;
};
exports.destroySortable = element => {
  if (element.sortable('instance')) {
    element.sortable('destroy');
  }
};
exports.fadePageIn = () => {
  setTimeout(() => {
    $('.not-yet-zoomed').removeClass('not-yet-zoomed').addClass('zoomed');
  }, 250);
};
exports.fadePageOut = () => {
  $('.zoomed').removeClass('zoomed').addClass('not-yet-zoomed');
};
exports.isFocusedInElements = elements => {
  return $(document.activeElement).is(elements);
};
exports.isFocusedInFormElement = () => {
  return exports.isFocusedInElements('input, textarea');
};
exports.isFocusedInTextarea = () => {
  return exports.isFocusedInElements('textarea');
};
exports.reloadImage = function () {
  const src = $(this).attr('src').split('?')[0];
  $(this).attr('src', src + '?' + Date.now());
};
exports.stripQuotes = str => {
  const first = _.head(str);
  const last = _.last(str);
  if (first === '"' && last === '"' || first === "'" && last === "'") {
    str = str.slice(1, -1);
  }
  return str;
};
exports.yesOrNo = val => {
  return val ? 'Yes' : 'No';
};
exports.detachFormattedPlannedStartDate = entity => {
  delete entity.formatted_planned_start_date;
  delete entity.planned_start_date_timestamp;
};
exports.attachFormattedPlannedStartDate = entity => {
  if (entity.planned_start_date) {
    const d = moment(entity.planned_start_date);
    entity.formatted_planned_start_date = d.format(Constants.SHORT_DATE_FORMAT);
    entity.planned_start_date_timestamp = d.valueOf();
  } else {
    exports.detachFormattedPlannedStartDate(entity);
  }
  return entity;
};
exports.detachFormattedDeadlines = entity => {
  delete entity.formatted_deadline;
  delete entity.formatted_short_deadline;
  delete entity.deadline_timestamp;
};
exports.attachFormattedDeadlines = entity => {
  if (entity.deadline) {
    // Using the same moment() instance leads to a 30-40% performance improvement.
    const d = moment(entity.deadline);
    entity.formatted_deadline = d.format(Constants.SHORT_DATE_FORMAT);
    entity.formatted_short_deadline = d.format(Constants.SHORT_DATE_NO_YEAR);
    entity.deadline_timestamp = d.valueOf();
  } else {
    exports.detachFormattedDeadlines(entity);
  }
  return entity;
};
exports.getDeadlineClass = entity => {
  let className = '';
  if (getIsOverdue(entity.deadline)) {
    className = 'deadline-overdue';
  } else if (getIsDueSoon(entity.deadline)) {
    className = 'deadline-warning';
  }
  return className;
};

// Accepts and returns a moment date object
exports.getSameDateWithDifferentUtcOffset = (date, utcOffset) => {
  const updated = date.clone();
  updated.utcOffset(utcOffset);
  updated.subtract(date.utcOffset() - updated.utcOffset(), 'minutes');
  return updated;
};

// Returns a moment object
exports.getLastBusinessDay = () => {
  let lastDay = moment().subtract(1, 'day');
  const dayNumber = lastDay.day(); // 0: Sunday, 6: Saturday

  if (dayNumber === 0) {
    lastDay = lastDay.subtract(2, 'day');
  } else if (dayNumber === 6) {
    lastDay = lastDay.subtract(1, 'day');
  }
  return lastDay;
};
exports.utcDay = momentDate => momentDate.utc().startOf('day');
exports.insertAtCursor = (textarea, str) => {
  const contents = textarea.val();
  const count = contents.length;
  const cursorPos = textarea.prop('selectionStart') || count;
  const textBefore = contents.substring(0, cursorPos);
  const textAfter = contents.substring(cursorPos, count);
  textarea.val(textBefore + str + textAfter);
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
exports.roundToDecimal = (value, exp) => {
  if (!exp) {
    return Math.round(value);
  }
  value = +value;
  exp = +exp;
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return Number.NaN;
  }

  // Shift
  value = value.toString().split('e');
  value = Math.round(+(value[0] + 'e' + (value[1] ? +value[1] + exp : exp)));

  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp));
};
exports.scrollToElement = (element, callback, containerSelector) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  const pos = _.get(element.offset(), 'top', 0) - ($('#header-raven').outerHeight() || 0) - ($('.scroll-tabs').outerHeight() || 0) + ($(containerSelector || '#content').scrollTop() || 0);
  $(containerSelector || '#content').animate({
    scrollTop: pos
  }, 800, callback);
};

// A JS-only implementation of _.sample, which returns a random element of an array:
// Ref: https://stackoverflow.com/questions/5915096/get-random-item-from-javascript-array
exports.sample = arr => {
  return arr[Math.floor(Math.random() * arr.length)];
};
exports.hasKeys = (obj, keys) => {
  return _.intersection(_.keys(obj), keys).length === keys.length;
};
exports.hasAllValues = obj => {
  return !!_.compact(_.values(obj)).length;
};
exports.startsWithVowel = word => {
  return _.some(['a', 'e', 'i', 'o', 'u'], vowel => {
    return _.startsWith(word, vowel);
  });
};
exports.pluckAll = (collection, values) => {
  return _.map(collection, _.partialRight(_.pick, values));
};
exports.sortByCaseInsensitive = (collection, prop) => {
  return _.sortBy(collection, obj => {
    return (obj[prop] || '').toLowerCase();
  });
};
exports.strToFunction = $.fastbinder.strToFunction;
exports.fireEventHandler = $.fastbinder.fireEventHandler;
exports.renameObjectKeys = (obj, pattern, replacement) => {
  if (!_.isObject(obj)) {
    return;
  }
  Object.keys(obj).forEach(key => {
    if (key.match(pattern)) {
      const newKey = key.replace(pattern, replacement);
      if (!obj[newKey]) {
        obj[newKey] = obj[key];
      }
      delete obj[key];
    }
    if (_.isObject(obj[key])) {
      exports.renameObjectKeys(obj[key], pattern, replacement);
    }
  });
};
exports.openInNewTabWithNoopener = href => {
  const a = document.createElement('a');
  a.rel = 'noopener noreferrer';
  a.target = '_blank';
  a.href = href;
  a.click();
};
const IS_INITIAL_PAGE_RENDER_COMPLETE = 'utils.isInitialPageRenderComplete';
exports.isInitialPageRenderComplete = () => {
  return Boolean(Globals.get(IS_INITIAL_PAGE_RENDER_COMPLETE));
};
exports.setIsInitialPageRenderComplete = value => {
  Globals.set(IS_INITIAL_PAGE_RENDER_COMPLETE, value);
};
exports.getRandomInt = max => {
  return Math.floor(Math.random() * Math.floor(max));
};

// These colors are provided by GitHub. We expect them to be valid hex values.
// Some colors were stored without a `#` since GitHub sends colors without them.
exports.fixColor = color => {
  const defaultColor = '#f3f3f3';
  if (color.length === 6) {
    return '#' + color;
  } else if (color.length === 7) {
    return color;
  } else {
    return defaultColor;
  }
};
exports.hexToRgb = hexColor => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
  return {
    r: Number.parseInt(result[1], 16),
    g: Number.parseInt(result[2], 16),
    b: Number.parseInt(result[3], 16)
  };
};

// http://www.w3.org/TR/AERT#color-contrast
exports.textForColor = color => {
  const hexColor = exports.fixColor(color);
  const rgbColor = exports.hexToRgb(hexColor);
  const brightness = Math.round((299 * rgbColor.r + 587 * rgbColor.g + 114 * rgbColor.b) / 1000);
  return brightness > 125 ? 'black' : 'white';
};
exports.modifiedClick = e => {
  return exports.isAnyModifierPressed(e) || exports.keyPressed(e, 'MIDDLE_CLICK');
};
export { exports as default };