import { resetPageCSS } from 'app/client/core/js/modules/resetPageCSS';
import css from 'app/client/settings/less/app.less?url';
import Boot from '../../../core/js/modules/boot';
import Url from '../../../core/js/modules/url';
import Layout from '../../../core/js/modules/layout';
import ApplicationState from '../../../core/js/modules/applicationState';
import ConsolidatedFetch from '../../../core/js/modules/consolidatedFetch';
import SettingsPageController from '../controllers/settingsPage';
import SlackAuth from './slackAuth';
import Updates from '../../../core/js/modules/updates';
const exports = {};
const fetchAndRender = () => {
  ConsolidatedFetch.fetchConsolidatedDataAndActivity(() => {
    SettingsPageController.render();
  });
};
const setupPage = callback => {
  Layout.renderContent('<div id="settings-page" class="page-content"></div>');
  if (Url.getCurrentSlug() !== '') {
    // Only update the application state if this is a page within a workspace.
    // e.g. organization/settings/account should not be saved
    ApplicationState.setLastView(Url.getCurrentPathnameWithHash());
  }
  Updates.init();
  SettingsPageController.init();
  callback();
};
const _authAndSetup = callback => {
  if (Url.isSlackIdentify()) {
    return SlackAuth.handleSlackIdentify().finally(() => setupPage(callback));
  }
  return setupPage(callback);
};
const _init = () => {
  _authAndSetup(fetchAndRender);
};
const _reInit = () => {
  _authAndSetup(() => SettingsPageController.render());
};

// make public for tests
exports._init = _init;
exports.boot = async () => {
  await resetPageCSS(css);
  Boot.initializePage(_init, _reInit);
};
exports.loadStyles = async () => {
  await resetPageCSS(css);
};
export { exports as default };