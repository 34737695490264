import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import BaseUtils from 'app/client/core/js/_frontloader/baseUtils';
import EpicStateModel from 'app/client/core/js/models/epicState';
import sortBy from 'lodash/sortBy';
import { useEntities, useOptimizedEntities } from './collection';
export const getEpicStateByGlobalId = globalId => EpicStateModel.get({
  global_id: globalId
});
export const getEpicStateById = id => EpicStateModel.getById(id);
export const all = () => EpicStateModel.all();
export const sortByPosition = (epicStates = all()) => sortBy(epicStates, 'position');
export const getStateTypes = () => EpicStateModel.getStateTypes();
export const useEpicStates = () => {
  const {
    entities
  } = useEntities({
    model: EpicStateModel
  });
  const epicStates = sortByPosition(entities);
  return {
    epicStates
  };
};
export const useOptimizedEpicStates = ({
  fields,
  filter
}) => useOptimizedEntities({
  model: EpicStateModel,
  fields,
  filter
});
export const getDefaultState = () => EpicStateModel.getDefaultState();
export const getDefaultStateId = () => EpicStateModel.getDefaultStateID();
export const setDefaultState = id => new Promise((resolve, reject) => {
  EpicStateModel.setDefault(id, (err, res) => {
    if (err) reject(err);else resolve(res);
  });
});
export const isOnlyOneOfType = id => EpicStateModel.isOnlyOneOfType(id);
export const isSafeToDelete = id => EpicStateModel.isSafeToDelete(id);
export const saveNewEpicState = data => new Promise((resolve, reject) => {
  EpicStateModel.saveNew(data, (err, res) => {
    if (err) reject(err);else resolve(res);
  });
});
export const deleteEpicState = data => new Promise((resolve, reject) => {
  EpicStateModel.deleteState(data, (err, res) => {
    if (err) reject(err);else resolve(res);
  });
});
export const saveEpicStateEdits = (epicStateId, updates) => new Promise((resolve, reject) => {
  EpicStateModel.saveUpdate(epicStateId, updates, (err, res) => {
    if (err) reject(err);else resolve(res);
  });
});
export const updatePosition = (epicStateId, updates) => {
  const updatePromise = BaseUtils.promisify(EpicStateModel.saveUpdate);
  return updatePromise(epicStateId, updates);
};