import Constants from './constants';
import * as Environment from './env';
import Url from './url';
import _ from 'lodash';
const exports = {};
exports.internal = Environment.internal;
exports.development = Environment.development;
exports.staging = Environment.staging;
exports.production = Environment.production;
exports.testEnvironment = Environment.testEnvironment;

// Ref: https://github.com/galori/simple-useragent/blob/master/lib/simple-useragent.rb
// Ref: http://stackoverflow.com/a/3540295
exports.mobile = userAgent => {
  userAgent = userAgent || navigator.userAgent;
  const regex = /(android|blackberry|iemobile|iphone|ipod|opera mini|palm|windows phone)/i;
  return regex.test(userAgent);
};
exports.tablet = () => {
  if (navigator.userAgent.match(/Tablet|iPad/i)) return true;
  if (/MacIntel/i.test(navigator.platform) && typeof navigator.maxTouchPoints === 'number' && navigator.maxTouchPoints > 2) return true;
  return false;
};

// Ref: http://stackoverflow.com/a/11752084
exports.mac = () => {
  return window.navigator.platform.toLowerCase().indexOf('mac') !== -1;
};
exports.https = url => {
  return url.indexOf('https://') === 0;
};
exports.adminOnly = permission => {
  return _.get(permission, 'role') === 'admin';
};

/**
 * @deprecated Use adminOrOwner
 */
exports.admin = permission => exports.adminOrOwner(permission);
exports.adminOrOwner = permission => {
  return exports.adminOnly(permission) || exports.ownerOnly(permission);
};

/**
 * @deprecated Use ownerOnly
 */
exports.owner = permission_or_profile => exports.ownerOnly(permission_or_profile);
exports.ownerOnly = permission_or_profile => {
  return Boolean(permission_or_profile?.is_owner);
};
exports.readOnly = (user // Contributor roles will be added here in the future
) => {
  return exports.observer(user);
};
exports.observer = user => {
  return _.get(user, 'role') === 'observer';
};
exports.anonPage = () => {
  const page = Url.getCurrentPage();
  return _.includes(Constants.ANON_PAGES, page);
};
exports.isRecoveryCodePage = () => {
  return window.location.pathname.includes('manage-security') && window.location.hash === '#recovery';
};
exports.loggedInPage = () => {
  return !exports.anonPage();
};
exports.storiesPage = () => {
  const page = Url.getCurrentPage();
  return page === 'stories' || page === 'story';
};
exports.storyPage = () => {
  const page = Url.getCurrentPage();
  return page === 'story';
};
exports.epicPage = () => {
  const page = Url.getCurrentPage();
  return page === 'epic';
};
exports.organizationsPage = () => {
  const page = Url.getCurrentPage();
  return page === 'organizations';
};
exports.companyPage = () => {
  const url = Url.getCurrentPathname();
  return _.startsWith(url, '/organizations/') && _.endsWith(url, '/manage');
};
exports.withinOrg = () => {
  // This is an exception where the settings page is embedded inside /organizations
  if (Url.isOrganizationsSettingsPage()) {
    return false;
  }
  const page = Url.getCurrentPage();
  const nonOrgPages = ['auth', 'oauth', 'organizations'];
  return !nonOrgPages.includes(page);
};
exports.arrayString = str => {
  return _.isString(str) && (str === '[]' || /^\[.*\]$/.test(str));
};
exports.objectString = str => {
  return _.isString(str) && (str === '{}' || /^{".+}$/.test(str));
};
exports.numericString = str => {
  return _.isString(str) && (/^\-?[0-9]+$/.test(str) || /^\-?[0-9]+\.[0-9]+$/.test(str));
};
exports.emojiShortname = str => {
  return _.isString(str) && /^\:[a-z0-9\_\-\+]*\:$/.test(str);
};
export { exports as default };