import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import InviteModel from 'app/client/core/js/models/invite';
import { useEntities, useEntity } from './collection';
export const useInvites = () => {
  const {
    entities
  } = useEntities({
    model: InviteModel
  });
  return entities;
};
export const usePendingInvites = () => {
  const {
    entities
  } = useEntities({
    model: InviteModel
  });
  return entities.filter(invite => !invite.used);
};
export const acceptInvite = id => new Promise((resolve, reject) => {
  InviteModel.accept(id, (err, res) => {
    if (err) reject(err);else resolve(res);
  });
});
export const usePendingInvite = id => {
  const {
    entity
  } = useEntity({
    id,
    model: InviteModel
  });
  return entity;
};
export const inviteGhostUsers = async data => {
  return InviteModel.Promises.createInvites({
    invites: data
  }, {
    rejectErrorsInSuccessfulResponse: true
  });
};
export const createInvites = (data, options) => InviteModel.Promises.createInvites({
  invites: data
}, {
  ...options,
  rejectErrorsInSuccessfulResponse: true
});
export const deleteInvite = (invite, options) => InviteModel.Promises.deleteInvite(invite, options);
export const resendInvite = (invite, options) => InviteModel.Promises.resend(invite, options);
export const isEmailInvited = email => InviteModel.filter({
  email
}).length > 0;
export const createFromInviteLink = (...args) => InviteModel.createFromInviteLink(...args);
export const invite = (...args) => InviteModel.Promises.invite(...args);