import * as LoginFormTemplate from 'app/client/login/views/templates/loginForm.html?caveman';
import { SigninWithGitHub } from 'components/login/SigninWithGitHub';
import { SigninWithGoogle } from 'components/login/SigninWithGoogle';
import ApplicationState from '../../../core/js/modules/applicationState';
import Backend from '../../../core/js/modules/backend';
import ConsolidatedFetch from '../../../core/js/modules/consolidatedFetch';
import Constants from '../../../core/js/modules/constants';
import * as Event from '../../../core/js/_frontloader/event';
import Log from '../../../core/js/modules/log';
import OrganizationModel from '../../../core/js/models/organization';
import PageLoadAction from '../../../core/js/modules/pageLoadAction';
import Url from '../../../core/js/modules/url';
import UserModel from '../../../core/js/models/user';
import Utils from '../../../core/js/modules/utils';
import View from '../../../core/js/modules/view';
import FlashController from '../../../core/js/controllers/flash';
import Segment from '../../../core/js/modules/segment';
import { getSsoLookupUrlForSpaceWithRedirect } from 'utils/navigation/sso';
import { EVENTS } from 'utils/monitoring';
import * as ServiceWorkerUtils from 'components/shared/utils/serviceWorker';
import { clearPersistentCache as clearPersistentApolloCache } from 'gql/utils/cacheUtils';
import _ from 'lodash';
const exports = {};
const signUpURL = `https://${BRAND.DOMAIN_CORP_WEBSITE}/signup`;
const PARENT_SELECTOR = '#login-form';
const USER_LOCKED_OUT = 1;
const INVITE_REQUIRES_SIGN_IN = 2;
const INVITE_REQUIRES_SAME_USER = 3;
const USER_NOT_FOUND = 4;
const OAUTH_CANCELLED = 5;
const OAUTH_UNKNOWN_ERROR = 6;
const EXISTING_ERRORS = {
  [USER_LOCKED_OUT]: {
    title: 'User is locked out.',
    message: 'This user has been locked out, please ' + `<a href="https://${BRAND.DOMAIN_CORP_WEBSITE}/contact">contact us</a> for further information.`
  },
  [INVITE_REQUIRES_SIGN_IN]: {
    title: 'Log in to accept invite.',
    message: 'For security purposes, we require you to log in to verify you are the owner of the email address.'
  },
  [INVITE_REQUIRES_SAME_USER]: {
    title: 'Wrong user logged in.',
    message: 'You must be logged in with the email address that received the invite to join that organization.'
  },
  [USER_NOT_FOUND]: {
    title: 'User not found.',
    message: `<a href="${signUpURL}">Click here to create an account.</a>`
  },
  [OAUTH_CANCELLED]: {
    title: 'OAuth login flow cancelled.',
    message: `You initiated an OAuth login, but cancelled or rejected it.`
  },
  [OAUTH_UNKNOWN_ERROR]: {
    title: 'OAuth login flow failed.',
    message: `Something caused the OAuth login flow to fail. Please try again and ` + `<a href="https://${BRAND.DOMAIN_CORP_WEBSITE}/contact">contact us</a> if the issue persists.`
  }
};
exports.init = () => {
  ServiceWorkerUtils.unregisterAndDeleteCache();
  clearPersistentApolloCache();
  exports.trySSOLoginOnlyIfRequired(Url.getCurrentSlug());
  exports.render();
  Utils.autoTabIndex($(PARENT_SELECTOR));
  $(`${PARENT_SELECTOR} input`).eq(0).focus();
  Utils.fadePageIn();
};
exports.render = () => {
  View.renderWithComponents({
    template: LoginFormTemplate,
    templateData: {
      signUpURL
    },
    templateMountNode: document.querySelector('#form-container'),
    components: {
      signInWithGitHub: {
        component: SigninWithGitHub,
        props: {
          href: getGitHubOauthEndpoint(),
          onClick: () => trackLogin({
            is_oauth: true
          })
        }
      },
      signInWithGoogle: {
        component: SigninWithGoogle,
        props: {
          href: getGoogleOauthEndpoint(),
          onClick: () => trackLogin({
            is_oauth: true
          })
        }
      }
    }
  });
  exports.showExistingErrors();
  Event.trigger('pageRendered', 'Login');
};
const getGitHubOauthEndpoint = () => {
  let href = `${Backend.getServerURL()}/oauth/github/login`;
  const {
    dest
  } = Url.parseLocationSearch() || {};
  if (dest) {
    href = href.concat(Url.formatQueryParams({
      dest
    }));
  }
  return href;
};
const getGoogleOauthEndpoint = () => {
  // TODO: (toby) Update this to /oauth/google/login once we update the Google App
  // to support that route
  let href = `${Backend.getServerURL()}/oauth/login`;
  const {
    dest
  } = Url.parseLocationSearch() || {};
  if (dest) {
    href = href.concat(Url.formatQueryParams({
      dest
    }));
  }
  return href;
};
const trackLogin = (data = {}) => {
  Segment.sendEvent(EVENTS.Login_Attempted, {
    is_oauth: false,
    ...data
  });
};
exports.login = function () {
  trackLogin();
  const data = Utils.formData($(PARENT_SELECTOR));
  if (!data.username) {
    $('#login-name').focus();
    return false;
  } else {
    data.username = data.username.toLowerCase();
  }
  if (!data.password) {
    $('#login-password').focus();
    return false;
  }

  // Reset input in case password manager filled in username.
  if ($('#two-factor-login-tab').hasClass('hidden')) {
    data.two_factor_code = '';
  }

  // Guard against user including spaces e.g. "123 456"
  if (data.two_factor_code) {
    data.two_factor_code = UserModel.sanitizeTwoFactorCode(data.two_factor_code);
  }
  const button = $(this);
  exports.disableButton(button);
  exports.attemptLogin(data, {
    button
  });
  return false;
};
exports.attemptLogin = (data, options) => {
  Backend.post('/login', {
    data,
    onError: options.onError || (res => {
      if (res && res.tag === 'two_factor_required') {
        exports.drawTwoFactorForm();
      } else if (res && res.tag && res.tag !== 'unauthorized') {
        exports.drawLoginError(options.button, data.username, res.message);
      } else if (res && res.error && res.tag !== 'unauthorized') {
        // This path can occur if the server returned a 0, 500, or 503,
        // i.e. if the server is down or the request failed, maybe due to a firewall,
        // browser extension, or network connection loss.
        exports.drawLoginError(options.button, data.username, res.error);
      } else {
        exports.drawLoginError(options.button, data.username);
      }
    }),
    onSuccess: options.onSuccess || (res => {
      if (_.get(res, 'tag') === 'unauthorized') {
        exports.drawLoginError(options.button, data.username);
      } else if (_.get(res, 'message')) {
        exports.drawLoginError(options.button, data.username, res.message);
      } else {
        exports.handleLoginSuccess();
      }
    })
  });
};
exports.trySSOLogin = (spaceSlug, onFailure = _.noop) => {
  Backend.get(getSsoLookupUrlForSpaceWithRedirect(spaceSlug), {
    excludeOrgHeader: true,
    onComplete: (res = {}) => {
      if (res.saml_signon_url) {
        Utils.redirect(res.saml_signon_url);
      } else {
        onFailure(res);
      }
    }
  });
};
exports.trySSOLoginOnlyIfRequired = (spaceSlug, onFailure = _.noop) => {
  Backend.get(getSsoLookupUrlForSpaceWithRedirect(spaceSlug), {
    excludeOrgHeader: true,
    onComplete: (res = {}) => {
      if (res.sign_on_policy === 'sso-required-for-all' && res.saml_signon_url) {
        Utils.redirect(res.saml_signon_url);
      } else {
        onFailure(res);
      }
    }
  });
};
const _rememberReferralActionInUrl = () => {
  if (Url.parseLocationSearch()['referral-program']) {
    PageLoadAction.save('openReferralDialog');
  }
};
const _currentOrgIsDemoWithNoActiveOrgs = org => {
  const activeOrgs = OrganizationModel.getAllActive();
  return org?.is_public_demo === true && activeOrgs.length === 1 && activeOrgs.at(0).is_public_demo === true;
};
exports.handleLoginSuccess = () => {
  ConsolidatedFetch.fetchUserOrgAndApplicationState(() => {
    const org = OrganizationModel.getCurrent();
    const destination = !org || _currentOrgIsDemoWithNoActiveOrgs(org) ? '/organizations' : exports._getLoginDestination(org.url_slug);
    _rememberReferralActionInUrl();
    setTimeout(() => {
      Utils.redirect(destination);
    }, 100);
  });
};
exports._getLoginDestination = slug => {
  const params = Url.getUrlSearchParams();
  let destination = params.get('dest') || ApplicationState.getLastView();
  const includeSlug = Url.notRouteException(destination);
  destination = exports._ensureDestinationHasRightSlug(slug, destination);
  destination = ensureDestinationPathExcludesOauth(destination);
  params.delete('dest');
  const queryParams = params.toString();
  return `${!includeSlug || _.startsWith(destination, slug, 1) ? '' : `/${slug}`}${destination}${queryParams ? `?${queryParams}` : ''}`;
};
exports._ensureDestinationHasRightSlug = (slug, destination) => {
  const potentialOrgSlug = destination.split('/')[1];
  const otherSlugs = OrganizationModel.getSlugsOtherThanSlug(slug);

  // User might be logging in to org B with the dest for org A, which the user
  // has two different user accounts for. Or, we may have changed the org slug.
  // In either case, let's guard against "/org-slug-a/org-slug-b/dashboard".
  const secondSegment = destination.split('/')[2];
  const isAnotherSlug = potentialOrgSlug !== slug && secondSegment && _.includes(Constants.RESERVED_SLUGS, secondSegment);
  if (_.includes(otherSlugs, potentialOrgSlug) || isAnotherSlug) {
    destination = destination.replace('/' + potentialOrgSlug, '');
  }
  return destination;
};
const ensureDestinationPathExcludesOauth = destination => {
  const destinationIncludesOauth = destination.includes('/oauth/');
  if (destinationIncludesOauth) destination = '/dashboard';
  return destination;
};
exports.drawLoginError = (button, username, message) => {
  Log.log('login error', {
    username,
    message
  });
  _resetButton(button);
  exports.showError(message);
  return false;
};
exports.drawSSOLoginError = (button, slug, message) => {
  Log.log('SSO login error', {
    slug,
    message
  });
  _resetButton(button);
  exports.showError(message, null, '#sso-login-form');
};
function _resetButton(button) {
  button.html('Log in').removeClass('disabled').attr('disabled', false);
}
exports.disableButton = buttonElement => {
  buttonElement.html('<span class="fa fa-spin fa-star"></span> Logging in...').addClass('disabled').attr('disabled', 'disabled');
};
exports.showError = (message, title = 'Unable to log in.', parentSelector) => {
  parentSelector = parentSelector || PARENT_SELECTOR;
  if (!message) {
    message = $('#two-factor-login-tab').hasClass('hidden') ? `Incorrect username or password.<br/> Please try again.` : 'Your two-factor code was wrong. Please try again.';
  }
  FlashController.error($(parentSelector), title, message);
};
exports.showExistingErrors = () => {
  const params = Url.parseLocationSearch() || {};
  if (_.includes(_.keys(EXISTING_ERRORS), params.msg)) {
    const error = EXISTING_ERRORS[params.msg];
    exports.showError(error.message, error.title);
  }
  if (params.error) {
    exports.showError(params.error, 'Login Error');
  }
};
exports.drawTwoFactorForm = () => {
  const container = $(PARENT_SELECTOR);
  container.fadeOut(250, () => {
    $('#standard-login-tab').addClass('offscreen');
    $('#two-factor-login-tab').removeClass('hidden');
    $('.forgot-your-password-link').hide();
    $('.server-messages').html('');
    $('.oauth-options').hide();
    $('#login-button').html('Log in').removeClass('disabled').attr('disabled', false);
    container.fadeIn(250, () => {
      // Reset input in case password manager filled in username.
      $('#two-factor-code').val('').focus();
    });
  });
};
exports.attemptRecovery = (data, options) => {
  Backend.delete('/api/private/two-factor-auth/recover', {
    data,
    onError: options.onError || (() => {
      exports.drawRecoveryError(options.button, data.username);
    }),
    onSuccess: options.onSuccess || (() => {
      exports.disableButton(options.button);
      exports.attemptLogin(data, options);
    })
  });
};
exports.drawRecoveryError = (button, username) => {
  Log.log('recovery error', {
    username
  });
  button.html('Disable 2FA').removeClass('disabled').attr('disabled', false);
  exports.showRecoveryError();
};
exports.disableRecoveryButton = buttonElement => {
  buttonElement.html('<span class="fa fa-spin fa-star"></span> Disabling 2FA...').addClass('disabled').attr('disabled', 'disabled');
};
exports.showRecoveryError = () => {
  const title = 'Unable to disable 2FA.';
  const message = 'Your username, password, or recovery code was wrong. Please try again.';
  FlashController.error($(PARENT_SELECTOR), title, message);
};
export { exports as default };