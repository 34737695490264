import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import * as ServerMessageTemplate from 'app/client/core/views/templates/serverMessage.html?caveman';
import LocalStorage from '../modules/localStorage';
import Utils from '../modules/utils';
import View from '../modules/view';
import _ from 'lodash';
const exports = {};
const SAVED_MESSAGE_KEY = 'savedMessage';
exports.slideDownAndFadeIn = (context, html) => {
  $(context).find('.server-messages').eq(0).hide().html(html).css({
    opacity: 0
  }).slideDown(200, function () {
    $(this).animate({
      opacity: 1
    }, 200);
  });
};
exports.success = (context, title, message) => {
  const html = ServerMessageTemplate.render({
    className: 'success',
    iconLeft: 'fa-check-circle',
    title,
    message
  });
  exports.slideDownAndFadeIn(context, html);
};
exports.error = (context, title, message, shouldBreakOnNewlines = false) => {
  const html = ServerMessageTemplate.render({
    className: `error ${shouldBreakOnNewlines ? 'break-on-newlines' : ''}`.trim(),
    iconLeft: 'fa-minus-circle',
    title,
    message
  });
  exports.slideDownAndFadeIn(context, html);
};
exports.saveSuccessMessage = (selector, title, message) => {
  exports.saveMessage('success', selector, title, message);
};
exports.saveErrorMessage = (selector, title, message) => {
  exports.saveMessage('error', selector, title, message);
};
exports.saveMessage = (type, selector, title, message) => {
  const data = {
    message,
    selector,
    title,
    type
  };
  LocalStorage.set(SAVED_MESSAGE_KEY, data, {
    prefix: true
  });
};
exports.showSavedMessage = () => {
  const msg = Utils.parseJSON(LocalStorage.get(SAVED_MESSAGE_KEY, {
    prefix: true
  }));
  if (!_.isPlainObject(msg)) {
    return;
  }
  if (msg.selector && msg.title) {
    if (msg.type === 'error') {
      exports.error(msg.selector, msg.title, msg.message);
    } else if (msg.type === 'success') {
      exports.success(msg.selector, msg.title, msg.message);
    }
  }
  LocalStorage.remove(SAVED_MESSAGE_KEY, {
    prefix: true
  });
};

// ----- Actions -----

exports.close = function (e) {
  e.preventDefault();
  View.fadeAndSlideUp($(this).closest('.server-message'));
};
export { exports as default };