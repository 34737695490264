import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import ProductModel from 'app/client/core/js/models/product';
import { DISPLAY_CONFIG } from 'app/client/core/js/modules/constants';
import { getAvailableTiers } from 'utils/tiers';
export const getProducts = () => getAvailableTiers().map(({
  id
}) => ProductModel.get({
  display_key: id
}));
export const getProductTitle = product => {
  if (!product) return null;
  return DISPLAY_CONFIG[product.display_key]?.displayName ?? null;
};
export const isSameProduct = (left, right) => left.display_key === right.display_key;
export const getProductById = idToFind => ProductModel.all().find(({
  id
}) => id === idToFind);