import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
// Integration Configuration
import aikidoSecurityL from '@clubhouse/assets/png/third-party-logos/aikidosecurity_128x128.png';
import aikidoSecurityS from '@clubhouse/assets/png/third-party-logos/aikidosecurity_32x32.png';
import AirfocusL from '@clubhouse/assets/png/third-party-logos/airfocus-128x128.png';
import AirfocusS from '@clubhouse/assets/png/third-party-logos/airfocus-32x32.png';
import BitbucketL from '@clubhouse/assets/png/third-party-logos/bitbucket_128x128.png';
import BitbucketS from '@clubhouse/assets/png/third-party-logos/bitbucket_32x32.png';
import BoxL from '@clubhouse/assets/png/third-party-logos/box_128x128.png';
import BoxS from '@clubhouse/assets/png/third-party-logos/box_32x32.png';
import BugseeL from '@clubhouse/assets/png/third-party-logos/bugsee_128x128.png';
import BugseeS from '@clubhouse/assets/png/third-party-logos/bugsee_32x32.png';
import BugsnagL from '@clubhouse/assets/png/third-party-logos/bugsnag_128x128.png';
import BugsnagS from '@clubhouse/assets/png/third-party-logos/bugsnag_32x32.png';
import CalendarL from '@clubhouse/assets/png/third-party-logos/calendar_128x128.png';
import CalendarS from '@clubhouse/assets/png/third-party-logos/calendar_32x32.png';
import ClockifyL from '@clubhouse/assets/png/third-party-logos/clockify_128x128.png';
import ClockifyS from '@clubhouse/assets/png/third-party-logos/clockify_32x32.png';
import CodestreamL from '@clubhouse/assets/png/third-party-logos/codestream_128x128.png';
import CodestreamS from '@clubhouse/assets/png/third-party-logos/codestream_32x32.png';
import dailybotL from '@clubhouse/assets/png/third-party-logos/dailybot_128x128.png';
import dailybotS from '@clubhouse/assets/png/third-party-logos/dailybot_32x32.png';
import dropboxL from '@clubhouse/assets/png/third-party-logos/dropbox_128x128.png';
import dropboxS from '@clubhouse/assets/png/third-party-logos/dropbox_32x32.png';
import everhourL from '@clubhouse/assets/png/third-party-logos/everhour_128x128.png';
import everhourS from '@clubhouse/assets/png/third-party-logos/everhour_32x32.png';
import feedbucketL from '@clubhouse/assets/png/third-party-logos/feedbucket_128x128.png';
import feedbucketS from '@clubhouse/assets/png/third-party-logos/feedbucket_32x32.png';
import figmaL from '@clubhouse/assets/png/third-party-logos/figma_128x128.png';
import figmaS from '@clubhouse/assets/png/third-party-logos/figma_32x32.png';
import firehydrantL from '@clubhouse/assets/png/third-party-logos/firehydrant_128x128.png';
import firehydrantS from '@clubhouse/assets/png/third-party-logos/firehydrant_32x32.png';
import gcalL from '@clubhouse/assets/png/third-party-logos/gcal_128x128.png';
import gcalS from '@clubhouse/assets/png/third-party-logos/gcal_32x32.png';
import githubL from '@clubhouse/assets/png/third-party-logos/github_128x128.png';
import githubS from '@clubhouse/assets/png/third-party-logos/github_32x32.png';
import gitlabL from '@clubhouse/assets/png/third-party-logos/gitlab_128x128.png';
import gitlabS from '@clubhouse/assets/png/third-party-logos/gitlab_32x32.png';
import googleDriveL from '@clubhouse/assets/png/third-party-logos/google_drive_128x128.png';
import googleDriveS from '@clubhouse/assets/png/third-party-logos/google_drive_32x32.png';
import googleSheetsL from '@clubhouse/assets/png/third-party-logos/google_sheets_128x128.png';
import googleSheetsS from '@clubhouse/assets/png/third-party-logos/google_sheets_32x32.png';
import guruL from '@clubhouse/assets/png/third-party-logos/guru_128x128.png';
import guruS from '@clubhouse/assets/png/third-party-logos/guru_32x32.png';
import honeyBadgerL from '@clubhouse/assets/png/third-party-logos/honeyBadger_128x128.png';
import honeyBadgerS from '@clubhouse/assets/png/third-party-logos/honeyBadger_32x32.png';
import instabugL from '@clubhouse/assets/png/third-party-logos/instabug_128x128.png';
import instabugS from '@clubhouse/assets/png/third-party-logos/instabug_32x32.png';
import jiraSyncL from '@clubhouse/assets/png/third-party-logos/jirasync_128x128.png';
import jiraSyncS from '@clubhouse/assets/png/third-party-logos/jirasync_32x32.png';
import lambdatestL from '@clubhouse/assets/png/third-party-logos/lambdatest_128x128.png';
import lambdatestS from '@clubhouse/assets/png/third-party-logos/lambdatest_32x32.png';
import linearbL from '@clubhouse/assets/png/third-party-logos/linearb_128x128.png';
import linearbS from '@clubhouse/assets/png/third-party-logos/linearb_32x32.png';
import loomL from '@clubhouse/assets/png/third-party-logos/loom-128x128.png';
import loomS from '@clubhouse/assets/png/third-party-logos/loom-32x32.png';
import makeL from '@clubhouse/assets/png/third-party-logos/make_128x128.png';
import makeS from '@clubhouse/assets/png/third-party-logos/make_32x32.png';
import markerL from '@clubhouse/assets/png/third-party-logos/marker_128x128.png';
import markerS from '@clubhouse/assets/png/third-party-logos/marker_32x32.png';
import miroL from '@clubhouse/assets/png/third-party-logos/miro_128x128.png';
import miroS from '@clubhouse/assets/png/third-party-logos/miro_32x32.png';
import notion from '@clubhouse/assets/png/third-party-logos/notion_104x104.png';
import plainL from '@clubhouse/assets/png/third-party-logos/plain_128x128.png';
import plainS from '@clubhouse/assets/png/third-party-logos/plain_32x32.png';
import plectoL from '@clubhouse/assets/png/third-party-logos/plecto_128x128.png';
import plectoS from '@clubhouse/assets/png/third-party-logos/plecto_32x32.png';
import pipedreamL from '@clubhouse/assets/png/third-party-logos/pipedream_128x128.png';
import pipedreamS from '@clubhouse/assets/png/third-party-logos/pipedream_32x32.png';
import productboardL from '@clubhouse/assets/png/third-party-logos/productboard_128x128.png';
import productboardS from '@clubhouse/assets/png/third-party-logos/productboard_32x32.png';
import roadmunkL from '@clubhouse/assets/png/third-party-logos/roadmunk_128x128.png';
import roadmunkS from '@clubhouse/assets/png/third-party-logos/roadmunk_32x32.png';
import rollbarL from '@clubhouse/assets/png/third-party-logos/rollbar_128x128.png';
import rollbarS from '@clubhouse/assets/png/third-party-logos/rollbar_32x32.png';
import rootlyL from '@clubhouse/assets/png/third-party-logos/rootly_128x128.png';
import rootlyS from '@clubhouse/assets/png/third-party-logos/rootly_32x32.png';
import savioL from '@clubhouse/assets/png/third-party-logos/savio_128x128.png';
import savioS from '@clubhouse/assets/png/third-party-logos/savio_32x32.png';
import sentryL from '@clubhouse/assets/png/third-party-logos/sentry_128x128.png';
import sentryS from '@clubhouse/assets/png/third-party-logos/sentry_32x32.png';
import slabL from '@clubhouse/assets/png/third-party-logos/slab_128x128.png';
import slabS from '@clubhouse/assets/png/third-party-logos/slab_32x32.png';
import slackL from '@clubhouse/assets/png/third-party-logos/slack_128x128.png';
import slackS from '@clubhouse/assets/png/third-party-logos/slack_32x32.png';
import spikeShL from '@clubhouse/assets/png/third-party-logos/spikesh_128x128.png';
import spikeShS from '@clubhouse/assets/png/third-party-logos/spikesh_32x32.png';
import statusheroL from '@clubhouse/assets/png/third-party-logos/statushero_128x128.png';
import statusheroS from '@clubhouse/assets/png/third-party-logos/statushero_32x32.png';
import suretriggersL from '@clubhouse/assets/png/third-party-logos/suretriggers_128x128.png';
import suretriggersS from '@clubhouse/assets/png/third-party-logos/suretriggers_32x32.png';
import testlodgeL from '@clubhouse/assets/png/third-party-logos/testlodge_128x128.png';
import testlodgeS from '@clubhouse/assets/png/third-party-logos/testlodge_32x32.png';
import vantaL from '@clubhouse/assets/png/third-party-logos/vanta_128x128.png';
import vantaS from '@clubhouse/assets/png/third-party-logos/vanta_32x32.png';
import webhookL from '@clubhouse/assets/png/third-party-logos/webhook_128x128.png';
import webhookS from '@clubhouse/assets/png/third-party-logos/webhook_32x32.png';
import youtubeL from '@clubhouse/assets/png/third-party-logos/youtube-128x128.png';
import youtubeS from '@clubhouse/assets/png/third-party-logos/youtube-32x32.png';
import zapierL from '@clubhouse/assets/png/third-party-logos/zapier_128x128.png';
import zapierS from '@clubhouse/assets/png/third-party-logos/zapier_32x32.png';
import zendeskL from '@clubhouse/assets/png/third-party-logos/zendesk_128x128.png';
import zendeskS from '@clubhouse/assets/png/third-party-logos/zendesk_32x32.png';
import zohoCliqL from '@clubhouse/assets/png/third-party-logos/zohocliq_128x128.png';
import zohoCliqS from '@clubhouse/assets/png/third-party-logos/zohocliq_32x32.png';
import { INTEGRATION_TYPE } from '@clubhouse/shared/types';
import InstallationModel from 'app/client/core/js/models/installation';
import IntegrationModel from 'app/client/core/js/models/integration';
import OrganizationModel from 'app/client/core/js/models/organization';
import PaymentPlan2Model from 'app/client/core/js/models/paymentPlan2';
import WebhookModel from 'app/client/core/js/models/webhook';
import AikidoSecurityIntegrationController from 'app/client/integrations/js/controllers/aikidoSecurityIntegration';
import AirfocusIntegrationController from 'app/client/integrations/js/controllers/airfocusIntegration';
import BitbucketIntegrationController from 'app/client/integrations/js/controllers/bitbucketIntegration';
import BoxIntegrationController from 'app/client/integrations/js/controllers/boxIntegration';
import BugseeIntegrationController from 'app/client/integrations/js/controllers/bugseeIntegration';
import BugsnagIntegrationController from 'app/client/integrations/js/controllers/bugsnagIntegration';
import CalendarIntegrationController from 'app/client/integrations/js/controllers/calendarIntegration';
import ClockifyIntegrationController from 'app/client/integrations/js/controllers/clockifyIntegration';
import CodeStreamIntegrationController from 'app/client/integrations/js/controllers/codeStreamIntegration';
import DailyBotIntegrationController from 'app/client/integrations/js/controllers/dailybotIntegration';
import DropboxIntegrationController from 'app/client/integrations/js/controllers/dropboxIntegration';
import EverhourIntegrationController from 'app/client/integrations/js/controllers/everhourIntegration';
import FeedbucketIntegrationController from 'app/client/integrations/js/controllers/feedbucketIntegration';
import FigmaIntegrationController from 'app/client/integrations/js/controllers/figmaIntegration';
import FireHydrantIntegrationController from 'app/client/integrations/js/controllers/fireHydrantIntegration';
import GithubIntegrationController from 'app/client/integrations/js/controllers/githubIntegration';
import GitlabIntegrationController from 'app/client/integrations/js/controllers/gitlabIntegration';
import GoogleCalendarIntegrationController from 'app/client/integrations/js/controllers/googleCalendarIntegration';
import GoogleDriveIntegrationController from 'app/client/integrations/js/controllers/googleDriveIntegration';
import GsheetsIntegrationController from 'app/client/integrations/js/controllers/gsheetsIntegration';
import GuruIntegrationController from 'app/client/integrations/js/controllers/guruIntegration';
import HoneyBadgerIntegrationController from 'app/client/integrations/js/controllers/honeyBadgerIntegration';
import InstabugIntegrationController from 'app/client/integrations/js/controllers/instabugIntegration';
import JiraSyncIntegrationController from 'app/client/integrations/js/controllers/jiraSyncIntegration';
import LambdaTestIntegrationController from 'app/client/integrations/js/controllers/lambdaTestIntegration';
import LinearbIntegrationController from 'app/client/integrations/js/controllers/linearbIntegration';
import LoomIntegrationController from 'app/client/integrations/js/controllers/loomIntegration';
import MakeIntegrationController from 'app/client/integrations/js/controllers/makeIntegration';
import MarkerIOIntegrationController from 'app/client/integrations/js/controllers/markerIOIntegration';
import MiroIntegrationController from 'app/client/integrations/js/controllers/miroIntegration';
import NotionIntegration from 'app/client/integrations/js/controllers/notionIntegration';
import PipedreamIntegrationController from 'app/client/integrations/js/controllers/pipedreamIntegration';
import PlainIntegrationController from 'app/client/integrations/js/controllers/plainIntegration';
import PlectoIntegrationController from 'app/client/integrations/js/controllers/plectoIntegration';
import ProductboardIntegrationController from 'app/client/integrations/js/controllers/productboardIntegration';
import RoadmunkIntegrationController from 'app/client/integrations/js/controllers/roadmunkIntegration';
import RollbarIntegrationController from 'app/client/integrations/js/controllers/rollbarIntegration';
import RootlyIntegrationController from 'app/client/integrations/js/controllers/rootlyIntegration';
import SavioIntegrationController from 'app/client/integrations/js/controllers/savioIntegration';
import SentryIntegrationController from 'app/client/integrations/js/controllers/sentryIntegration';
import SlabIntegrationController from 'app/client/integrations/js/controllers/slabIntegration';
import SlackIntegrationController from 'app/client/integrations/js/controllers/slackIntegration';
import SpikeShIntegrationController from 'app/client/integrations/js/controllers/spikeShIntegration';
import StatusHeroIntegrationController from 'app/client/integrations/js/controllers/statusHeroIntegration';
import SuretriggersIntegrationController from 'app/client/integrations/js/controllers/sureTriggersIntegration';
import TestlodgeIntegrationController from 'app/client/integrations/js/controllers/testlodgeIntegration';
import VantaIntegrationController from 'app/client/integrations/js/controllers/vantaIntegration';
import WebhookIntegrationController from 'app/client/integrations/js/controllers/webhookIntegration';
import YouTubeIntegrationController from 'app/client/integrations/js/controllers/youtubeIntegration';
import ZapierIntegrationController from 'app/client/integrations/js/controllers/zapierIntegration';
import ZendeskIntegrationController from 'app/client/integrations/js/controllers/zendeskIntegration';
import ZohoCliqIntegrationController from 'app/client/integrations/js/controllers/zohoCliqIntegration';
import { currentUserHasNotionToken } from 'data/entity/user';
const getIntegrationList = () => [{
  description: 'Create Shortcut Stories for security findings.',
  name: 'Aikido Security',
  type: INTEGRATION_TYPE.AIKIDO_SECURITY,
  icons: {
    small: aikidoSecurityS,
    large: aikidoSecurityL
  },
  openHandler: () => AikidoSecurityIntegrationController.open()
}, {
  description: 'Align development with product strategy and roadmaps.',
  name: 'Airfocus',
  type: INTEGRATION_TYPE.AIRFOCUS,
  icons: {
    small: AirfocusS,
    large: AirfocusL
  },
  openHandler: () => AirfocusIntegrationController.open(),
  shouldShowStatus: () => true
}, {
  description: 'Link branches and commits to stories, and more.',
  name: 'Bitbucket',
  type: INTEGRATION_TYPE.BITBUCKET,
  icons: {
    small: BitbucketS,
    large: BitbucketL
  },
  openHandler: () => BitbucketIntegrationController.open(),
  shouldShowStatus: () => true,
  isEnabled: () => {
    const integration = WebhookModel.getBitbucket();
    return integration && !integration.disabled && Boolean(InstallationModel.getBitbucket());
  }
}, {
  description: `Share your files to collaborate with other ${BRAND.NAME} users.`,
  name: 'Box',
  type: INTEGRATION_TYPE.BOX,
  icons: {
    small: BoxS,
    large: BoxL
  },
  openHandler: () => BoxIntegrationController.open(),
  invertForDarkMode: true,
  shouldShowStatus: () => true,
  isEnabled: () => OrganizationModel.isIntegrationEnabled('box')
}, {
  description: `Create ${BRAND.NAME} stories from within Bugsee.`,
  name: 'Bugsee',
  type: INTEGRATION_TYPE.BUGSEE,
  icons: {
    small: BugseeS,
    large: BugseeL
  },
  openHandler: () => BugseeIntegrationController.open()
}, {
  description: `Create ${BRAND.NAME} stories from within Bugsnag.`,
  name: 'Bugsnag',
  type: INTEGRATION_TYPE.BUGSNAG,
  icons: {
    small: BugsnagS,
    large: BugsnagL
  },
  openHandler: () => BugsnagIntegrationController.open()
}, {
  name: 'Calendar',
  type: INTEGRATION_TYPE.CALENDAR,
  description: `Add due dates from ${BRAND.NAME} to your Calendar.`,
  icons: {
    small: CalendarS,
    large: CalendarL
  },
  openHandler: () => CalendarIntegrationController.open(),
  shouldShowStatus: () => true,
  isEnabled: () => {
    const integration = WebhookModel.getCalendar();
    return integration && !integration.disabled;
  }
}, {
  description: `Track time on Shortcut stories.`,
  name: 'Clockify',
  type: INTEGRATION_TYPE.CLOCKIFY,
  icons: {
    small: ClockifyS,
    large: ClockifyL
  },
  openHandler: () => ClockifyIntegrationController.open()
}, {
  description: `Attach requests to Shortcut stories and automate your workflow.`,
  name: `CodeStream`,
  type: INTEGRATION_TYPE.CODESTREAM,
  icons: {
    small: CodestreamS,
    large: CodestreamL
  },
  openHandler: () => CodeStreamIntegrationController.open()
}, {
  description: `Manage your ${BRAND.NAME} workflows.`,
  name: 'DailyBot',
  type: INTEGRATION_TYPE.DAILYBOT,
  icons: {
    small: dailybotS,
    large: dailybotL
  },
  openHandler: () => DailyBotIntegrationController.open()
}, {
  description: `Share your files to collaborate with other ${BRAND.NAME} users.`,
  name: 'Dropbox',
  type: INTEGRATION_TYPE.DROPBOX,
  icons: {
    small: dropboxS,
    large: dropboxL
  },
  openHandler: () => DropboxIntegrationController.open(),
  shouldShowStatus: () => true,
  isEnabled: () => OrganizationModel.isIntegrationEnabled('dropbox')
}, {
  description: `Track time on tasks with just one click.`,
  name: 'Everhour',
  type: INTEGRATION_TYPE.EVERHOUR,
  icons: {
    small: everhourS,
    large: everhourL
  },
  openHandler: () => EverhourIntegrationController.open(),
  invertForDarkMode: true
}, {
  description: 'Turn website feedback into Shortcut Stories.',
  name: 'Feedbucket',
  type: INTEGRATION_TYPE.FEEDBUCKET,
  icons: {
    small: feedbucketS,
    large: feedbucketL
  },
  openHandler: () => FeedbucketIntegrationController.open()
}, {
  description: `Embed Figma links within any markdown text field in Shortcut.`,
  name: 'Figma',
  type: INTEGRATION_TYPE.FIGMA,
  icons: {
    small: figmaS,
    large: figmaL
  },
  openHandler: () => FigmaIntegrationController.open()
}, {
  description: `Sync action items from incidents to Shortcut stories. `,
  name: `FireHydrant`,
  type: INTEGRATION_TYPE.FIREHYDRANT,
  icons: {
    small: firehydrantS,
    large: firehydrantL
  },
  openHandler: () => FireHydrantIntegrationController.open()
}, {
  name: 'GitHub',
  type: INTEGRATION_TYPE.GITHUB,
  icons: {
    small: githubS,
    large: githubL
  },
  openHandler: () => GithubIntegrationController.open(),
  description: 'Link branches and commits to stories, and more.',
  shouldShowStatus: () => true,
  isEnabled: () => InstallationModel.isGitHubEnabled(),
  invertForDarkMode: true
}, {
  name: 'GitLab',
  type: INTEGRATION_TYPE.GITLAB,
  icons: {
    small: gitlabS,
    large: gitlabL
  },
  openHandler: () => GitlabIntegrationController.open(),
  description: 'Link branches and commits to stories, and more.',
  shouldShowStatus: () => true,
  isEnabled: () => {
    const integration = InstallationModel.getGitlab();
    return integration && !integration.disabled;
  }
}, {
  description: `Visualize the due dates of your Shortcut Stories in your Google Calendar.`,
  name: 'Google Calendar',
  type: INTEGRATION_TYPE.GOOGLE_CALENDAR,
  invertForDarkMode: false,
  icons: {
    small: gcalS,
    large: gcalL
  },
  openHandler: () => GoogleCalendarIntegrationController.open()
}, {
  description: `Share your files to collaborate with other ${BRAND.NAME} users.`,
  name: 'Google Drive',
  type: INTEGRATION_TYPE.GOOGLE_DRIVE,
  icons: {
    small: googleDriveS,
    large: googleDriveL
  },
  openHandler: () => GoogleDriveIntegrationController.open(),
  shouldShowStatus: () => true,
  isEnabled: () => OrganizationModel.isIntegrationEnabled('google-drive')
}, {
  description: 'Connect your Story data to a Google Sheet for custom analysis.',
  name: 'Google Sheets',
  type: INTEGRATION_TYPE.GOOGLE_SHEETS,
  icons: {
    small: googleSheetsS,
    large: googleSheetsL
  },
  openHandler: () => GsheetsIntegrationController.open(),
  shouldShowStatus: () => true,
  isEnabled: () => {
    const integration = IntegrationModel.getGsheetsIntegration();
    return integration && !integration.disabled;
  }
}, {
  description: 'Get instant, AI-powered answers.',
  name: 'Guru',
  type: INTEGRATION_TYPE.GURU,
  icons: {
    small: guruS,
    large: guruL
  },
  openHandler: () => GuruIntegrationController.open()
}, {
  description: `Create ${BRAND.NAME} stories from within Honeybadger.`,
  name: 'HoneyBadger',
  type: INTEGRATION_TYPE.HONEY_BADGER,
  icons: {
    small: honeyBadgerS,
    large: honeyBadgerL
  },
  openHandler: () => HoneyBadgerIntegrationController.open()
}, {
  description: `Create ${BRAND.NAME} stories from within Instabug.`,
  name: 'Instabug',
  type: INTEGRATION_TYPE.INSTABUG,
  icons: {
    small: instabugS,
    large: instabugL
  },
  openHandler: () => InstabugIntegrationController.open()
}, {
  description: 'Keep Shortcut Stories and Jira Issues in sync.',
  name: 'Jira Sync',
  type: INTEGRATION_TYPE.JIRA_SYNC,
  icons: {
    small: jiraSyncS,
    large: jiraSyncL
  },
  openHandler: () => JiraSyncIntegrationController.open()
}, {
  description: `File browser testing issues to Shortcut directly.`,
  name: `LambdaTest`,
  type: INTEGRATION_TYPE.LAMBDATEST,
  icons: {
    small: lambdatestS,
    large: lambdatestL
  },
  invertForDarkMode: true,
  openHandler: () => LambdaTestIntegrationController.open()
}, {
  description: `Measure performance with team-based metrics related to Shortcut stories.`,
  name: 'LinearB',
  type: INTEGRATION_TYPE.LINEAR_B,
  icons: {
    small: linearbS,
    large: linearbL
  },
  invertForDarkMode: true,
  openHandler: () => LinearbIntegrationController.open()
}, {
  description: `Bring Loom into Shortcut with this link preview integration.`,
  name: 'Loom',
  type: INTEGRATION_TYPE.LOOM,
  icons: {
    small: loomS,
    large: loomL
  },
  invertForDarkMode: true,
  openHandler: () => LoomIntegrationController.open()
}, {
  description: 'Connect Shortcut to other apps to automate work.',
  name: 'Make',
  type: INTEGRATION_TYPE.MAKE,
  icons: {
    small: makeS,
    large: makeL
  },
  openHandler: () => MakeIntegrationController.open()
}, {
  description: `Report and fix bugs without leaving your workflow.`,
  name: `Marker.io`,
  type: INTEGRATION_TYPE.MARKER,
  icons: {
    small: markerS,
    large: markerL
  },
  openHandler: () => MarkerIOIntegrationController.open(),
  invertForDarkMode: true
}, {
  description: `Turn shared board links into embedded live previews.`,
  name: `Miro`,
  type: INTEGRATION_TYPE.MIRO,
  icons: {
    small: miroS,
    large: miroL
  },
  openHandler: () => MiroIntegrationController.open()
}, {
  description: 'Add live Shortcut link previews into your Notion docs',
  name: 'Notion',
  type: INTEGRATION_TYPE.NOTION,
  icons: {
    small: notion,
    large: notion
  },
  openHandler: () => NotionIntegration.open(),
  shouldShowStatus: () => true,
  isEnabled: currentUserHasNotionToken
}, {
  description: 'Create and link Stories and close the loop with your customers.',
  name: 'Plain',
  type: INTEGRATION_TYPE.PLAIN,
  icons: {
    small: plainS,
    large: plainL
  },
  openHandler: () => PlainIntegrationController.open()
}, {
  description: `Create customized Shortcut dashboards with real-time metrics.`,
  name: 'Plecto',
  type: INTEGRATION_TYPE.PLECTO,
  icons: {
    small: plectoS,
    large: plectoL
  },
  openHandler: () => PlectoIntegrationController.open()
}, {
  description: 'Build and run workflows using the Shortcut API.',
  name: 'Pipedream',
  type: INTEGRATION_TYPE.PIPEDREAM,
  icons: {
    small: pipedreamS,
    large: pipedreamL
  },
  openHandler: () => PipedreamIntegrationController.open()
}, {
  name: 'Productboard',
  description: `Create ${BRAND.NAME} Epics from Productboard features.`,
  type: INTEGRATION_TYPE.PRODUCTBOARD,
  icons: {
    small: productboardS,
    large: productboardL
  },
  openHandler: () => ProductboardIntegrationController.open(),
  shouldShowStatus: () => true,
  isEnabled: () => InstallationModel.isProductboardEnabled()
}, {
  description: `Connect to Roadmunk and visualize Shortcut entities in a roadmap.`,
  name: 'Roadmunk',
  type: INTEGRATION_TYPE.ROADMUNK,
  icons: {
    small: roadmunkS,
    large: roadmunkL
  },
  openHandler: () => RoadmunkIntegrationController.open()
}, {
  name: 'Rollbar',
  type: INTEGRATION_TYPE.ROLLBAR,
  icons: {
    small: rollbarS,
    large: rollbarL
  },
  openHandler: () => RollbarIntegrationController.open(),
  description: `Create ${BRAND.NAME} stories from within Rollbar.`
}, {
  description: `Create Shortcut stories from your Rootly Slack incident channel.`,
  name: 'Rootly',
  type: INTEGRATION_TYPE.ROOTLY,
  icons: {
    small: rootlyS,
    large: rootlyL
  },
  openHandler: () => RootlyIntegrationController.open()
}, {
  description: 'Centralize and prioritize product feedback.',
  name: 'Savio',
  type: INTEGRATION_TYPE.SAVIO,
  icons: {
    small: savioS,
    large: savioL
  },
  openHandler: () => SavioIntegrationController.open()
}, {
  name: 'Sentry',
  type: INTEGRATION_TYPE.SENTRY,
  icons: {
    small: sentryS,
    large: sentryL
  },
  openHandler: () => SentryIntegrationController.open(),
  description: `Create ${BRAND.NAME} stories from within Sentry.`,
  shouldShowStatus: () => true,
  isEnabled: () => {
    const integration = InstallationModel.getSentry();
    return integration && !integration.disabled;
  }
}, {
  name: 'Slab',
  type: INTEGRATION_TYPE.SLAB,
  icons: {
    small: slabS,
    large: slabL
  },
  openHandler: () => SlabIntegrationController.open(),
  description: `Manage your ${BRAND.NAME} stories from within your knowledge base.`
}, {
  name: 'Slack',
  type: INTEGRATION_TYPE.SLACK,
  icons: {
    small: slackS,
    large: slackL
  },
  openHandler: () => SlackIntegrationController.open(),
  description: `Broadcast ${BRAND.NAME} Story comments to Slack channels.`,
  shouldShowStatus: () => {
    const integration = IntegrationModel.getSlackIntegration();
    return integration && !integration.disabled;
  },
  isEnabled: () => {
    const integration = IntegrationModel.getSlackIntegration();
    return integration && !integration.disabled;
  }
}, {
  description: 'Create Shortcut stories with incident details.',
  name: 'Spike.sh',
  type: INTEGRATION_TYPE.SPIKE_SH,
  icons: {
    small: spikeShS,
    large: spikeShL
  },
  openHandler: () => SpikeShIntegrationController.open()
}, {
  name: 'Status Hero',
  type: INTEGRATION_TYPE.STATUS_HERO,
  icons: {
    small: statusheroS,
    large: statusheroL
  },
  openHandler: () => StatusHeroIntegrationController.open(),
  description: 'View story updates in your activity stream and check-ins.'
}, {
  description: 'Connect your apps to automate repetitive processes.',
  name: 'Suretriggers',
  type: INTEGRATION_TYPE.SURETRIGGERS,
  icons: {
    small: suretriggersS,
    large: suretriggersL
  },
  openHandler: () => SuretriggersIntegrationController.open()
}, {
  description: `Create Shortcut stories automatically from test statuses.`,
  name: `Testlodge`,
  type: INTEGRATION_TYPE.TESTLODGE,
  icons: {
    small: testlodgeS,
    large: testlodgeL
  },
  openHandler: () => TestlodgeIntegrationController.open()
}, {
  description: 'Create Shortcut Stories with 1-click from Vanta.',
  name: 'Vanta',
  type: INTEGRATION_TYPE.VANTA,
  icons: {
    small: vantaS,
    large: vantaL
  },
  openHandler: () => VantaIntegrationController.open()
}, {
  description: 'Generic outgoing webhooks to integrate with other services.',
  name: 'Webhooks',
  type: INTEGRATION_TYPE.WEBHOOK,
  icons: {
    small: webhookS,
    large: webhookL
  },
  openHandler: () => WebhookIntegrationController.open(),
  shouldShowStatus: () => true,
  isEnabled: () => IntegrationModel.genericIntegrationsEnabled()
}, {
  description: `Bring YouTube into Shortcut with this link preview integration.`,
  name: 'YouTube',
  type: INTEGRATION_TYPE.YOUTUBE,
  icons: {
    small: youtubeS,
    large: youtubeL
  },
  invertForDarkMode: true,
  openHandler: () => YouTubeIntegrationController.open()
}, {
  description: 'Automate story creation using 400+ other services.',
  name: 'Zapier',
  type: INTEGRATION_TYPE.ZAPIER,
  icons: {
    small: zapierS,
    large: zapierL
  },
  openHandler: () => ZapierIntegrationController.open(),
  isEnabled: () => InstallationModel.isInstalled(InstallationModel.getZendesk())
}, {
  description: 'Create two way links between your stories and support tickets.',
  name: 'Zendesk',
  type: INTEGRATION_TYPE.ZENDESK,
  icons: {
    small: zendeskS,
    large: zendeskL
  },
  openHandler: () => ZendeskIntegrationController.open(),
  invertForDarkMode: true,
  shouldShowStatus: () => true,
  isEnabled: () => InstallationModel.isInstalled(InstallationModel.getZendesk())
}, {
  description: 'Create new Stories from Cliq communications.',
  name: 'Zoho Cliq',
  type: INTEGRATION_TYPE.ZOHO_CLIQ,
  icons: {
    small: zohoCliqS,
    large: zohoCliqL
  },
  openHandler: () => ZohoCliqIntegrationController.open()
}];
export const getEntitlementKey = type => type === INTEGRATION_TYPE.GOOGLE_CALENDAR ? `integration-calendar` : `integration-${type}`;
export const getIntegrationsForPlan = () => {
  const integrationList = getIntegrationList();
  const plan2 = PaymentPlan2Model.getPlanForCurrentOrg();
  return integrationList.filter(({
    type
  }) => PaymentPlan2Model.hasEntitlement(plan2, getEntitlementKey(type)));
};
export const getIntegration = type => {
  const integration = getIntegrationList().find(config => config.type === type);
  if (!integration) throw new Error('Invalid integration type:' + type);
  return integration;
};
export const isIntegrationEnabled = type => {
  const integration = getIntegration(type);
  return integration.isEnabled?.() ?? false;
};
export const isAnyVCSEnabled = () => isIntegrationEnabled(INTEGRATION_TYPE.BITBUCKET) && isIntegrationEnabled(INTEGRATION_TYPE.GITHUB) && isIntegrationEnabled(INTEGRATION_TYPE.GITLAB);