import { resetPageCSS } from 'app/client/core/js/modules/resetPageCSS';
import css from 'app/client/organizations/less/app.less?url';
import Boot from '../../../core/js/modules/boot';
import * as Event from '../../../core/js/_frontloader/event';
import InviteModel from '../../../core/js/models/invite';
import Layout from '../../../core/js/modules/layout';
import OrgDashboardController from '../controllers/orgDashboard';
import View from '../../../core/js/modules/view';
const exports = {};
const NS = '.OrganizationsInit';
const _init = () => {
  Layout.renderContent('<div id="organizations" class="page-content"></div>');
  OrgDashboardController.init();
  InviteModel.fetchAllPending(() => {
    OrgDashboardController.render();
    Event.onlyOn('resize' + NS, View.genericResizeHandler);
    Event.trigger('resize');
  });
};
const _reInit = _init;
exports.boot = async () => {
  await resetPageCSS(css);
  Boot.initializePage(_init, _reInit);
};
export { exports as default };