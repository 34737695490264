import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import { useEffect, useMemo, useState } from 'react';
import { useAsync } from 'react-use';
import { BILLING_CYCLE_TYPES, BILLING_TYPES as BillingTypes } from '@clubhouse/shared/types';
import PaymentPlanModel from 'app/client/core/js/models/paymentPlan2';
import { useEntities, useEntity } from './collection';
import { useCompanyByOrg } from './company';
export const usePaymentPlan = id => {
  const {
    entity: paymentPlan
  } = useEntity({
    model: PaymentPlanModel,
    id
  });
  return {
    paymentPlan
  };
};
export const usePaymentPlans = () => {
  const {
    entities
  } = useEntities({
    model: PaymentPlanModel
  });
  return entities;
};
export const usePaymentPlanForOrganization = orgId => {
  const company = useCompanyByOrg(orgId);
  const paymentPlans = usePaymentPlans();
  return paymentPlans.find(plan => company && plan.companyId === company.id);
};
export const getCurrentPlan = () => {
  return PaymentPlanModel.getPlanForCurrentOrg();
};
export const useCurrentPlan = () => {
  const paymentPlan = getCurrentPlan();
  return usePaymentPlan(paymentPlan && paymentPlan.id);
};
export const getPlanPeriodAdverb = plan => PaymentPlanModel.getPlanPeriodAdverb(plan);
export const getPlanPeriod = plan => PaymentPlanModel.getPlanPeriod(plan);
export const getPlanDisplayName = plan => PaymentPlanModel.getPlanDisplayName(plan);
export const getCurrentProduct = plan => PaymentPlanModel.getCurrentProduct(plan);
export const getNumSeats = plan => PaymentPlanModel.getNumSeats(plan);
export const getNumActiveUsers = plan => PaymentPlanModel.getNumActiveUsers(plan);
export const getAvailableSeats = plan => PaymentPlanModel.availableSeats(plan);
export const getPlanName = plan => PaymentPlanModel.getName(plan);
export const getPlanPeriodWithHasSelectedTier = plan => PaymentPlanModel.planTrialInfo(plan)?.has_selected_tier === false ? BILLING_CYCLE_TYPES.ANNUAL : getPlanPeriod(plan);
export const hasPendingTierDowngrade = plan => PaymentPlanModel.hasPendingTierDowngrade(plan);
export const pendingTierDowngradeProduct = plan => hasPendingTierDowngrade(plan) && PaymentPlanModel.getPendingProduct(plan);
export const freePlanIneligibleForTrial = plan => PaymentPlanModel.freePlanIneligibleForTrial(plan);
export const updateTier = ({
  tier,
  period,
  couponCode
}) => PaymentPlanModel.Promises.updateTier({
  tier,
  period,
  couponCode
});
export const cancelDowngrade = plan => PaymentPlanModel.Promises.cancelDowngrade(plan);
export const updatePeriod = period => PaymentPlanModel.Promises.updatePeriod(period);
export const planIsTier = (plan, tier) => {
  const product = getCurrentProduct(plan);
  return product && product.display_key === tier;
};
export const usePlanStatuses = plan => {
  return useMemo(() => {
    if (!plan) return {};
    return {
      planTrialInfo: planTrialInfo(plan),
      trialingWillDowngradeToFreeInfo: PaymentPlanModel.trialingWillDowngradeToFreeInfo(plan),
      violationOfPendingTierDowngradeInfo: PaymentPlanModel.violationOfPendingTierDowngradeInfo(plan),
      pendingTierDowngradeProduct: pendingTierDowngradeProduct(plan),
      freePlanIneligibleForTrial: freePlanIneligibleForTrial(plan)
    };
  }, [plan]);
};
export const usePlanStatus = (status, plan) => {
  return useMemo(() => plan.statuses[status], [plan, status]);
};
export const getPlanStatus = (stat, plan) => {
  return plan.statuses[stat] ?? null;
};

// Note: This will return undefined if the current user is not an owner
export const useSubscriptionDetails = plan => {
  const [subscriptionDetails, setDetails] = useState();
  useEffect(() => {
    PaymentPlanModel.fetchSubscriptionDetails(plan).then(setDetails);
  }, [plan]);
  return subscriptionDetails;
};
export const usePrices = plan => {
  const {
    value,
    loading
  } = useAsync(async () => await PaymentPlanModel.fetchPrices(plan), [plan]);

  // Return without waiting for the promise to prevent price "flashes"
  if (plan && plan.prices) {
    return {
      prices: plan.prices,
      loading: false
    };
  }
  return {
    prices: value,
    loading
  };
};
export const planIsTrialing = plan => PaymentPlanModel.isTrialing(plan);
export const getPlanBillingType = plan => PaymentPlanModel.getPlanBillingType(plan);
export const isMissingCreditCard = plan => PaymentPlanModel.isMissingCreditCard(plan);
export const getPendingPeriodDowngrade = plan => PaymentPlanModel.getPendingPeriodDowngrade(plan);
export const getPlanPricePerSeat = ({
  prices,
  tier,
  frequency = BILLING_CYCLE_TYPES.ANNUAL,
  monthly,
  basePrice
}) => PaymentPlanModel.getPlanPricePerSeat({
  prices,
  tier,
  frequency,
  monthly,
  basePrice
});
export const getPlanPriceTotal = ({
  prices,
  tier,
  frequency = BILLING_CYCLE_TYPES.ANNUAL,
  monthly,
  basePrice
}) => PaymentPlanModel.getPlanPriceTotal({
  prices,
  tier,
  frequency,
  monthly,
  basePrice
});
export const hasEntitlement = (paymentPlan, entitlement) => PaymentPlanModel.hasEntitlement(paymentPlan, entitlement);
export const useEntitlement = (paymentPlan, entitlement) => {
  return useMemo(() => hasEntitlement(paymentPlan, entitlement), [paymentPlan, entitlement]);
};
export const isBusinessTier = tier => PaymentPlanModel.isBusinessTier(tier);
export const isManaged = plan => PaymentPlanModel.isManaged(plan);
export const isAProfitablePlan = plan => PaymentPlanModel.isAProfitablePlan(plan);
export const planTrialInfo = plan => PaymentPlanModel.planTrialInfo(plan);

// NOTE: Do not use this, it's really just used for logging events
// Old PaymentPlan had a type property that had some old values we don't use anymore
// This is meant to bridge the log events with PaymentPlan2
export const getPlanLevel = paymentPlan => {
  if (!paymentPlan) return null;

  // First catch trialing plans, regardless of the chosen plan
  const trialPlanStatus = getPlanStatus('trialing', paymentPlan);
  if (trialPlanStatus) {
    // If 0 days left, assume trial has expired
    return trialPlanStatus.trial_days_remaining === 0 ? 'expired-trial-plan' : 'trial-plan';
  }

  // Not trialing; check for managed (Enterprise) plans next, of which there should be three
  if (isManaged(paymentPlan)) {
    switch (getPlanBillingType(paymentPlan)) {
      case BillingTypes.COMPLIMENTARY:
        return 'complimentary-plan';
      case BillingTypes.INVOICED:
        return 'invoiced';
      case BillingTypes.NON_PROFIT:
        return 'non-profit-plan';
      default:
        // Just in case we ever add some other type, reporting will get something
        return 'unknown-managed';
    }
  }

  // If not trialing and not an enterpise, all that's left is a "subscribed" plan or a "free" plan
  return isAProfitablePlan(paymentPlan) ? 'subscribed' : 'free-plan';
};