import * as SystemCrashTemplate from 'app/client/core/views/templates/systemCrash.html?caveman';
import * as Event from '../_frontloader/event';
import Log from '../modules/log';
import PanelController from './panel';
import PanelModel from '../models/panel';
import Utils from '../modules/utils';
const exports = {};
const PANEL_ID = 'system-crash-panel';
const EVENT_NS = '.SystemCrashController';
exports.init = () => {
  Event.onlyOn('SystemCrash' + EVENT_NS, data => {
    exports.render(data);
  });
};
exports.render = data => {
  data = data || {};
  Log.log(data.logTitle || 'System crash!');
  PanelController.open({
    id: PANEL_ID,
    center: true,
    disallowClose: true,
    showCloseButton: false,
    css: {
      'z-index': 2001
    },
    html: SystemCrashTemplate.render(data),
    onOpen: panel => {
      panel.overlay.css({
        'z-index': 2000,
        cursor: 'default'
      });
    },
    target: document.body,
    width: 440
  });
  return false;
};
exports.close = () => {
  const panel = PanelModel.getById(PANEL_ID);
  panel.options.disallowClose = false;
  PanelController.closeById(PANEL_ID);
};

// ----- Actions -----

exports.reloadPage = () => {
  Log.log('User reloading page after system crash...');
  Utils.reloadPage();
  return false;
};
export { exports as default };