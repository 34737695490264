import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
/*

Example application entity:

{
  entity_type: "application"
  id: "59e72e58-89d0-47fe-85cc-e3d0c36892a6"
  identifier: "zendesk-app"
}

*/

import BitBucketLogo from '@clubhouse/assets/png/third-party-logos/bitbucket_logo.png';
import GithubLogo from '@clubhouse/assets/png/third-party-logos/github_logo.png';
import GitLabLogo from '@clubhouse/assets/png/third-party-logos/gitlab_logo.png';
import SentryLogo from '@clubhouse/assets/png/third-party-logos/sentry_black.png';
import ZendeskLogo from '@clubhouse/assets/png/third-party-logos/zendesk_logo.png';
import Collection from '../_frontloader/collection';
import Async from '../modules/async';
import Backend from '../modules/backend';
import _ from 'lodash';
const exports = {};
Collection.create('Application', exports);
exports.APPLICATION_IDS = {
  Zendesk: '59f9ac23-3bf4-45f0-a82b-a621f450ff39',
  Bitbucket: '3a7f854b-ab4b-4aca-8ef0-1c94ba7aeb0c',
  Gitlab: '5c782124-4636-4d3b-a3e1-36e798dfeffa',
  Sentry: '5c813931-06fd-457f-ac79-4456f9b6a647',
  Github: '66364e9b-7b69-47e5-8765-6b247f0fd6db',
  Productboard: 'd28bfee1-1541-4066-8d63-d608774a57b8'
};
exports.isValid = application => {
  return !!(application && application.id);
};
exports.normalize = application => {
  application.name = _getName(application);
  application.logo = _getLogo(application);
};
exports.getIntegrationSettingsPath = applicationId => {
  switch (applicationId) {
    case exports.APPLICATION_IDS.Zendesk:
      return '/settings/integrations/zendesk';
    case exports.APPLICATION_IDS.Bitbucket:
      return '/settings/integrations/bitbucket';
    case exports.APPLICATION_IDS.Gitlab:
      return '/settings/integrations/gitlab';
    case exports.APPLICATION_IDS.Sentry:
      return '/settings/integrations/sentry';
    case exports.APPLICATION_IDS.Github:
      return '/settings/integrations/github';
    default:
      return '';
  }
};
exports.getZendeskId = () => {
  return exports.APPLICATION_IDS.Zendesk;
};
exports.getSentryId = () => {
  return exports.APPLICATION_IDS.Sentry;
};
exports.getZendesk = () => {
  return exports.get({
    id: exports.APPLICATION_IDS.Zendesk
  });
};
exports.getBitbucket = () => {
  return exports.get({
    id: exports.APPLICATION_IDS.Bitbucket
  });
};
exports.fetchZendesk = callback => {
  _fetch(exports.APPLICATION_IDS.Zendesk, callback);
};
exports.fetchAll = callback => {
  Async.eachInParallelThen(_.map(exports.APPLICATION_IDS, function (id) {
    return _fetch.bind(this, id);
  }), callback);
};
function _fetch(id, callback) {
  Backend.get('/api/private/applications/' + id, {
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
}
function _getName(application) {
  return _.findKey(exports.APPLICATION_IDS, id => id === application.id);
}
function _getLogo(application) {
  switch (application.id) {
    case exports.APPLICATION_IDS.Zendesk:
      return ZendeskLogo;
    case exports.APPLICATION_IDS.Bitbucket:
      return BitBucketLogo;
    case exports.APPLICATION_IDS.Gitlab:
      return GitLabLogo;
    case exports.APPLICATION_IDS.Sentry:
      return SentryLogo;
    case exports.APPLICATION_IDS.Github:
      return GithubLogo;
    default:
      return '';
  }
}
export { exports as default };