import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import ApplicationModel from 'app/client/core/js/models/application';
import InstallationModel from 'app/client/core/js/models/installation';
import { useEntities } from 'data/entity/collection';
export const ApplicationIds = ApplicationModel.APPLICATION_IDS;
export const install = (id, data) => InstallationModel.Promises.installByApplicationIdWithOptions(id, {
  data
});
export const installProductboard = data => install(ApplicationModel.APPLICATION_IDS.Productboard, data);
export const isProductboardEnabled = () => InstallationModel.isProductboardEnabled();
export const uninstallZendesk = installationId => new Promise((resolve, reject) => InstallationModel.uninstallZendesk(installationId, (err, res) => {
  if (err) reject(err);else resolve(res);
}));
export const reinstallZendesk = installationId => new Promise((resolve, reject) => InstallationModel.reinstallZendesk(installationId, (err, res) => {
  if (err) reject(err);else resolve(res);
}));
export const deleteInstallation = installation => InstallationModel.Promises.delete(installation);
export const useInstallationByApplicationId = applicationId => {
  const installations = useInstallations();
  const installation = installations.find(entry => entry.application_id === applicationId);
  return installation;
};
export const useInstallationsByApplicationId = applicationId => {
  const installations = useInstallations();
  return (installations || []).filter(entry => entry.application_id === applicationId);
};
export const useInstallations = () => {
  const {
    entities
  } = useEntities({
    model: InstallationModel
  });
  return entities;
};
export const getInstallation = applicationId => InstallationModel.getByApplicationId(applicationId);
export const getInstallations = applicationId => InstallationModel.getMultipleByApplicationId(applicationId);
export const fetchAll = () => InstallationModel.Promises.fetchAll();
export const fetchByApplicationId = async applicationId => {
  try {
    const installation = await InstallationModel.Promises.fetchByApplicationId(applicationId);
    return installation || null;
  } catch {
    /* Not installed */
  }
  return null;
};
export const update = (installation, changes) => new Promise((resolve, reject) => {
  InstallationModel.update(installation, changes, (err, res) => {
    if (err) reject(err);else resolve(res);
  });
});
export const enableInstallation = installation => new Promise((resolve, reject) => {
  InstallationModel.enable(installation, (err, res) => {
    if (err) reject(err);else resolve(res);
  });
});
export const disableInstallation = installation => new Promise((resolve, reject) => {
  InstallationModel.disable(installation, (err, res) => {
    if (err) reject(err);else resolve(res);
  });
});
export const getInstallationsForUser = applicationId => InstallationModel.getInstallationsForUser(applicationId);
export const installByApplicationId = applicationId => InstallationModel.Promises.installByApplicationId(applicationId);
export const disableByApplicationId = applicationId => InstallationModel.Promises.disableByApplicationId(applicationId);
export const installCopyToCurrentWorkspace = installation_id => InstallationModel.installCopyToCurrentWorkspace(installation_id);