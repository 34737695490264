import "core-js/modules/es.array.push.js";
import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.reduce.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import StoryDialogController from 'app/client/core/js/controllers/storyDialog';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  const StoryDialogController = exports;
  [[['Model', 'Story'], StoryModel], [['Controller', 'StoryDialog'], StoryDialogController]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});

/*eslint-disable import/order*/
import * as StoryDialogTemplate from 'app/client/core/views/templates/storyDialog.html?caveman';
import * as StoryLoadingTemplate from 'app/client/core/views/templates/storyLoading.html?caveman';
import * as StoryAutoLinkTemplate from 'app/client/core/views/templates/storyAutoLink.html?caveman';
import * as ModalDialogStoryNavigationTemplate from 'app/client/core/views/templates/modalDialogStoryNavigation.html?caveman';
import { StoryAttachments } from 'components/stories/StoryAttachments';
import { withWriteContext } from 'pages/write';
import { applicationStateKey as TOGGLE_STATE_KEY } from 'components/stories/OptInToggleButton/useOptInToggleState';
import { StoryDialogLoadingSkeleton } from 'pages/story/StoryDialogLoadingSkeleton';
import { StoryDialogNavigation } from 'components/shared/StoryDialogNavigation/StoryDialogNavigation';
import { hasStoryDialogNavigation as hasReactiveNavigationList } from 'components/shared/StoryDialogNavigation/utils';
import { StoryArchivedMessage } from 'pages/story/StoryArchivedMessage';
import AddExternalLinkController from './addExternalLink';
import AddNewStoryController from './addNewStory';
import AutocompleteController from './autocomplete';
import BaseUtils from '../_frontloader/baseUtils';
import BulkEditorController from './bulkEditor';
import CommentController from 'app/client/core/js/controllers/comment';
import ContextMenuController from './contextMenu';
import Dialog from '../modules/dialog';
import DropdownController from './dropdown';
import DropdownModel from '../models/dropdown';
import * as Event from '../_frontloader/event';
import Globals from '../_frontloader/globals';
import ApplicationState from '../modules/applicationState';
import Is from '../modules/is';
import Lightbox from '../modules/lightbox';
import LinkedFileModel from '../models/linkedFile';
import Log from '../modules/log';
import MessageController from './message';
import PanelController from './panel';
import ProfileModel from '../models/profile';
import QuickSearchController from './quickSearch';
import RecentlyViewedController from './recentlyViewed';
import Router from '../_frontloader/router';
import Segment from '../modules/segment';
import StoryController from './story';
import StoryHistoryModel from '../models/storyHistory';
import StoryModel from '../models/story';
import TaskController from './task';
import Tests from 'app/client/core/js/modules/tests';
import Tooltip from '../modules/tooltip';
import UploaderController from './uploader';
import Url from '../modules/url';
import UserModel from '../models/user';
import User from '../modules/user';
import Utils from '../modules/utils';
import View from '../modules/view';
import WritePageController from '../../../write/js/controllers/writePage';
import Constants from '../modules/constants';
import StoriesView from 'app/client/stories/js/modules/storiesView';
import { EVENTS, endTrace, startTraceIfUnstarted } from 'utils/monitoring';
import { createBackgroundLocationState, getCurrentBackgroundLocationState } from 'utils/navigation/backgroundLocationState';
import { getRelativeHref } from 'utils/navigation';
import isEqual from 'lodash/isEqual';
import { StoryDescriptionEditor } from 'components/stories/StoryDescriptionEditor';
import { StoryBreadcrumbs } from 'components/stories/StoryBreadcrumbs';
import { hasAnySnapshotWithPrefix } from 'utils/snapshot';
import { isRendering, startRendering, stopRendering } from 'utils/storyDialog';
import { isObjectivesEnabledForCurrentOrg } from 'data/entity/organization';
import { StorySubtasks } from 'components/stories/StorySubtasks';
import { StorySubtaskChip } from 'components/stories/StorySubtaskChip';
import { StorySubtaskParentLinkMobile } from 'components/stories/StorySubtasks/StorySubtaskParentLinkMobile';
import { FEATURE_TOGGLES, getFeatureToggle } from '@clubhouse/feature-toggles';
import _ from 'lodash';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};
const PARENT_SELECTOR = '.story-dialog';
const STORY_DIALOG_PARENT_ID = 'story-dialog-parent';
const STORY_DIALOG_NAVIGATION_SELECTOR = '.modal-dialog-nav';
const STANDARD_DIALOG_WIDTH = 980;
const STORY_DIALOG_TYPE = 'story';
// var MAXIMIZED_DIALOG_WIDTH = 1200;

// the dialog is visible
const ITEMS_RENDERED_DIALOG = 'dialog';

// story dialog components e.g., comments, description, related stories, etc
const ITEMS_RENDERED_ATTACHMENT_ITEMS = 'attachmentItems';

// the react component with attachment icons
const ITEMS_RENDERED_ATTACHMENTS = 'attachments';
exports.loadingStoryID = null;
exports.statusPageCurrentUserID = null;
exports.navigationListType = '';
exports.isOpen = () => {
  return $(PARENT_SELECTOR).length > 0;
};
exports.isOpening = () => isRendering();
const loadStoryOrShowAlert = (context, story, activityID) => {
  if (!StoryModel.isValid(story)) {
    Log.log('Unable to open story dialog: invalid story.');
    MessageController.alert('Story not found.', {
      id: 'story-not-found'
    });
  } else {
    _scrollToTop();
    exports.loadStory.call(context, story, activityID);
  }
};
exports.closeCurrentStoryDialog = () => {
  Dialog.closeIfOpen();
};
exports.open = function (e) {
  const element = $(this);
  e = e || {};
  if (element.closest('.bind-selection-toggle').length) {
    element.find('.bulk-checkbox').click();
    return false;
  }
  if (element.hasClass('search-result')) {
    Segment.sendEvent(EVENTS.Interaction_Search, {
      source: 'navigation',
      selection: 'story card'
    });
  }
  if (Is.mobile() || Utils.modifiedClick(e)) {
    // We're not returning false here because we want to
    // bubble down to the anchor tag's href behavior.
    return;
  }
  const story = Utils.getModelFromContext(this);
  const {
    activity
  } = exports.parseStoryURL(element.attr('href'));
  if (story) {
    loadStoryOrShowAlert(this, story, activity);
  } else {
    StoryModel.fetchStoryPromise(e.target.dataset.id).then(story => loadStoryOrShowAlert(this, story, activity));
  }
  e.preventDefault();
  return false;
};
exports.openFromMessage = function (e) {
  MessageController.closeAll();
  return exports.open.call(this, e);
};
exports.openFromRouter = () => {
  openOrFetchFromUrl(window.location.href);
};
exports.loadStoryById = storyId => {
  const story = StoryModel.getById(storyId);
  return exports.loadStory(story);
};
exports.loadStory = async function (story, activityID) {
  exports.loadingStoryID = story.id;
  openStory.call(this, story, activityID);
  await StoryModel.Promises.fetchStory(story.id);

  // Need to check this in case the user switches to a different story before
  // this finishes loading, or the dialog has been closed.
  if (exports.loadingStoryID !== story.id || !Dialog.isOpen()) {
    return;
  }
  exports.update({
    force: true
  });
  exports.loadingStoryID = null;
};
const HAS_STARTED_PAGE_RENDER_TRACE = 'HAS_STARTED_PAGE_RENDER_TRACE';
const RENDERED_ITEMS_KEY = 'story-dialog-renderedItems';
const getRenderedItems = () => Globals.get(RENDERED_ITEMS_KEY);
const setRenderedItems = value => Globals.set(RENDERED_ITEMS_KEY, value);

// NOTE: Some state should only be cleaned up when the dialog is fully closed,
// The dialog controller's onClose and onDestroy callbacks get executed on each re-render so cleaning up the state there
// could cause unwanted side-effects, deferring the cleanup by a couple of milliseconds and clearing the timer
// when the Story dialog opens/rerenders ensures it only happens when the dialog has been closed completely.
let deferredCleanupTimer;
const DEFERRED_CLEANUP_TIME = 300;
function deferredCleanup() {
  // Component cleanup needs to be deferred so that mount nodes are disconnected by the time this function is called.
  View.unmountDetachedComponents();
  window.removeEventListener('beforeunload', TaskController.saveUncommittedTaskOnReload);
}
const getBreadcrumbComponent = () => {
  if (isObjectivesEnabledForCurrentOrg()) return StoryBreadcrumbs;else return StoryBreadcrumbs.EpicsAndStoriesOnly;
};
const openStory = async function (story, activityID) {
  window.addEventListener('beforeunload', TaskController.saveUncommittedTaskOnReload);
  // This should be executed before accessing `story`
  StoryModel.normalize(story);
  StoryModel.normalizeStoryDetails(story);
  const url = activityID ? story.url + '#activity-' + activityID : story.url;
  if (activityID) {
    // Deferring one second so that images are more likely to load.
    setTimeout(() => {
      CommentController.updateAnchorHighlightForComment(activityID, true);
    }, 1000);
  }

  // This is a temporary workaround to the fact that we're not handling the story screen on mobile anymore
  // TODO: (@charpeni) We should figure out how we want to clean this file open and consolidate into
  // a single entry point to open a story
  if (Is.mobile()) {
    return Utils.redirect(url, true);
  }
  startRendering();
  setRenderedItems(new Set());
  const {
    newTrace
  } = startTraceIfUnstarted(Constants.PAGE_RENDER_TRACE_ID, Url.getPageFromCurrentPathname(), 'pageLoad');
  Globals.set(HAS_STARTED_PAGE_RENDER_TRACE, newTrace);
  AddNewStoryController.close();
  BulkEditorController.close();
  Tooltip.close();
  DropdownController.closeAll();

  // Fixes https://app.shortcut.com/internal/story/98725
  if (story) {
    RecentlyViewedController.addToRecentlyViewed('story', story.id);
  }
  UploaderController.initLibraries();
  Lightbox.loadLibraryIfMissing();
  exports.watchStory();
  const permission = UserModel.getLoggedInUserPermission();
  const isReadOnly = Is.readOnly(permission);
  const currentPage = Url.getCurrentPage();
  const backgroundLocationState = createBackgroundLocationState();
  const title = `${story.name} | ${BRAND.NAME}`;
  document.title = title;
  const relativeUrl = getRelativeHref();
  const currentBackgroundLocationState = getCurrentBackgroundLocationState();
  if (url !== relativeUrl || !isEqual(backgroundLocationState, currentBackgroundLocationState)) {
    Router.pushState(url, title, backgroundLocationState);
  }
  const html = StoryDialogTemplate.render(_.assign({
    readOnly: isReadOnly
  }, story));
  if (StoryModel.isFullyLoaded(story)) {
    getRenderedItems().add(ITEMS_RENDERED_ATTACHMENT_ITEMS);
  }
  const classNames = 'story-container' + (story.archived ? ' archived' : '');
  const options = {
    shouldAnimate: false,
    closeOnEscape: true,
    disallowCloseOnEscape: exports.disallowCloseOnEscape,
    onClose: () => {
      _unbindKeyboardShortcuts();
      if (exports.shouldRenderCavemanTasks()) {
        TaskController.saveUncommittedTask();
      }

      // TODO: Figure out if we still need this:
      $(PARENT_SELECTOR).closest('.story-container').off('scroll');
      AddExternalLinkController.close();
      DropdownController.closeAll();
      AutocompleteController.close();
      ContextMenuController.close();
      StoryModel.deleteLastEmptyTask(story);
      if (!Dialog.isOpening()) {
        exports.statusPageCurrentUserID = null;
      }
      if (_userIsEditing(story)) {
        MessageController.info('Your edits to ' + StoryAutoLinkTemplate.render(story) + ' have not been lost. Open the story to continue editing.', {
          id: 'saved-story-dialog-edits',
          actions: '<button class="action mini elevated" data-controller="StoryDialog" ' + 'data-on-click="openFromMessage" data-model="Story" data-id="' + story.id + '">Open Story</button>',
          timeout: 12000
        });
      }
      Event.trigger('storyElementTearDown', $(PARENT_SELECTOR)[0]);
      deferredCleanupTimer = setTimeout(deferredCleanup, DEFERRED_CLEANUP_TIME);
    },
    onResize: _onDialogResize,
    shouldNotCloseFn: _inPlaceTextareaFocused,
    fullscreen: true,
    expandable: true,
    pinnableButtonProps: {
      globalId: story.global_id
    },
    breadcrumbsProps: {
      globalId: story.global_id,
      publicId: story.id,
      Component: getBreadcrumbComponent()
    },
    onExpand: _onExpand,
    classNames,
    id: STORY_DIALOG_PARENT_ID,
    type: STORY_DIALOG_TYPE,
    width: _getDialogWidth(),
    onDialogVisible: () => {
      const element = $(PARENT_SELECTOR);
      if (deferredCleanupTimer) clearTimeout(deferredCleanupTimer);
      getRenderedItems().add(ITEMS_RENDERED_DIALOG);
      StoryController.updateAttributeComponent(story, element);
      conditionallyFinishLoadingAndStopTimer(element);
    }
  };
  if (Dialog.isOpen()) {
    Dialog.rerender(html, options);
  } else {
    Dialog.open(html, options);
  }
  View.renderComponent({
    componentKey: `storyDescriptionEditor-${story.id}`,
    mountNode: document.querySelector('#story-description-v2'),
    component: StoryDescriptionEditor,
    props: {
      id: story.id
    }
  });
  View.renderComponent({
    componentKey: 'storyDialogStoryAttachements',
    mountNode: document.querySelector('#story-attachments'),
    component: withWriteContext(StoryAttachments, WritePageController.getProps()),
    props: {
      StoryModel,
      Dialog,
      Tests,
      withRelatedDocs: true,
      story,
      onUpdateComplete: ({
        hasRenderedStoryData
      }) => {
        if (hasRenderedStoryData) {
          const element = $(PARENT_SELECTOR);
          getRenderedItems().add(ITEMS_RENDERED_ATTACHMENTS);
          conditionallyFinishLoadingAndStopTimer(element);
        }
      },
      addExternalLink: e => {
        AddExternalLinkController.open.call(e.target);
      },
      addFile: e => {
        StoryController.openFileUploadDropdown.call(e.target);
      }
    }
  });
  if (hasReactiveNavigationList() && !_hasNavigationItems()) {
    View.renderComponent({
      componentKey: `story-navigation-${story.id}`,
      component: StoryDialogNavigation,
      mountNode: document.querySelector(STORY_DIALOG_NAVIGATION_SELECTOR),
      props: {
        storyId: story.global_id
      }
    });
  } else {
    await exports.updateNavigationItems(story);
  }
  if (currentPage === 'status') {
    exports.getStatusPageCurrentUserID.call(this);
  }
  const element = $(PARENT_SELECTOR);

  // Place focus in the story dialog for up/down arrow interaction.
  // Ref: https://stackoverflow.com/questions/6754275/set-keyboard-focus-to-a-div
  element.focus();

  // Sometimes a story dialog will be scrolled down after focusing.
  // I'm not sure why, but let's force the scroll to the top.
  $('.story-container').scrollTop(0);
  Event.trigger('afterStoryDetailsRender', element);
  Utils.autoTabIndex(element);
  _bindKeyboardShortcuts();
};
const _onExpand = () => {
  const currentExpanded = User.isStoryDialogExpanded();
  User.setStoryDialogExpanded(!currentExpanded);
  Dialog.setWidth(_getDialogWidth());
};
const haveAllItemsRendered = () => {
  const expectedItems = [ITEMS_RENDERED_DIALOG, ITEMS_RENDERED_ATTACHMENT_ITEMS, ITEMS_RENDERED_ATTACHMENTS];
  return !expectedItems.some(k => !getRenderedItems().has(k));
};
const conditionallyFinishLoadingAndStopTimer = element => {
  if (haveAllItemsRendered() && isRendering()) {
    StoryController.removeLoadingIndicator(element);
    if (Globals.get(HAS_STARTED_PAGE_RENDER_TRACE)) {
      endTrace(Constants.PAGE_RENDER_TRACE_ID, {
        chPage: 'story',
        hotReload: true
      });
    }
    Event.trigger('storyDialogFinishedLoading');
    stopRendering();
  }
};
function _getDialogWidth() {
  return User.isStoryDialogExpanded() ? '94vw' : STANDARD_DIALOG_WIDTH;
}
function _onDialogResize() {
  const dialogElement = Dialog.getElement();
  toggleStickySidebar({
    dialogElement
  });
}
const toggleStickySidebar = ({
  dialogElement
}) => {
  const dialogContainer = dialogElement.find('.container');
  const bottomOfDialog = dialogContainer.height();
  const rightColumnOffset = dialogElement.find('.scrollable-content').offset().top - dialogElement.offset().top;
  const bottomOfRightColumn = dialogElement.find('.right-column').height() + rightColumnOffset;
  const className = 'fixed';
  if (bottomOfDialog > bottomOfRightColumn) {
    dialogContainer.addClass(className);
  } else {
    dialogContainer.removeClass(className);
  }
};
exports.updateCommentsWithSnapshots = (element, state) => {
  if (_.isString(state.comment)) {
    element.find('.add-comment').click();
    element.find('.comments textarea').val(state.comment).height(state.commentHeight).focus().setSelection(state.commentSelectStart, state.commentSelectEnd);
  }
};
exports.disallowCloseOnEscape = () => {
  const dropdownIsNotifications = DropdownModel.isOnlyNotifications();
  const dropdownIsQuickSearch = DropdownModel.isOnlyQuickSearch();
  const dropdownIsOpen = DropdownModel.size() > 0 && !(dropdownIsNotifications || dropdownIsQuickSearch);
  const autocompleteIsClosing = AutocompleteController.isClosing;
  const taskIsBeingEdited = $(document.activeElement).closest('.tasks').length > 0;
  const autocompleteIsOpen = $('#story-description-v2 textarea[aria-expanded="true"]').length > 0;
  const textareaHasFocus = document.activeElement.matches('textarea');
  return textareaHasFocus || Lightbox.isOpen() || taskIsBeingEdited || autocompleteIsClosing || dropdownIsOpen || PanelController.isOpen() || autocompleteIsOpen;
};
function _inPlaceTextareaFocused() {
  return $(document.activeElement).is(PARENT_SELECTOR + ' .in-place-textarea textarea');
}
function _userIsEditing(story) {
  return hasAnySnapshotWithPrefix(`story.${story.id}`);
}
exports.parseStoryURL = (url = '') => {
  try {
    const urlObject = new URL(url, window.location.origin);
    url = `${urlObject.pathname}${urlObject.hash}`;
  } catch {}
  const parts = _.compact(url.split('/'));
  const storyName = _.compact((parts[3] || '').split('#activity-'));
  return {
    id: BaseUtils.toNumber(parts[2] || ''),
    activity: BaseUtils.toNumber(storyName[1] || '')
  };
};
exports.getStoryFromURL = url => {
  url = url || Url.getCurrentPathnameWithHash();
  const id = exports.parseStoryURL(url).id;
  return StoryModel.getById(id);
};
function _bindKeyboardShortcuts() {
  $(window).on('keydown.StoryDialogShortcuts', exports.storyShortcutHandler);
}
function _unbindKeyboardShortcuts() {
  $(window).off('keydown.StoryDialogShortcuts');
}
exports.storyShortcutHandler = e => {
  if (Is.storiesPage()) {
    if (Utils.isUnfocusedKeyPress(e, ['CMD+SHIFT+UP_ARROW', 'CTRL+SHIFT+UP_ARROW'])) {
      return StoryController.reorderStory(exports.getStoryFromURL(), {
        first: true
      });
    }
    if (Utils.isUnfocusedKeyPress(e, ['CMD+SHIFT+DOWN_ARROW', 'CTRL+SHIFT+DOWN_ARROW'])) {
      return StoryController.reorderStory(exports.getStoryFromURL(), {
        last: true
      });
    }
  }
};
exports.updateNavigationItems = async story => {
  if (!exports.isOpen() || hasReactiveNavigationList()) return;
  story = story ?? exports.getStoryFromURL();

  // Guard against story being deleted.
  if (!story) {
    return;
  }
  const links = await exports.generateNavLinks(story);
  // We need to set these two so that left/right keys work,
  // even though they're not used in the view.
  const dialogState = Dialog.getState();
  dialogState.onPrev = links.prev;
  dialogState.onNext = links.next;
  const html = ModalDialogStoryNavigationTemplate.render(links);
  $(STORY_DIALOG_NAVIGATION_SELECTOR).html(html);
};
const isUsingNewStoriesPage = () => {
  return ApplicationState.get(TOGGLE_STATE_KEY) ?? false;
};
exports.getNavigationItems = async function (story) {
  const page = Url.getCurrentPage();
  let storyCollection = [];
  let storyElement = $(this).data('id') === story.id ? $(this) : null;
  let userID = null;
  let epic = null;
  if (QuickSearchController.isOpen()) {
    storyCollection = StoryModel.elementsToStories($('.quick-search .story'));
    exports.navigationListType = 'search results';
  }
  if (storyCollection.length === 0) {
    if (Is.storiesPage() && !isUsingNewStoriesPage()) {
      storyElement = storyElement || StoryController.getStoryElementInColumn(story);
      storyCollection = [story];
      const {
        prevStory,
        nextStory
      } = StoriesView.getPreviousAndNextStories(story);
      if (prevStory) {
        storyCollection.unshift(prevStory);
      }
      if (nextStory) {
        storyCollection.push(nextStory);
      }
      exports.navigationListType = StoryModel.getWorkflowStateName(story);
    } else if (page === 'dashboard') {
      storyElement = storyElement || StoryController.getStoryElementInColumn(story);
      storyCollection = StoryModel.elementsToStories(storyElement.closest('.column').find('.story'));
      exports.navigationListType = 'your work';
    } else if (page === 'epics') {
      storyElement = storyElement || $('.epic .story-' + story.id);
      storyCollection = StoryModel.elementsToStories(storyElement.closest('.epic').find('.story'));
      exports.navigationListType = Utils.getModelFromContext(storyElement.parent()).name;
    } else if (page === 'epic') {
      epic = Utils.getModelFromContext('.epic-page');
      storyElement = storyElement || $(`[data-scroll-target="stories"] [data-id="${story.id}"]`);
      storyCollection = StoryModel.elementsToStories($('[data-scroll-target="stories"] .story'));
      exports.navigationListType = epic.name;
    } else if (page === 'status') {
      userID = exports.getStatusPageCurrentUserID();
      if (userID) {
        storyElement = storyElement || $('.user[data-id="' + userID + '"] .story-' + story.id);
        storyCollection = StoryModel.elementsToStories(storyElement.closest('.user').find('.story'));
        exports.navigationListType = _.get(ProfileModel.getAllDetailsById(userID), 'name');
      }
    } else if (page === 'search') {
      storyCollection = StoryModel.elementsToStories($('#search-page .story-tr'));
      exports.navigationListType = 'search results';
    } else if (page === 'reports') {
      storyCollection = StoryModel.elementsToStories($('.name-column .story'));
      exports.navigationListType = 'the completed stories table';
    } else if (page === 'iterations') {
      storyElement = storyElement || $(`.iteration-card [data-model="Story"][data-id="${story.id}"]`);
      const iterationCard = storyElement.closest('.iteration-card');
      storyCollection = StoryModel.elementsToStories(iterationCard.find('[data-model="Story"][data-id]'));
      exports.navigationListType = Utils.getModelFromContext(iterationCard).name;
    } else if (page === 'iteration') {
      const iteration = Utils.getModelFromContext('.iteration-page');
      storyElement = storyElement || $(`[data-scroll-target="stories"] [data-id="${story.id}"]`);
      storyCollection = StoryModel.elementsToStories($('[data-scroll-target="stories"] .story'));
      exports.navigationListType = iteration.name;
    } else if (page === 'write') {
      storyElement = storyElement || $(`#doc_main [data-model="Story"][data-id="${story.id}"]`);
      storyCollection = StoryModel.elementsToStories($('#doc_main [data-model="Story"]'));
      exports.navigationListType = 'Doc';
    }
  }
  const storyIndex = _.indexOf(storyCollection, story);
  return {
    prev: storyCollection[storyIndex - 1],
    next: storyCollection[storyIndex + 1]
  };
};
function _hasNavigationItems() {
  return document.querySelector(STORY_DIALOG_NAVIGATION_SELECTOR).innerHTML.trim() !== '';
}
exports.getNavigationTooltip = function () {
  const story_direction = $(this).hasClass('prev') ? 'prev' : 'next';
  const headline = exports.getTooltipHeadline(story_direction);
  const story = _.assign({}, Utils.getModelFromContext(this), {
    header: headline
  });
  return StoryController.renderStoryTooltip(story);
};
exports.getTooltipHeadline = story_direction => {
  let headline = '';
  const prev_or_next = story_direction === 'prev' ? 'Previous' : 'Next';
  if (exports.navigationListType) {
    headline = prev_or_next + ' story from ' + exports.navigationListType + '...';
  }
  return headline;
};
exports.generateNavLinks = async story => {
  const nav = await exports.getNavigationItems(story);
  if (nav.prev && !StoryModel.isFullyLoaded(nav.prev)) {
    StoryModel.fetchStory(nav.prev.id);
  }
  if (nav.next && !StoryModel.isFullyLoaded(nav.next)) {
    StoryModel.fetchStory(nav.next.id);
  }
  return {
    nav,
    next: exports.generateNavLink(nav.next),
    prev: exports.generateNavLink(nav.prev)
  };
};
exports.generateNavLink = story => {
  return story && (() => {
    exports.loadStory(story);

    // This works on left/right key presses, not on click.
    // Waiting 100ms because sometimes it jumps down a little
    // if this is done immediately.
    _scrollToTop();
    setTimeout(_scrollToTop, 100);
    return false;
  });
};
function _scrollToTop() {
  $('.story-container').scrollTop(0);
}
exports.getStatusPageCurrentUserID = function () {
  const id = exports.statusPageCurrentUserID || $(this).closest('.user').data('id');
  exports.statusPageCurrentUserID = id;
  return id;
};
exports.update = async options => {
  options = {
    ...options,
    onUpdate: ({
      hasRenderedStoryData,
      element
    }) => {
      if (hasRenderedStoryData) {
        getRenderedItems().add(ITEMS_RENDERED_ATTACHMENT_ITEMS);
        conditionallyFinishLoadingAndStopTimer(element);
      }
    }
  };
  if (Globals.get('isDragging')) {
    Log.debug('StoryDialog: not updating, user is dragging.');
  } else if (Globals.get('isSaving') && options.force !== true) {
    Log.debug('StoryDialog: not updating, something is mid-save.');
  } else {
    const element = $(PARENT_SELECTOR);
    if (element.length > 0) {
      const story = Utils.getModelFromContext(element);
      if (story) {
        // We want to draw navigation during a direct link after stories load,
        // but we don't want to redraw and disrupt a user already navigating.
        if (!_hasNavigationItems()) {
          await exports.updateNavigationItems();
        }
        StoryController.warnIfDirty('story', story, 'description');
        StoryController.updateComponents(story, element, options);
        StoryHistoryModel.fetch(story);
        Event.trigger('afterStoryDetailsRender', element);
      } else {
        // Story has been deleted, remove dialog
        Dialog.close();
        MessageController.info('The story you had open was just deleted.');
      }
    }
  }
};
exports.getCurrentlyDisplayedStory = () => {
  const element = $(PARENT_SELECTOR);
  if (element.length > 0) {
    return Utils.getModelFromContext(element);
  }
};
exports.watchStory = () => {
  StoryModel.off('storySaved latestVersionFetched');

  // We have `storySaved` and `latestVersionFetched` here so that we can support optimistic updates.
  StoryModel.on('storySaved latestVersionFetched', story => {
    const storyInDialog = exports.getCurrentlyDisplayedStory();
    if (storyInDialog && story.id === storyInDialog.id) {
      exports.update();
    }
  });
};
exports.init = () => {
  exports.watchStory();
  LinkedFileModel.on('fetched', () => {
    exports.update({
      force: true
    });
  });
  const NS = '.StoryDialog';
  Event.onlyOn(`filesAttached${NS} ExternalLinkAssociated iterationsEnabled${NS} iterationsDisabled${NS}`, () => {
    exports.update({
      force: true
    });
  });
  Event.onlyOn(`storiesLookedUp${NS}`, () => {
    exports.update();
  });
};
Router.add(url => {
  return url.indexOf(Url.getSlugPath() + '/story/') === 0;
}, url => {
  const story = exports.getStoryFromURL(url);
  return story && story.name;
}, url => {
  openOrFetchFromUrl(url);
});
const openOrFetchFromUrl = url => {
  const urlParts = exports.parseStoryURL(url);
  const story = StoryModel.getById(urlParts.id);
  if (story) {
    exports.loadStory(story, urlParts.activity);
  } else {
    // This can happen if the user has been redirected from a /story/:id deep link
    // back to /stories. At this point, no stories have been fetched yet, so let's
    // request it now instead of waiting for the entire searchStories response.
    StoryModel.fetchStory(urlParts.id, () => {
      const story = StoryModel.getById(urlParts.id);
      if (story) {
        exports.loadStory(story, urlParts.activity);
      } else {
        MessageController.alert('Story <strong>#' + urlParts.id + '</strong> not found.');
      }
    });
  }
};

/**
 * We only want to render the caveman tasks if the Sub-tasks feature toggle is disabled.
 */
exports.shouldRenderCavemanTasks = () => {
  const isSubtasksEnabled = getFeatureToggle(FEATURE_TOGGLES.ENABLE_SUBTASKS);
  return isSubtasksEnabled === false;
};
exports.showLoading = _options => {
  const options = {
    shouldAnimate: false,
    closeOnEscape: true,
    disallowCloseOnEscape: exports.disallowCloseOnEscape,
    onClose: () => {
      if (_options && _options.onClose) _options.onClose();
    },
    onResize: _onDialogResize,
    fullscreen: true,
    expandable: true,
    onExpand: _onExpand,
    classNames: 'story-dialog-loading',
    id: STORY_DIALOG_PARENT_ID,
    type: STORY_DIALOG_TYPE,
    width: _getDialogWidth(),
    pinnableButtonProps: {},
    onDialogVisible: () => {
      if (deferredCleanupTimer) clearTimeout(deferredCleanupTimer);
    },
    breadcrumbsProps: {
      Component: getBreadcrumbComponent()
    }
  };
  const html = StoryLoadingTemplate.render();
  if (Dialog.isOpen()) {
    Dialog.rerender(html, options);
  } else {
    Dialog.open(html, options);
  }
};

// You may be wondering why we have two functions here instead of just one.
// The reason is that we can't reuse the same component key with different props,
// as the first render won't be right. So we need to render two different components,
// depending on whether we want the title or not. Otherwise, we could see the title
// still being there... right under the actual title!
exports.renderLoadingSkeletonOuter = () => {
  return View.renderComponentDelayed({
    componentKey: 'story-dialog-loading-skeleton-outer',
    component: _jsx(StoryDialogLoadingSkeleton, {
      showTitle: true
    })
  }).html;
};
exports.renderLoadingSkeletonInner = () => {
  return View.renderComponentDelayed({
    componentKey: 'story-dialog-loading-skeleton-inner',
    component: _jsx(StoryDialogLoadingSkeleton, {})
  }).html;
};
exports.renderSubtasks = story => {
  return View.renderComponentDelayed({
    componentKey: `story-subtasks-${story.id}`,
    component: _jsx(StorySubtasks, {
      storyGlobalId: story.global_id
    })
  }).html;
};
exports.renderSubtaskChip = story => {
  return View.renderComponentDelayed({
    componentKey: `story-subtask-chip-${story.id}`,
    cssClass: 'subtask-chip',
    component: _jsx(StorySubtaskChip, {
      className: "sub-task",
      storyGlobalId: story.global_id
    })
  }).html;
};
exports.renderMobileSubtaskParentLink = story => {
  return View.renderComponentDelayed({
    componentKey: `story-subtask-parent-link-mobile-${story.id}`,
    component: _jsx(StorySubtaskParentLinkMobile, {
      storyGlobalId: story.global_id
    })
  }).html;
};
exports.renderStoryArchivedMessage = story => {
  return View.renderComponentDelayed({
    componentKey: `story-archived-message-${story.id}`,
    component: _jsx(StoryArchivedMessage, {
      isArchived: story.archived,
      storyGlobalId: story.global_id
    })
  }).html;
};
export { exports as default };