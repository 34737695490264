import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import moment from 'moment';
import { FEATURE_TOGGLES, getFeatureToggle } from '@clubhouse/feature-toggles';
import { capitalize } from '@clubhouse/shared/utils';
import { CumulativeFlowReportCard } from 'components/reports/cumulativeFlow/Report';
import { getOrgWorkingDays } from 'data/entity/organization';
import { NON_WORKING_DAYS_LABEL, formatIterationDateRange, importChartingLibraries, mergeNonWorkingData } from 'utils/charts';
import { CHART_ID } from './constants';
import { getCumulativeFlowChartConfig } from './config';
import ReportModel from '../../../models/report';
import WorkflowModel from '../../../models/workflow';
import Constants from '../../../modules/constants';
import View from '../../../modules/view';
import ReportsController from '../../reports';
function isNumber(val) {
  return typeof val === 'number';
}
const DATE_FORMAT = ReportModel.DEFAULT_DATE_FORMAT;
export const renderCumulativeFlowChartComponent = ({
  chartData,
  errorMsg,
  options,
  onChangeFilter
}) => {
  const mountNode = document.getElementById(`${CHART_ID}-chart`);
  if (mountNode) {
    View.renderComponent({
      mountNode,
      component: CumulativeFlowReportCard,
      props: {
        chartId: CHART_ID,
        chartData,
        errorMsg,
        onChangeFilter,
        renderChart: renderData => renderChart({
          chartData: renderData,
          options
        })
      }
    });
  }
};
const formatData = ({
  chartData = {},
  sortedIterations,
  workflowStates,
  aggregateType,
  options
}) => {
  // Initializes C3 Groups - 1 for each Workflow State AND list of dates for x axis values
  const {
    dateRange,
    interval
  } = options;
  const intervalUnit = interval?.id === 'none' ? 'day' : interval?.id;
  const dateStrings = [];
  const iterationDateStrings = [];
  let date = dateRange?.start.clone() ?? moment();
  if (interval?.id === 'iteration') {
    // this handles both the available start dates as well as the formatted date ranges for each iteration
    (sortedIterations || []).forEach(iteration => {
      const {
        iteration_start,
        iteration_end
      } = iteration;
      dateStrings.push(iteration_start);
      const formattedDateRange = formatIterationDateRange(iteration_start, iteration_end);
      iterationDateStrings.push(formattedDateRange);
    });
  } else {
    while (!date.isAfter(dateRange?.end)) {
      dateStrings.push(date.format(DATE_FORMAT));
      date = date.add(1, intervalUnit);
    }
  }
  const initialGroups = workflowStates.reduce((groups, state) => {
    groups.push([state.name]);
    return groups;
  }, [['x', ...(interval?.id === 'iteration' ? iterationDateStrings : dateStrings)]]);
  const sortedValues = dateStrings.map(date => date && chartData[date] || {});
  return sortedValues.reduce((groups, workflowGroups) => {
    // Add point/story values for each workflow state
    workflowStates.forEach((state, i) => {
      const stateData = workflowGroups[state.id];
      const storiesOrPoints = stateData ? stateData[aggregateType] : 0;
      groups[i + 1].push(storiesOrPoints);
    });
    return groups;
  }, initialGroups);
};
const sortIterations = data => {
  const dataWithDefault = data || {};
  const sortedDates = Object.keys(dataWithDefault).sort((a, b) => moment(a).diff(moment(b)));
  return sortedDates.map(date => {
    const {
      iteration_start,
      iteration_end,
      iteration_name
    } = dataWithDefault[date];
    return {
      iteration_start,
      iteration_end,
      iteration_name
    };
  });
};
export const renderChart = async ({
  chartData,
  options
}) => {
  const {
    c3,
    d3
  } = await importChartingLibraries();
  const workflowId = getCumulativeFlowChartConfig('cf_workflow');
  const aggregateLabel = capitalize(getCumulativeFlowChartConfig('cf_aggregate'));
  const aggregateType = aggregateLabel === 'Stories' ? 'count' : 'points';
  const isGroupByIteration = options.interval?.id === 'iteration';
  const defaultWorkflowId = Object.keys(chartData)[0];
  const sortedIterations = isGroupByIteration ? sortIterations(chartData[workflowId] || chartData[defaultWorkflowId]) : [];
  const workflow = WorkflowModel.getById(workflowId) || WorkflowModel.getActive();
  const workflowStates = workflow.states.slice().sort((a, b) => b.position - a.position).map(state => ({
    id: state.id,
    name: state.name
  }));
  const colors = {};
  workflowStates.forEach((state, index) => {
    colors[state.name] = Constants.CHART_COLORS[index % Constants.CHART_COLORS.length];
  });
  const formattedData = formatData({
    chartData: chartData[workflowId],
    sortedIterations,
    workflowStates,
    aggregateType,
    options
  });
  const sortedDateStrings = formattedData[0].slice(1).sort((a, b) => moment(a).diff(moment(b)));
  const workingDays = getOrgWorkingDays();
  const c3Input = {
    bindto: options.element || document.getElementById(CHART_ID),
    size: {
      height: 320
    },
    data: {
      x: 'x',
      columns: formattedData,
      order: null,
      // Default behavior is to use a stacked area chart, but some users mentioned they preferred the old behavior,
      // which was a stacked bar, so in those cases, we add them to the fallback feature flag.
      type: getFeatureToggle(FEATURE_TOGGLES.REPORTS_FALLBACK_TO_CFD_AS_STACKED_BAR) ? 'bar' : 'area',
      groups: [workflowStates.map(({
        name
      }) => name)],
      colors
    },
    bar: {
      width: {
        ratio: 0.9
      }
    },
    point: {
      show: false
    },
    legend: {
      show: true
    },
    grid: {
      x: {
        show: false
      },
      y: {
        show: false
      }
    },
    axis: {
      x: {
        show: true,
        type: isGroupByIteration ? 'category' : 'timeseries',
        tick: !isGroupByIteration && {
          format: date => {
            const MAX_LABEL_COUNT = 5;
            const mod = Math.floor(sortedDateStrings.length / MAX_LABEL_COUNT);
            const index = sortedDateStrings.indexOf(moment(date).format(DATE_FORMAT));
            if (sortedDateStrings.length <= MAX_LABEL_COUNT || index % mod === 0) {
              return moment(date).format('MMM D, YYYY');
            }
          },
          values: sortedDateStrings
        }
      },
      y: {
        label: {
          text: `${aggregateLabel} completed`,
          position: 'outer-middle'
        },
        show: true,
        min: 0,
        padding: {
          bottom: 0,
          top: 0
        }
      }
    },
    tooltip: {
      position: function (data, width, height, element) {
        const {
          left
        } = c3.chart.internal.fn.tooltipPosition.apply(this, [data, width, height, element]);
        const chart = document.querySelector('.c3-zoom-rect');
        const chartHeight = chart?.clientHeight || 0;
        const mouse = d3.mouse(element);
        let tooltipTop = mouse[1] + 15;
        if (tooltipTop + height > chartHeight) {
          tooltipTop -= height + 30;
        }
        if (tooltipTop < 0) {
          tooltipTop = tooltipTop / 2;
        }
        return {
          top: tooltipTop,
          left
        };
      },
      contents: function (data, xFormat, yFormat, color) {
        data.reverse();
        const validData = data.filter(x => x.id !== NON_WORKING_DAYS_LABEL);
        const totalValue = validData.reduce((sum, n) => sum + n.value, 0);
        const totalLabel = `Total ${aggregateLabel}`;
        const finalData = [...validData, {
          id: totalLabel,
          index: validData.length + 1,
          name: totalLabel,
          value: totalValue
        }];
        return c3.chart.internal.fn.getTooltipContent.apply(this, [finalData, xFormat, yFormat, color]);
      },
      format: {
        title: index => {
          const inputDate = index;
          if (options.interval?.id === 'iteration' && isNumber(index)) {
            return sortedIterations[index]?.iteration_name;
          }
          return ReportsController.formatDate(inputDate);
        }
      }
    }
  };
  const workingDaysTooltips = {
    tooltip: {
      format: {
        title: inputDate => {
          const longDate = ReportsController.formatDate(inputDate);
          if (!workingDays.includes(moment(inputDate).day())) {
            return `${longDate} (No Work)`;
          }
          return longDate;
        }
      }
    }
  };
  const c3InputWithNonWorkingDays = mergeNonWorkingData(workingDays, options.interval.id, c3Input, workingDaysTooltips);
  return c3.generate(c3InputWithNonWorkingDays);
};