import Settings from 'app/client/core/js/modules/settings';
import Url from 'app/client/core/js/modules/url';
import View from 'app/client/core/js/modules/view';
import { Vanta } from 'pages/integrations/Vanta';
const exports = {};
exports.title = `Vanta Integration | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/settings/integrations/vanta';
};
exports.open = async () => {
  Settings.open({
    template: View.componentToTemplate(Vanta, 'integrations-vanta'),
    route: exports.route,
    title: exports.title
  });
  return false;
};
export { exports as default };