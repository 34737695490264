import "core-js/modules/es.array.push.js";
import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.reduce.js";
import HotReloadController from 'app/client/core/js/controllers/hotReload';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'HotReload'], HotReloadController]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { useEffect } from 'react';
import { Loading } from '@clubhouse/shared/components/Loading';
import { CONSOLIDATED_DATA_FETCHED_KEY } from 'app/client/core/js/modules/consolidatedFetch';
import * as AddNewEpicTemplate from 'app/client/core/views/templates/addNewEpic.html?caveman';
import * as AddNewEpicActionsTemplate from 'app/client/core/views/templates/addNewEpicActions.html?caveman';
import * as ButtonSavingStateTemplate from 'app/client/core/views/templates/buttonSavingState.html?caveman';
import * as EpicFollowerFooterTemplate from 'app/client/core/views/templates/epicFollowerFooter.html?caveman';
import * as NewEpicDateControlTemplate from 'app/client/core/views/templates/newEpicDateControl.html?caveman';
import * as NewEpicStateControlTemplate from 'app/client/core/views/templates/newEpicStateControl.html?caveman';
import { FeatureToggledTeamSelect } from 'components/create-epics/FeatureToggledTeamSelect';
import { NewEpicObjectives } from 'components/create-epics/NewEpicObjectives';
import { TYPE as SELECT_USERS_DROPDOWN_TYPES, SelectUsersDropdown } from 'components/shared/dropdowns/select-users';
import { getTeamByTeamScopedId } from 'data/entity/group';
import { EVENT_TYPES, dispatchEvent } from 'utils/collectionizeToApolloMessageBus';
import { useConsolidatedDataFetched } from 'utils/consolidatedFetch';
import AddNewController from './addNew';
import AutocompleteController from './autocomplete';
import DropdownController from './dropdown';
import LabelAttachmentsController from './labelAttachments';
import MessageController from './message';
import PanelController from './panel';
import InviteUsersController from '../../../settingsShared/js/controllers/inviteUsers';
import * as Event from '../_frontloader/event';
import globals from '../_frontloader/globals';
import EpicModel from '../models/epic';
import EpicStateModel from '../models/epicState';
import MilestoneModel from '../models/milestone';
import ProfileModel from '../models/profile';
import UserModel from '../models/user';
import Autosize from '../modules/autosize';
import Format from '../modules/format';
import Is from '../modules/is';
import LocalStorage from '../modules/localStorage';
import Utils from '../modules/utils';
import Validator from '../modules/validator';
import View from '../modules/view';
import { simpleCompleteTask } from 'utils/quickstart';
import { NewEntityDescriptionEditor } from 'components/shared/NewEntityDescriptionEditor';
import { isObjectivesEnabledForCurrentOrg } from 'data/entity/organization';
import _ from 'lodash';
import moment from 'moment';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};
const PANEL_ID = 'addNewEpic';
exports.LOCALSTORAGE_KEY = 'newEpicState';
exports.saving = false;
exports.onSave = _.noop;
exports.newTemplate = () => {
  const epicStateID = EpicStateModel.getDefaultStateID();
  const epic = {
    name: '',
    description: '',
    epic_state_id: epicStateID,
    planned_start_date: null,
    deadline: null,
    objective_ids: [],
    group_ids: [],
    owner_ids: [],
    follower_ids: []
  };
  Utils.attachFormattedDeadlines(epic);
  Utils.attachFormattedPlannedStartDate(epic);
  return epic;
};
function _migrateState(state) {
  if (state.state) {
    state.epic_state_id = _.get(EpicStateModel.get({
      lowercase_name: state.state
    }), 'id') || EpicStateModel.getDefaultStateID();
    delete state.state;
  }
  if (state.group_id) {
    const unique = new Set(state.group_ids || []);
    unique.add(state.group_id);
    state.group_ids = [...unique];
    delete state.group_id;
  }
  if (state.milestone_id) {
    const unique = new Set(state.objective_ids || []);
    unique.add(state.milestone_id);
    state.objective_ids = [...unique];
    delete state.milestone_id;
  }
}
exports.openLabelDropdown = function () {
  return LabelAttachmentsController.openDropdown.call(this, exports.updateState, exports.getState().labels);
};
exports.removeLabel = function () {
  return LabelAttachmentsController.remove.call(this, exports.updateState, exports.getState().labels);
};
exports.getState = () => AddNewController.getState(exports.LOCALSTORAGE_KEY) || exports.newTemplate();
const _handleDeletedData = state => {
  if (state.epic_state_id) {
    const epicState = EpicStateModel.getById(state.epic_state_id);
    if (!epicState) state.epic_state_id = EpicStateModel.getDefaultStateID();
  }
};
exports.updateState = updates => {
  const state = exports.getState();
  _.assignIn(state, updates);
  Utils.attachFormattedPlannedStartDate(state);
  Utils.attachFormattedDeadlines(state);
  _migrateState(state);
  _handleDeletedData(state);
  LocalStorage.set(exports.LOCALSTORAGE_KEY, state, {
    prefix: true
  });
  Utils.autoTabIndex($('.add-new-epic'));
};
exports.resetState = () => {
  LocalStorage.set(exports.LOCALSTORAGE_KEY, exports.newTemplate(), {
    prefix: true
  });
};
exports.renderOwnerDropdown = () => {
  const props = {
    title: 'Select Epic Owners',
    type: SELECT_USERS_DROPDOWN_TYPES.OWNER,
    initialSelectedUserIds: exports.getState().owner_ids,
    users: ProfileModel.getAllActiveProfileDetails(),
    currentUser: ProfileModel.getCurrentUserProfileDetails(),
    onSelect: (values, id) => {
      exports.updateState({
        owner_ids: values
      });
      const profile = ProfileModel.getAllDetailsById(id);
      exports.addUserAsFollower(exports.getState(), profile);
      exports.renderOwnerDropdown();
    },
    groupIds: exports.getState().group_ids
  };
  return View.renderComponentDelayed({
    componentKey: 'addNewEpicOwnerDropdown',
    cssClass: 'addNewEpicOwnerDropdown',
    component: _jsx(SelectUsersDropdown, {
      ...props
    })
  }).html;
};
exports.renderFromContent = ({
  name,
  description,
  onSave
}) => {
  exports.resetState();
  exports.updateState({
    name,
    description
  });
  if (onSave) {
    exports.onSave = onSave;
  }
  exports.render();
};
const getInitialGroupIdValue = ({
  group_ids
}) => {
  const team = getTeamByTeamScopedId();
  return {
    group_ids: (team?.id ? [team?.id] : group_ids) || []
  };
};
const getDefaults = state => {
  return {
    ...state,
    ...getInitialGroupIdValue(state)
  };
};
const renderDescriptionEditorV2 = panel => {
  if (!panel.element) return;
  View.renderComponent({
    componentKey: 'addNewEpicReactMarkdownEditor',
    mountNode: panel.element.find('#epic-description-v2')[0],
    component: NewEntityDescriptionEditor,
    props: {
      // The renderId forces a re-render of the editor with clean state.
      // `initialValue` doesn't work because it is usually an empty string (ie no change is triggered)
      renderId: String(Date.now()),
      initialValue: exports.getState()?.description || '',
      onChange: val => {
        exports.updateState({
          description: val
        });
      },
      onSave: async val => {
        exports.updateState({
          description: val
        });
        exports.save();
      }
    }
  });
};
exports.render = () => {
  if (exports.isOpen || Is.readOnly(UserModel.getLoggedInUserPermission())) {
    return false;
  }
  const panelOptions = {
    id: PANEL_ID,
    target: document.getElementById('add-new-button'),
    onOpen: panel => {
      if (panel && panel.element) {
        panel.element.find('textarea').first().focus();
        renderDescriptionEditorV2(panel);
      }
    }
  };

  // The user may have the Create Story menu open at this point.
  DropdownController.closeAll();
  const loading = !globals.get(CONSOLIDATED_DATA_FETCHED_KEY);
  if (loading) {
    PanelController.open({
      html: AddNewEpicTemplate.render({
        epic: {},
        loading
      }),
      ...panelOptions
    });
    return false;
  }
  const state = getDefaults(exports.getState());
  exports.updateState(state);
  exports.isOpen = true;
  const panel = PanelController.open({
    html: AddNewEpicTemplate.render({
      epic: state,
      loading: false
    }),
    onClose: () => {
      if (panel && panel.element) {
        const textarea = panel.element.find('textarea');
        textarea.off('keyup blur');
        Autosize.destroy(textarea);
      }
      Validator.destroy('.add-new-dialog');
      Event.off('resize.AddNewEpic');
      exports.isOpen = false;
      View.unmountDetachedComponents();
    },
    onScroll: Is.mobile() ? () => {
      return false;
    } : null,
    ...panelOptions
  });
  if (!panel) {
    return false;
  }
  Validator.init('.add-new-dialog');
  panel.element.find('.title-container textarea').focus().select();
  const textareas = panel.element.find('textarea');
  Autosize.bind(textareas);
  textareas.on('keyup blur', _.debounce(function () {
    if (!panel || !panel.element) {
      return;
    }
    const key = $(this).data('binding');
    const update = {
      [key]: $(this).val().trim()
    };
    exports.updateState(update);
  }, 50));
  renderGroupDropdown();
  renderObjectivesDropdown();
  View.unmountDetachedComponents();
  return false;
};
const renderGroupDropdown = () => {
  const state = exports.getState();
  View.renderComponent({
    mountNode: document.querySelector('#epicGroupDropdown'),
    componentKey: 'addNewEpicGroupDropdown',
    component: _jsx(FeatureToggledTeamSelect, {
      selectedTeams: state.group_ids || (state.group_id ? [state.group_id] : []),
      onChange: groups => {
        exports.updateState({
          group_ids: groups
        });
        renderGroupDropdown();
        exports.renderOwnerDropdown();
      }
    })
  });
};
const renderObjectivesDropdown = () => {
  const state = exports.getState();
  const usesObjectives = isObjectivesEnabledForCurrentOrg();
  if (usesObjectives) {
    View.renderComponent({
      mountNode: document.querySelector('#epicObjectiveDropdown'),
      componentKey: 'addNewEpicObjectiveDropdown',
      component: _jsx(NewEpicObjectives, {
        selectedObjectives: state.objective_ids || (state.milestone_id ? [state.milestone_id] : []),
        onChange: values => {
          exports.updateState({
            objective_ids: values
          });
          renderObjectivesDropdown();
        }
      })
    });
  }
};
exports.isOpen = false;
exports.onKeyDown = function (e) {
  if (Utils.keyPressed(e, ['CMD+ENTER', 'CTRL+ENTER'])) {
    // Needed to prevent ENTER keypress from bubbling.
    $(this).prop('readonly', true);
    setTimeout(exports.save, 50);
    return false;
  }
};
exports.onKeyDownTitle = function (e) {
  if (Utils.keyPressed(e, ['ENTER', 'CMD+ENTER', 'CTRL+ENTER'])) {
    // Needed to prevent ENTER keypress from bubbling.
    $(this).prop('readonly', true);
    setTimeout(exports.save, 50);
    return false;
  }
};
exports.openEpicStateUpdater = function () {
  exports.epicStateControl.call(this, exports.getState(), (epicState = {}) => {
    if (epicState.id) {
      exports.updateState({
        epic_state_id: epicState.id
      });
      $('#new-epic-state').html(NewEpicStateControlTemplate.render(epicState));
    }
  });
  return false;
};
exports.epicStateControl = function (epic, callback) {
  callback = _.isFunction(callback) ? callback : _.noop;
  DropdownController.open({
    items: EpicStateModel.getItemsForAutocomplete(epic.epic_state_id),
    title: 'Update Epic State',
    target: this,
    topOffset: 4,
    onApply: epicState => {
      callback(epicState);
    },
    width: 240
  });
};
exports.openDatepicker = function () {
  const context = $(this);
  const epic = exports.getState();
  const fieldName = Utils.data($(context).closest('[data-field-name]'), 'field-name');
  const dropdown = DropdownController.open({
    footer: '<div class="datepicker unselectable"></div>',
    beforeOutsideClick: DropdownController.isClickOnDatepicker,
    onClose: () => {
      Utils.destroyDatepicker(dropdown.dropdownElement.find('.datepicker'));
    },
    target: context,
    width: Is.mobile() ? 229 : 410
  });
  if (!dropdown) {
    return false;
  }
  dropdown.dropdownElement.find('.datepicker').datepicker({
    defaultDate: epic[fieldName] ? new Date(epic[fieldName]) : new Date(),
    nextText: 'Next',
    prevText: 'Previous',
    numberOfMonths: Is.mobile() ? 1 : 2,
    showAnim: '',
    onSelect: dateText => {
      const newDate = moment(dateText).format();
      exports.updateState({
        [fieldName]: newDate
      });
      $(`.new-epic-date[data-field-name="${fieldName}"]`).html(NewEpicDateControlTemplate.render({
        fieldName,
        epic: exports.getState()
      }));
      DropdownController.close(dropdown);
      Utils.deferFocus(context);
    }
  });
  DropdownController.bindToClicksOutsideDropdown(dropdown);
  return false;
};
exports.clearDate = function () {
  const fieldName = Utils.data($(this).closest('[data-field-name]'), 'field-name');
  exports.updateState({
    [fieldName]: null
  });
  $(`.new-epic-date[data-field-name="${fieldName}"]`).html(NewEpicDateControlTemplate.render({
    fieldName,
    epic: exports.getState()
  }));
  return false;
};
exports.openEpicFollowerDropdown = function () {
  const container = $('#new-epic-followers');
  const state = exports.getState();
  const inviteUsers = InviteUsersController.getInviteUsersFooter();
  const followers = ProfileModel.mapIDsToProfileDetails(state.follower_ids);
  return AutocompleteController.open({
    items: ProfileModel.getItemsForFollowerAutocomplete({
      ...state,
      followers
    }),
    multiSelect: true,
    footer: EpicFollowerFooterTemplate.render({
      inviteUsers
    }),
    onApply: profile => {
      // Guard against non-matching input
      if (profile && !ProfileModel.isValid(profile)) {
        return false;
      }
      state.follower_ids = state.follower_ids || [];
      if (!profile) {
        state.follower_ids = [];
      } else if (_.includes(state.follower_ids, profile.id)) {
        _.pull(state.follower_ids, profile.id);
      } else {
        state.follower_ids.push(profile.id);
      }
      exports.updateState({
        follower_ids: state.follower_ids
      });
      container.find('.value').html(EpicModel.describeFollowers(state));
      exports.updateFollowerToggleState();
      Utils.deferFocus(container);
    },
    noActive: true,
    title: 'Select Epic Followers',
    onResultsUpdate: InviteUsersController.getAutocompleteWithInviteUpdateHandler,
    showInput: true,
    target: this,
    topOffset: 0,
    width: 240
  });
};
exports.addUserAsFollower = (state, profile) => {
  if (!profile) {
    return;
  }
  state.follower_ids = state.follower_ids || [];
  if (!_.includes(state.follower_ids, profile.id)) {
    state.follower_ids.push(profile.id);
    exports.updateState({
      follower_ids: state.follower_ids
    });
    $('#new-epic-followers .value').html(EpicModel.describeFollowers(state));
    exports.updateFollowerToggleState();
  }
};
exports.removeUserAsFollower = (state, profile) => {
  state.follower_ids = state.follower_ids || [];
  if (_.includes(state.follower_ids, profile.id)) {
    _.pull(state.follower_ids, profile.id);
    exports.updateState({
      follower_ids: state.follower_ids
    });
    $('#new-epic-followers .value').html(EpicModel.describeFollowers(state));
    exports.updateFollowerToggleState();
  }
};
exports.addMeAsFollower = () => {
  const state = exports.getState();
  const profileDetails = ProfileModel.getCurrentUserProfileDetails();
  exports.addUserAsFollower(state, profileDetails);
  return false;
};
exports.removeMeAsFollower = () => {
  const state = exports.getState();
  const profileDetails = ProfileModel.getCurrentUserProfileDetails();
  exports.removeUserAsFollower(state, profileDetails);
  return false;
};
exports.updateFollowerToggleState = () => {
  const state = exports.getState();
  const context = $('#new-epic-followers .attribute-toggle').find('a');
  if (EpicModel.currentUserIsFollower(state)) {
    exports.markToggleAsFollower(context);
  } else {
    exports.markToggleAsNotFollower(context);
  }
};
exports.markToggleAsFollower = context => {
  context.attr('data-on-click', 'removeMeAsFollower');
  context.attr('data-tooltip', 'Remove Yourself as Follower');
  context.find('.fa').removeClass('fa-plus').addClass('fa-times');
};
exports.markToggleAsNotFollower = context => {
  context.attr('data-on-click', 'addMeAsFollower');
  context.attr('data-tooltip', 'Follow this Epic');
  context.find('.fa').removeClass('fa-times').addClass('fa-plus');
};
exports.save = () => {
  if (exports.saving) {
    return;
  }
  if (Validator.isValid('.add-new-dialog')) {
    const epic = exports.getState();
    let milestoneWasDeletedMessage = '';
    exports.saving = true;
    $('.add-new-dialog .add-new-action').html(ButtonSavingStateTemplate.render());

    // Guard against missing Milestone
    const validObjectives = (epic.objective_ids || []).filter(id => MilestoneModel.getById(id));
    if ((epic.objective_ids?.length || 0) !== validObjectives.length) {
      const diff = epic.objective_ids.length - validObjectives.length;
      milestoneWasDeletedMessage = diff === 1 ? ` ${epic.objective_ids.length > 1 ? 'One of the' : 'The'} selected Objective${epic.objective_ids.length !== 1 ? 's' : ''} no longer exists, so it was removed from the new Epic properties.` : `${diff} of the selected Objectives no longer exist, so they were removed from the new Epic properties.`;
    }
    EpicModel.saveNew(epic, (err, res) => {
      if (err) {
        $('.add-new-dialog .add-new-action').html(AddNewEpicActionsTemplate.render());
        MessageController.error(err, {
          secondary: `Unable to create <strong>${Format.sanitize(epic.name)}</strong>.`
        });
      } else {
        const savedEpic = EpicModel.getById(res.id);
        LocalStorage.remove(exports.LOCALSTORAGE_KEY, {
          prefix: true
        });
        PanelController.closeById(PANEL_ID);
        Event.trigger('epicCreated', res);
        simpleCompleteTask('create-epic');
        dispatchEvent(EVENT_TYPES.EPIC_CREATED);
        exports.onSave(savedEpic);
        MessageController.success(`Epic <strong>${Format.sanitize(savedEpic.name)}</strong> created.${milestoneWasDeletedMessage}`, {
          actions: `
              <a href="${savedEpic.url}" class="action mini elevated" data-controller="HotReload" data-on-click="load" data-page="epics">
                <span class="fa fa-eye"></span>
                View epic
              </a> `,
          timeout: 15000
        });
      }
      exports.saving = false;
      exports.onSave = _.noop;
    });
  } else {
    $('.add-new-dialog textarea').prop('readonly', false);
    $('body').focus();
    Validator.focusOnFirstError('.add-new-dialog');
  }
};
const ReRenderWhenLoadingIsComplete = () => {
  const isReady = useConsolidatedDataFetched();
  useEffect(() => {
    if (isReady) {
      exports.render();
    }
  }, [isReady]);
  return _jsx(Loading, {});
};
ReRenderWhenLoadingIsComplete.displayName = "ReRenderWhenLoadingIsComplete";
exports.renderLoading = () => View.renderComponentDelayed({
  componentKey: 'add-new-epic-loading',
  cssClass: 'add-new-epic-loading',
  component: _jsx(ReRenderWhenLoadingIsComplete, {})
}).html;
export { exports as default };