import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import * as ChangeEmailTemplate from 'app/client/settingsShared/views/templates/changeEmail.html?caveman';
import DropdownController from '../../../core/js/controllers/dropdown';
import EmailModel from '../../../core/js/models/email';
import * as Event from '../../../core/js/_frontloader/event';
import FlashController from '../../../core/js/controllers/flash';
import Format from '../../../core/js/modules/format';
import Is from '../../../core/js/modules/is';
import OrganizationModel from '../../../core/js/models/organization';
import MessageController from '../../../core/js/controllers/message';
import Url from '../../../core/js/modules/url';
import User from '../../../core/js/modules/user';
import UserModel from '../../../core/js/models/user';
import Utils from '../../../core/js/modules/utils';
import Validator from '../../../core/js/modules/validator';
import View from '../../../core/js/modules/view';
import SettingsPage from '../../../settings/js/controllers/settingsPage';
import EditProfileController from './editProfile';
import { navigateTo } from '../../../../../utils/navigation';
import { Anchor } from '@clubhouse/shared/components/Anchor';
import _ from 'lodash';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const exports = {};
const PARENT_SELECTOR = '#change-email-form';
const BUTTON_SELECTOR = PARENT_SELECTOR + ' .add-new-email';
exports.title = `Add or update your email addresses | ${BRAND.NAME}`;
exports.route = () => {
  const slug = Url.getSlugPath() || '/organizations';
  return slug + '/settings/account/email';
};
exports.open = () => {
  SettingsPage.loadPane(exports);
};
exports.render = () => {
  const user = User.getCurrentUser();
  const withinOrg = Is.withinOrg();
  const defaultEmail = UserModel.getDefaultEmail();
  const primaryEmail = EmailModel.getCurrentUsersPrimaryEmail();
  const otherEmails = _.reject(user.emails, {
    id: defaultEmail.id
  });
  const isScimEnabled = OrganizationModel.isScimEnabled(OrganizationModel.getCurrent().id);
  const html = ChangeEmailTemplate.render({
    user,
    subnav: withinOrg ? [] : SettingsPage.getSubnav(),
    active: 'changeEmail',
    defaultEmail,
    primaryEmail,
    otherEmails: withinOrg ? _.reject(otherEmails, {
      id: primaryEmail.id
    }) : otherEmails,
    withinOrg,
    isScimEnabled
  });
  $(SettingsPage.PARENT_SELECTOR).html(html);
  Validator.init(PARENT_SELECTOR);
  SettingsPage.onDestroy('ChangeEmail', () => {
    Validator.destroy(PARENT_SELECTOR);
  });
};
exports.addNewEmail = () => {
  const context = $(PARENT_SELECTOR);
  const data = Utils.formData(context);
  data.email_address = data.email_address.trim();
  if (Validator.isValid(context)) {
    View.changeButtonToSaving(BUTTON_SELECTOR);
    EmailModel.addNewEmail(data, err => {
      exports.render();
      if (err) {
        FlashController.error(PARENT_SELECTOR, err);
      } else {
        FlashController.success(PARENT_SELECTOR, 'Email address ' + data.email_address + ' added.', 'Please confirm your email address by clicking the link in the email we just sent you.');
      }
    });
  }
  return false;
};
exports.sendConfirmation = function () {
  const email = Utils.getModelFromContext(this);
  EmailModel.sendConfirmation(email, err => {
    exports.render();
    if (err) {
      FlashController.error(PARENT_SELECTOR, err);
    } else {
      FlashController.success(PARENT_SELECTOR, 'Confirmation sent to ' + email.email_address + '.', 'Please click the link in the email we just sent you.');
    }
  });
  return false;
};
exports.openEmailDropdown = function () {
  const container = $(this).closest('.email-selector');
  const type = Utils.data(container, 'type');
  const user = User.getCurrentUser();
  const currentEmail = Utils.getModelFromContext(container) || {};
  DropdownController.open({
    items: _.map(user.emails, email => {
      return {
        className: currentEmail.id === email.id ? 'active' : '',
        name: Format.sanitize(email.email_address),
        value: email
      };
    }),
    target: this,
    onApply: email => {
      container.append('<span class="saving"><span class="fa fa-star fa-spin-slow"></span> Updating...</span>');
      if (type === 'user') {
        exports.setAsUserPrimary(email);
      } else if (type === 'organization') {
        exports.setAsOrgPrimary(email);
      } else {
        exports.render();
        MessageController.error('An error has occured setting your email, please contact us.');
      }
    },
    width: 220
  });
  return false;
};
exports.setAsOrgPrimary = email => {
  EmailModel.setAsOrgPrimary(email, err => {
    exports._emailUpdateHandler(err, 'Primary email updated to ' + email.email_address + '.', 'Your gravatar has been updated to use this new email address.');
  });
  return false;
};
exports.setAsUserPrimary = email => {
  EmailModel.setAsUserPrimary(email, err => {
    exports._emailUpdateHandler(err, 'Default email updated to ' + email.email_address + '.', 'Your gravatar has been updated to use this new email address.');
  });
  return false;
};
exports._emailUpdateHandler = (err, msg) => {
  exports.render();
  if (err) {
    FlashController.error(PARENT_SELECTOR, err);
  } else {
    Event.trigger('userPrimaryEmailUpdated');
    FlashController.success(PARENT_SELECTOR, msg);
  }
};
exports.deleteEmail = function () {
  const email = Utils.getModelFromContext(this);
  const confirmMessage = 'Are you sure you want to delete ' + email.email_address + ' from your account?';
  if (window.confirm(confirmMessage)) {
    $(this).removeAttr('data-on-click').find('.fa').removeClass('fa-trash-o').addClass('fa-spin fa-star').end().closest('.user-email-row').animate({
      opacity: 0.3
    }, 500);
    EmailModel.deleteEmail(email, err => {
      exports.render();
      if (err) {
        FlashController.error(PARENT_SELECTOR, err);
      } else {
        FlashController.success(PARENT_SELECTOR, 'Email ' + email.email_address + ' deleted.');
      }
    });
  }
  return false;
};
exports.renderPrimaryEmailContent = () => {
  return View.renderComponentDelayed({
    componentKey: 'primary-email-content',
    component: _jsxs("p", {
      children: ["We send system emails to the primary email address you've selected for this organization, and if you haven't selected a", ' ', _jsx(Anchor, {
        onClick: () => {
          navigateTo({
            url: EditProfileController.route()
          });
        },
        children: "Profile Icon"
      }), ", we'll use this email address for your Gravatar."]
    })
  }).html;
};
export { exports as default };