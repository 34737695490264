import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import * as InlineUserMentionTooltipTemplate from 'app/client/core/views/templates/inlineUserMentionTooltip.html?caveman';
import * as SearchByDateTooltipTemplate from 'app/client/core/views/templates/storySearch/searchByDateTooltip.html?caveman';
import { GroupMentionTooltip } from 'components/shared/GroupMentionTooltip';
import Format from '../modules/format';
import ProfileModel from '../models/profile';
import GroupModel from '../models/group';
import Utils from '../modules/utils';
import View from '../modules/view';
import * as Event from '../_frontloader/event';
import _ from 'lodash';
const exports = {};
exports.renderUserTooltip = function () {
  const profile = Utils.getModelFromContext(this);
  return InlineUserMentionTooltipTemplate.render({
    profile: ProfileModel.getAllDetailsById(profile.id)
  });
};
let tooltipUnmountFunctions;
const resetTooltipUnmountFunctions = () => {
  tooltipUnmountFunctions = new Map();
};
exports.renderGroupTooltip = target => {
  if (!tooltipUnmountFunctions) {
    resetTooltipUnmountFunctions();
    Event.on('storyElementTearDown.renderGroupTooltip', () => {
      tooltipUnmountFunctions.forEach(unmountFn => {
        unmountFn();
      });
      resetTooltipUnmountFunctions();
    });
  }
  const mountNode = target.parentNode;
  const id = Utils.data(mountNode, 'id');
  const kind = Utils.data(target, 'kind');
  mountNode.innerHTML = '';
  View.renderComponent({
    mountNode,
    component: GroupMentionTooltip,
    getProps: ({
      unmountComponent
    }) => {
      tooltipUnmountFunctions.set(mountNode, unmountComponent);
      return {
        kind,
        group: GroupModel.getById(id)
      };
    }
  });
};
exports.renderNotFoundMessage = function () {
  const id = Utils.data(this, 'id');
  const type = Utils.data(this, 'type');
  return `No ${type} found with the id <em>${id}</em>.`;
};
exports.renderStoryOwnerTooltip = function () {
  const profile = Utils.getModelFromContext(this);
  return 'Owner: ' + Format.sanitize(_.get(ProfileModel.getAllDetailsById(profile.id), 'name'));
};
exports.renderSearchByDateTooltip = () => {
  return SearchByDateTooltipTemplate.render();
};
export { exports as default };