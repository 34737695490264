import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import * as SsoLoginFormTemplate from 'app/client/login/views/templates/ssoLoginForm.html?caveman';
import * as Event from '../../../core/js/_frontloader/event';
import LoginController from './login';
import Utils from '../../../core/js/modules/utils';
import Validator from '../../../core/js/modules/validator';
import { setPageTitle } from 'utils/page';
const exports = {};
const PARENT_SELECTOR = '#sso-login-form';
const SAMPLE_ORG_SLUG = 'myorg';
exports.init = () => {
  setPageTitle(`Log in with SSO | ${BRAND.NAME}`);
  exports.renderLoginForm();
};
exports.renderLoginForm = () => {
  const html = SsoLoginFormTemplate.render({
    sampleOrgSlug: SAMPLE_ORG_SLUG
  });
  $('#form-container').html(html);
  Utils.fadePageIn();
  $('#login-slug').focus();
  Event.trigger('resize');
  Event.trigger('pageRendered', 'SSOLogin');
};
exports.login = () => {
  const context = $(PARENT_SELECTOR);
  const button = context.find('button.action');
  const {
    workspace_slug
  } = Utils.formData(context);
  if (Validator.isValid(context)) {
    LoginController.disableButton(button);
    LoginController.trySSOLogin(workspace_slug, err => {
      const message = err.message || 'We were unable to login with SSO for this Workspace slug.';
      LoginController.drawSSOLoginError(button, workspace_slug, message);
    });
  } else {
    Validator.focusOnFirstError(context);
  }
  return false;
};
export { exports as default };