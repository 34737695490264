import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.every.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.flat-map.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import Async from '../modules/async';
import BaseUtils from '../_frontloader/baseUtils';
import CategoryModel from './category';
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import Constants from '../modules/constants';
import EpicModel from './epic';
import FasterMoment from '../modules/fasterMoment';
import Format from '../modules/format';
import Iterate from '../modules/iterate';
import StoryModel from './story';
import Router from '../_frontloader/router';
import Url from '../modules/url';
import Utils from '../modules/utils';
import WebhookModel from './webhook';
import { StatusIcon } from '@clubhouse/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { renderComponentToString } from 'utils/helpers';
import { MilestoneState } from '@clubhouse/shared/types';
import _ from 'lodash';
import moment from 'moment';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {
  Promises: {}
};

/*

Example Milestone:

{
  completed_at: "2017-04-01T04:00:00Z"
  created_at: "2016-10-17T14:23:13Z"
  description: ""
  id: 12627
  name: "Q1 2017"
  categories: []
  position: 33024
  started_at: "2017-01-01T05:00:00Z"
  state: "to do"
  updated_at: "2016-10-29T13:01:29Z"
}

*/

Collection.create('Milestone', exports);
Router.addPageTitle('milestone', url => {
  const path = Url.parsePathSkipCache(url);
  const milestone = exports.getById(path.milestone);
  const fallback = `Milestones | ${BRAND.NAME}`;
  return milestone ? milestone.name + ' | ' + fallback : fallback;
});
const createStates = () => {
  const states = {
    'to do': {
      name: 'To Do',
      icon: renderComponentToString(_jsx(DeprecatedIconAdapter, {
        width: 16,
        children: _jsx(StatusIcon, {
          icon: "Unstarted"
        })
      })),
      value: 'to do'
    },
    'in progress': {
      name: 'In Progress',
      icon: renderComponentToString(_jsx(DeprecatedIconAdapter, {
        width: 16,
        children: _jsx(StatusIcon, {
          icon: "Started"
        })
      })),
      value: 'in progress'
    },
    done: {
      name: 'Done',
      icon: renderComponentToString(_jsx(DeprecatedIconAdapter, {
        width: 16,
        children: _jsx(StatusIcon, {
          icon: "Done"
        })
      })),
      value: 'done'
    }
  };

  // Handle difference between GQL and collectionize.
  states.toDo = states['to do'];
  states.inProgress = states['in progress'];
  return states;
};
let STATES = null;
exports.getStates = () => {
  if (!STATES) STATES = createStates();
  return STATES;
};
exports.NATURAL_SORT_ORDER = {
  'to do': 1,
  'in progress': 2,
  done: 3
};
exports.buildUrl = (milestone, includeDomain = false) => (includeDomain ? Url.getCurrentOrigin() : '') + Url.getSlugPath() + '/milestone/' + milestone.id + '/' + Utils.slugify(milestone.name, {
  limit: 120
});
exports.normalize = milestone => {
  milestone.formatted_name = Format.sanitizeAndEmojify(milestone.name);
  milestone.url = exports.buildUrl(milestone);
  milestone.type = milestone.key_result_ids?.length ? 'strategic' : 'tactical';
  milestone.completed_at_calculated = milestone.completed_at_override || milestone.completed_at;
  milestone.started_at_calculated = milestone.started_at_override || milestone.started_at;
  milestone.started_at_timestamp = FasterMoment.valueOf(milestone.started_at_calculated);
  const stateObj = exports.getStates()[milestone.state];
  milestone.state_icon = stateObj.icon;
  milestone.state_name = stateObj.name;
  exports._recalculateEpicsForMilestone(milestone);
  milestone.totalStories = milestone.num_stories_backlog + milestone.num_stories_unstarted + milestone.num_stories_started + milestone.num_stories_done;
  milestone.totalPoints = milestone.num_points_backlog + milestone.num_points_done + milestone.num_points_started + milestone.num_points_unstarted;
  return milestone;
};
exports.normalizeDescription = milestone => {
  milestone.formatted_description = milestone.description ? Format.markdownify(milestone.description, 'milestone-description') : '<p class="ghost"><em>No description given.</em></p>';
};
exports.normalizeAll = () => {
  exports.each(milestone => {
    exports.normalize(milestone);
  });
};
exports.getFromUrl = url => {
  const path = Url.parsePath(url);
  return exports.getById(path.milestone);
};
exports.isDone = milestone => {
  return milestone.state === 'done';
};
exports.isInProgress = milestone => {
  return milestone.state === 'in progress';
};
exports.recalculateEpicsForMilestones = () => {
  const epicIdsByMilestone = EpicModel.all().reduce((acc, epic) => {
    if (!epic.archived && epic.objective_ids?.length) {
      epic.objective_ids.forEach(milestoneId => {
        if (!acc[milestoneId]) acc[milestoneId] = [];
        acc[milestoneId].push(epic.id);
      });
    }
    return acc;
  }, {});
  exports.each(milestone => {
    exports._recalculateEpicsForMilestone(milestone, epicIdsByMilestone[milestone.id] ?? []);
  });
};
exports._getEpicIDsForMilestone = milestone => {
  const epicsInMilestone = [];
  const allEpics = EpicModel.all();
  for (const epic of allEpics) if (!epic.archived && epic.objective_ids && epic.objective_ids.includes(milestone.id)) epicsInMilestone.push(epic.id);
  return epicsInMilestone;
};
exports._recalculateEpicsForMilestone = (milestone, epicIds, noBackReference) => {
  milestone.epics = [];
  milestone.epic_ids = epicIds || exports._getEpicIDsForMilestone(milestone);
  milestone.num_points = 0;
  milestone.num_points_backlog = 0;
  milestone.num_points_unstarted = 0;
  milestone.num_points_started = 0;
  milestone.num_points_done = 0;
  milestone.num_stories_unestimated = 0;
  milestone.num_stories_backlog = 0;
  milestone.num_stories_unstarted = 0;
  milestone.num_stories_started = 0;
  milestone.num_stories_done = 0;
  milestone.num_stories_total = 0;
  Iterate.each(milestone.epic_ids, epic_id => {
    const epic = EpicModel.getById(epic_id);
    if (epic) {
      if (!noBackReference) {
        // Avoiding an Epic 'updated' event here because it will cause a render on the Stories page
        epic.milestone = milestone;
        if (!epic.objective_ids) epic.objectives = [];
        if (epic.objectives.every(obj => obj.id !== milestone.id)) epic.objectives.push(milestone);
      }
      milestone.epics.push(epic);
      milestone.num_points += epic.num_points;
      milestone.num_points_backlog += epic.num_points_backlog;
      milestone.num_points_unstarted += epic.num_points_unstarted;
      milestone.num_points_started += epic.num_points_started;
      milestone.num_points_done += epic.num_points_done;
      milestone.num_stories_unestimated += epic.num_stories_unestimated;
      milestone.num_stories_backlog += epic.num_stories_backlog;
      milestone.num_stories_unstarted += epic.num_stories_unstarted;
      milestone.num_stories_started += epic.num_stories_started;
      milestone.num_stories_done += epic.num_stories_done;
      milestone.num_stories_total += epic.totalStories;
      if (!milestone.last_story_update || FasterMoment.isMoreRecentThan(milestone.last_story_update, epic.last_story_update)) {
        milestone.last_story_update = epic.last_story_update;
      }
    }
  });
  EpicModel.sortByPosition(milestone.epics);
  milestone.epic_ids = _.map(milestone.epics, 'id');
  milestone.project_ids = _.uniq(_.flatMap(milestone.epics, 'project_ids'));
  milestone.percent_completed = milestone.num_stories_done / milestone.num_stories_total * 100 || 0;
  milestone.percent_active = milestone.num_stories_started / milestone.num_stories_total * 100 || 0;
  milestone.percent_unstarted = milestone.num_stories_unstarted / milestone.num_stories_total * 100 || 0;
  milestone.percent_backlog = milestone.num_stories_backlog / milestone.num_stories_total * 100 || 0;
  milestone.percent_completed_points = milestone.num_points_done / milestone.num_points * 100 || 0;
  milestone.percent_active_points = milestone.num_points_started / milestone.num_points * 100 || 0;
  milestone.percent_unstarted_points = milestone.num_points_unstarted / milestone.num_points * 100 || 0;
  milestone.percent_backlog_points = milestone.num_points_backlog / milestone.num_points * 100 || 0;
};
exports.getFilteredEpicCalculations = (milestone, epicIds) => {
  const copy = _.cloneDeep(milestone);
  exports._recalculateEpicsForMilestone(copy, epicIds, true);
  return copy;
};
exports.fetchCycleTimeStoriesForMilestone = (milestone, callback) => {
  const query = {
    epic_ids: milestone.epic_ids,
    archived: false,
    workflow_state_types: ['done']
  };
  StoryModel.searchStories(query, () => {
    const stories = exports.getStoriesForMilestone(milestone);
    callback(stories);
  });
};
exports.getStoriesForMilestone = milestone => {
  milestone.stories = [];
  Iterate.each(milestone.epics, epic => {
    const stories = EpicModel.getStories(epic);
    milestone.stories = milestone.stories.concat(stories);
  });
  milestone.num_features = _.filter(milestone.stories, {
    story_type: 'feature'
  }).length;
  milestone.num_chores = _.filter(milestone.stories, {
    story_type: 'chore'
  }).length;
  milestone.num_bugs = _.filter(milestone.stories, {
    story_type: 'bug'
  }).length;
  return milestone.stories;
};
exports.getEpicLabels = milestone => {
  return _.uniq(_.flatMap(milestone.epics, 'labels'));
};
exports.getEpicGroupIds = milestone => {
  return _.uniq(_.map(milestone.epics, 'group_id'));
};
exports.getEpicTeamsOrAssociatedTeamsIds = milestone => {
  const epics = milestone.epics;
  const associatedGroups = _.uniq(_.flatMap(epics, 'associated_groups')).filter(group => group);
  const associatedGroupIds = _.uniq(associatedGroups.map(({
    group_id
  }) => group_id));
  return associatedGroupIds;
};
exports.getCalendarLink = milestone => {
  return WebhookModel.getCalendarURL() + '/calendars/milestones/' + milestone.id;
};
exports.fetchAll = callback => {
  Backend.get('/api/private/objectives', {
    onComplete: res => {
      exports.defaultFetchAllHandler(res, callback);
    }
  });
};
exports.fetch = (milestone, callback) => {
  Backend.get('/api/private/objectives/' + milestone.id, {
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.createMilestone = (data, callback) => {
  Backend.post('/api/private/objectives', {
    data,
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'milestoneCreated');
    }
  });
};
exports.updateMilestone = (milestone, updates, callback) => {
  Backend.put('/api/private/objectives/' + milestone.id, {
    data: updates,
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.archiveMilestone = (milestone, callback) => {
  exports.updateMilestone(milestone, {
    archived: true
  }, callback);
};
exports.unarchiveMilestone = (milestone, callback) => {
  exports.updateMilestone(milestone, {
    archived: false
  }, callback);
};
export const updateMilestonePromise = BaseUtils.promisify(exports.updateMilestone);
exports.deleteMilestone = (milestone, callback) => {
  Backend.delete('/api/private/objectives/' + milestone.id, {
    onComplete: (res, xhr) => {
      exports.defaultDeleteHandler(milestone, res, xhr, callback);
    }
  });
};
exports.moveMilestoneAndEpics = function (milestone, state, callback) {
  const updates = _.map(exports._getEpicIDsForMilestone(milestone), function (id) {
    return EpicModel.saveChanges.bind(this, {
      id
    }, {
      state
    });
  });
  updates.unshift(exports.updateMilestone.bind(this, milestone, {
    state
  }));
  Async.eachInParallelThenWithShortCircuiting(updates, callback);
};
exports.removeCategory = (milestone, category, callback) => {
  exports.saveCategories(milestone, _.reject(_.get(milestone, 'categories'), {
    name: _.get(category, 'name')
  }), callback);
};
exports.saveCategories = (milestone, categories, callback) => {
  const updates = {
    categories: CategoryModel.denormalizeMultiple(categories)
  };
  exports.updateMilestone(milestone, updates, () => {
    CategoryModel.fetchAll(callback);
  });
};
exports.hasCategory = (milestone, category) => {
  return _.find(milestone.categories, {
    id: category.id
  });
};
exports.getAllWithCategory = category => {
  return exports.filter(milestone => {
    return exports.hasCategory(milestone, category);
  });
};
exports.getOrphanEpics = () => {
  return _.sortBy(EpicModel.filter(epic => {
    return !EpicModel.isDone(epic) && !EpicModel.isArchived(epic) && !epic.milestone_id;
  }), 'position');
};
exports.getColumnKey = (data, name) => {
  let key;
  Iterate.each(data, (row, i) => {
    if (row[0] === name) {
      key = i;
      return false;
    }
  });
  return key;
};
exports.formatCalculatedDate = (milestone, prop) => {
  if (milestone[prop + '_calculated']) {
    return moment(milestone[prop + '_calculated']).format(Constants.SHORT_DATE_FORMAT);
  }
  return '';
};
const getMilestoneIconName = state => {
  let iconName = 'Unstarted';
  if (MilestoneState.InProgress === state.toLowerCase()) {
    iconName = 'Started';
  } else if (MilestoneState.Done === state.toLowerCase()) {
    iconName = 'Done';
  }
  return iconName;
};
exports.getItemsForAutocomplete = options => {
  options = options || {};
  const milestones = exports.filter({
    archived: false
  });
  const states = ['In Progress', 'To Do', 'Done'];
  const items = [];
  if (milestones.length > 0) {
    Iterate.each(states, state => {
      if (items.length > 0) {
        items.push({
          hr: true
        });
      }
      const selectAllButton = _.isFunction(options.selectAllFn) ? options.selectAllFn(state.toLowerCase()) : '';
      items.push({
        html: '<div class="caption">' + state + ' ' + selectAllButton + '</div>'
      });
      Iterate.each(_.filter(milestones, {
        state: state.toLowerCase()
      }), milestone => {
        items.push(options.mapFn ? options.mapFn(milestone) : {
          customIconLeft: renderComponentToString(_jsx(DeprecatedIconAdapter, {
            width: 14,
            children: _jsx(StatusIcon, {
              icon: getMilestoneIconName(state)
            })
          })),
          name: Format.sanitizeAndEmojify(milestone.name),
          value: options.valueFn ? () => {
            options.valueFn(milestone);
          } : milestone.id
        });
      });
    });
  }
  return items;
};
exports.Promises.updateMilestone = BaseUtils.promisify(exports.updateMilestone);
exports.Promises.moveMilestoneAndEpics = BaseUtils.promisify(exports.moveMilestoneAndEpics);
exports.Promises.fetchAll = BaseUtils.promisify(exports.fetchAll);
exports.Promises.fetch = BaseUtils.promisify(exports.fetch);
export { exports as default };