import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import * as ManageLoadingIconsTemplate from 'app/client/settingsShared/views/templates/manageLoadingIcons.html?caveman';
import * as LoadingIconsFormTemplate from 'app/client/settingsShared/views/templates/loadingIconsForm.html?caveman';
import * as LoadingIconPolaroidTemplate from 'app/client/settingsShared/views/templates/loadingIconPolaroid.html?caveman';
import FlashController from '../../../core/js/controllers/flash';
import IconModel from '../../../core/js/models/icon';
import InPlaceEditorController from '../../../core/js/controllers/inPlaceEditor';
import Is from '../../../core/js/modules/is';
import MessageController from '../../../core/js/controllers/message';
import OrganizationModel from '../../../core/js/models/organization';
import Url from '../../../core/js/modules/url';
import UserModel from '../../../core/js/models/user';
import Utils from '../../../core/js/modules/utils';
import View from '../../../core/js/modules/view';
import SettingsPage from '../../../settings/js/controllers/settingsPage';
import { AskAnOwnerAlert } from 'components/settings/AskAnOwnerAlert';
const exports = {};
const PARENT_SELECTOR = '#manage-loading-icons';
exports.title = `Manage your Loading Icons | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/settings/loading-icons';
};
exports.open = () => {
  SettingsPage.loadPane(exports);
};
exports.render = () => {
  // Only owners, admins and members can update loading icons.

  View.renderWithComponents({
    template: ManageLoadingIconsTemplate,
    templateMountNode: document.querySelector(SettingsPage.PARENT_SELECTOR),
    templateData: {
      active: 'manageLoadingIcons',
      me: UserModel.getLoggedInUserPermission(),
      organization: OrganizationModel.getCurrent(),
      readOnly: Is.readOnly(UserModel.getLoggedInUserPermission())
    },
    components: {
      readOnlyMessage: {
        component: AskAnOwnerAlert,
        props: {
          description: 'Only owners, admins and members can update loading icons.'
        }
      }
    }
  });
  OrganizationModel.fetchCurrent(err => {
    if (Url.isCurrentPath(exports.route()) && !err) {
      const html = LoadingIconsFormTemplate.render({
        files: IconModel.getLoadingIcons()
      });
      View.replaceElement('.waiting-for-content', html);
    }
  });
  SettingsPage.onDestroy('ManageLoadingIcons', () => {
    View.unmountComponents({
      templateMountNode: document.querySelector(SettingsPage.PARENT_SELECTOR)
    });
  });
};
exports.openChooser = () => {
  $(PARENT_SELECTOR).find('.html-file-upload').click();
  return false;
};
exports.save = files => {
  IconModel.saveLoadingIcon(files, (err, res) => {
    if (!err) {
      exports.render();
    } else {
      FlashController.error(PARENT_SELECTOR, 'We were unable to save your loading icon.', res);
    }
  });
  return false;
};
exports.delete = function () {
  const icon = Utils.getModelFromContext(this);
  exports.updatePendingState(icon);
  IconModel.deleteLoadingIcon(icon, err => {
    if (!err) {
      if (IconModel.getLoadingIcons().length) {
        exports.getLoadingIconElement(icon).remove();
      } else {
        exports.render();
      }
    } else {
      exports.revertPendingState(icon);
      MessageController.error(err, {
        secondary: 'Unable to delete loading icon.'
      });
    }
  });
  return false;
};
exports.update = (icon, data, callback) => {
  exports.updatePendingState(icon);
  IconModel.save(icon, data, (err, res) => {
    if (!err) {
      exports.getLoadingIconElement(icon).html(LoadingIconPolaroidTemplate.render(res));
    }
    exports.revertPendingState(icon);
    callback(err, res);
  });
};
exports.editMessage = function () {
  if (Utils.dragDistance() < 10) {
    InPlaceEditorController.open.call(this);
  }
};
exports.cancelMessage = function () {
  const editorElement = $(this).closest('.in-place-editor');
  InPlaceEditorController.destroy(editorElement);
  return false;
};
exports.updateMessage = function () {
  const icon = Utils.getModelFromContext(this);
  const editorElement = $(this).closest('.in-place-editor');
  const message = editorElement.find('input').val().trim();
  if (icon.message !== message) {
    exports.update(icon, {
      message
    }, err => {
      if (err) {
        MessageController.error(err, {
          secondary: 'Unable to update message.'
        });
      }
    });
  }
  InPlaceEditorController.destroy(editorElement);
  return false;
};
exports.toggleSpin = function () {
  const icon = Utils.getModelFromContext(this);
  exports.update(icon, {
    spin: !icon.spin
  }, err => {
    if (err) {
      MessageController.error(err, {
        secondary: 'Unable to toggle spinning.'
      });
    }
  });
  return false;
};
exports.contextMenuItems = function () {
  const icon = Utils.getModelFromContext(this);
  const spinning = icon.spin;
  return [{
    iconLeft: 'fa-circle-o-notch' + (!spinning ? ' blue' : ''),
    tooltip: spinning ? 'Stop spinning icon' : 'Spin icon',
    value: target => exports.toggleSpin.call(target)
  }, {
    iconLeft: 'fa-trash',
    tooltip: 'Delete icon',
    value: target => exports.delete.call(target)
  }];
};
exports.getLoadingIconElement = icon => {
  return $('#loading-icon-' + icon.id);
};
exports.updatePendingState = icon => {
  exports.getLoadingIconElement(icon).css({
    opacity: 0.5
  });
};
exports.revertPendingState = icon => {
  exports.getLoadingIconElement(icon).css({
    opacity: 1
  });
};
export { exports as default };