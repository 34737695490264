import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import BaseUtils from '../_frontloader/baseUtils';
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import * as CustomFieldUtils from '@clubhouse/shared/utils/customFields';
const exports = {};

/*

Custom Field model:

{
  id: 1,
  name: 'Priority',
  canonical_name: 'priority',
  type: 'enum',
  description: 'Used to filter and sort stories in order of priority.',
  enabled: true,
  icon: 'priority',
  values: [{
      id: '2',
      value: 'High',
      colorKey: 'red',
      position: 1
  }]
}

*/

export const CUSTOM_FIELD_SEARCH_OPERATORS = {
  'technical-area': {
    operatorKey: 'technical-area',
    helperText: 'Technical Area values'
  },
  'skill-set': {
    operatorKey: 'skill-set',
    helperText: 'Skill Set values'
  },
  'product-area': {
    operatorKey: 'product-area',
    helperText: 'Product Area values'
  },
  priority: {
    operatorKey: 'priority',
    helperText: 'Priority values'
  },
  severity: {
    operatorKey: 'severity',
    helperText: 'Severity values'
  }
};
Collection.create('CustomField', exports);
exports.fetchAll = callback => {
  Backend.get('/api/private/custom-fields', {
    onComplete: res => {
      exports.defaultFetchAllHandler(res, callback);
    }
  });
};
exports.put = (id, data, callback) => {
  const cleanData = cleanupData(data);
  Backend.put(`/api/private/custom-fields/${id}`, {
    data: cleanData,
    onComplete: (res, xhr) => {
      if (xhr.status === 200) {
        exports.defaultGetHandler(res, callback);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.create = (data, callback) => {
  Backend.post(`/api/private/custom-fields`, {
    data: cleanupData(data),
    onComplete: (res, xhr) => {
      if (xhr.status === 201) {
        exports.defaultGetHandler(res, callback);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.delete = (id, callback) => {
  Backend.delete(`/api/private/custom-fields/${id}`, {
    onComplete: (res, xhr) => {
      exports.defaultDeleteHandler({
        id
      }, res, xhr, callback);
    }
  });
};
exports.getEnabledFields = () => exports.all().filter(({
  enabled
}) => enabled);
exports.areCustomFieldsEnabled = () => !!exports.getEnabledFields().length;
function cleanupData(data) {
  const cleanData = {
    ...data
  };
  if (Array.isArray(data.values)) {
    // Remove disallowed properties from the values
    cleanData.values = data.values.map(({
      id,
      value,
      color_key,
      enabled
    }) => ({
      id,
      value,
      color_key,
      enabled
    }));
  }
  return cleanData;
}

// Use this to help transition to object structure
// https://www.notion.so/useshortcut/Custom-fields-API-changes-4c5d80072ce649318a1db069f7da89cf#4a2b33e964924710ba0c483c291bcc21
exports.generateEmptyStoryCustomFields = CustomFieldUtils.generateEmptyStoryCustomFields;
exports.sanitizeStoryCustomFieldsPatch = custom_fields => custom_fields.map(({
  field_id,
  value_id
}) => ({
  field_id,
  value_id
}));
exports.getValueById = (fieldId, valueId) => {
  const field = exports.getById(fieldId);
  return field?.values.find(value => value.id === valueId);
};
exports.getFieldByValueId = valueId => {
  const fields = exports.all();
  for (const field of fields) {
    if (field.values.find(value => value.id === valueId)) {
      return field;
    }
  }
};
exports.Promises = {};
exports.Promises.fetchAll = BaseUtils.promisify(exports.fetchAll);
exports.Promises.put = BaseUtils.promisify(exports.put);
exports.Promises.create = BaseUtils.promisify(exports.create);
exports.Promises.delete = BaseUtils.promisify(exports.delete);
export { exports as default };