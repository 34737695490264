import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.some.js";
/*
 * This is a domain-specific wrapper around the Collectionize library.
 *
 * Must be in _frontloader so that other modules can extend themselves
 * with these methods by calling:
 *
 * `Collection.create(name, exports);`
 *
 * If this were _not_ frontloaded, then `Collection.create` would be
 * undefined when `define` is evaluated for the requiring module.
 */

import Collectionize from 'collectionize/collectionize';
import Constants from '../modules/constants';
import Globals from './globals';
import _ from 'lodash';
const exports = {};
const PERSIST_COLLECTION_THROUGH_HOTRELOAD = ['BulkSelection'];
function _initCollectionDB(name, collection) {
  Globals.setOnlyIfMissing('globalCollections', {});
  const globalCollections = Globals.get('globalCollections');
  if (globalCollections[name]) {
    collection.db = globalCollections[name].db;
    collection.idIndex = globalCollections[name].idIndex;
  }
  globalCollections[name] = collection;
}
exports.create = (name, collection = {}) => {
  collection = Collectionize(name, collection);
  collection.bulk = false;
  collection.isSyncing = false;
  _initCollectionDB(name, collection);
  collection.on('bulkStart.collection', () => {
    collection.bulk = true;
  });
  collection.on('bulkEnd.collection', () => {
    collection.bulk = false;
  });
  collection.on('syncStart.collection', () => {
    collection.isSyncing = true;
  });
  collection.on('syncEnd.collection', () => {
    collection.isSyncing = false;
  });

  //  collection.on('parseError', (data, e) => {
  //    Log.error(e, { type: 'parseError', data });
  //  });

  collection.on('beforeAdd.collection beforeUpdate.collection', obj => {
    if (collection.normalize) {
      collection.normalize(obj);
    }
  });
  collection.on('flushed.collection', () => {
    const globalCollections = Globals.get('globalCollections');
    collection.db = globalCollections[name].db;
    collection.idIndex = globalCollections[name].idIndex;
  });
  collection.isNotBulk = () => {
    return !collection.bulk;
  };
  collection.isNotSyncing = () => {
    return !collection.isSyncing;
  };
  collection.isValid = obj => {
    return obj && obj.id;
  };
  collection.updateIfValid = obj => {
    if (collection.isValid(obj)) {
      collection.updateById(obj);
    }
  };
  collection.removeAnyNotFound = res => {
    const toRemove = [];
    collection.each(model => {
      if (!_.some(res, {
        id: model.id
      })) {
        toRemove.push(model.id);
      }
    });
    toRemove.forEach(id => {
      collection.remove({
        id
      });
    });
  };
  collection.defaultFetchAllHandler = (res, callback, removeNotFound, checkUpdatedTimestamp) => {
    callback = _.isFunction(callback) ? callback : _.noop;
    collection.trigger('bulkStart');
    if (removeNotFound !== false) {
      collection.removeAnyNotFound(res);
    }
    if (res && res.length && res.length > 0) {
      res.forEach(obj => {
        if (checkUpdatedTimestamp !== false && obj?.id) {
          const existing = collection.getById(obj.id);
          if (existing?.updated_at && obj.updated_at && obj.updated_at < existing.updated_at) {
            console.debug('Newer version already found in db. Skipping update:', obj);
            return;
          }
        }
        collection.updateIfValid(obj);
      });
    }
    collection.trigger('bulkEnd');
    collection.trigger('fetched');
    callback(null);
  };
  collection.defaultFetchSomeHandler = (res, callback) => {
    collection.defaultFetchAllHandler(res, callback, false);
  };
  collection.defaultGetHandler = (res, callback, eventName) => {
    let normalized = null;
    callback = _.isFunction(callback) ? callback : _.noop;
    if (collection.isValid(res)) {
      collection.updateById(res);
      normalized = collection.getById(res.id);
      if (eventName) {
        collection.trigger(eventName, normalized);
      }
      callback(null, normalized);
    } else {
      collection.defaultErrorHandler(res, callback);
    }
  };
  collection.defaultNoResponseHandler = (res, xhr, callback) => {
    callback = _.isFunction(callback) ? callback : _.noop;
    if (xhr.status === 204) {
      callback();
    } else {
      collection.defaultErrorHandler(res, callback);
    }
  };
  collection.defaultDeleteHandler = (obj, res, xhr, callback) => {
    callback = _.isFunction(callback) ? callback : _.noop;

    // The object may have already be deleted, in which case we'll get a 404, so
    // we should remove it from the client-side collection in either case.
    if (xhr.status === 204 || xhr.status === 404) {
      collection.remove({
        id: obj.id
      });
      callback(null);
    } else {
      collection.defaultErrorHandler(res, callback);
    }
  };

  // Note: Attempt to pass the entire error downstream, let the final stop do what it wants with the error object
  collection.defaultErrorHandler = (res, callback) => {
    const msg = _.get(res, 'error.message') || _.get(res, 'message') || _.get(res, 'error', Constants.NETWORK_ERROR);
    callback = _.isFunction(callback) ? callback : _.noop;
    if (res?.responseIntercepted) {
      callback(msg, null, res);
    } else {
      callback(msg);
    }
  };
  collection.clearListeners = () => {
    const collectionListeners = collection.listeners.filter(listener => _.last(listener.name.split('.')) === 'collection');
    collection.listeners = collectionListeners;
  };
  collection.copyAll = () => collection.all().slice();
  collection.copyById = id => {
    const thing = collection.getById(id);
    return thing && {
      ...thing
    };
  };
  return collection;
};
exports.clearAllListeners = () => {
  const globalCollections = Globals.get('globalCollections');
  Object.values(globalCollections).forEach(collection => {
    if (!PERSIST_COLLECTION_THROUGH_HOTRELOAD.includes(collection.name)) {
      collection.clearListeners();
    }
  });
};
export { exports as default };