import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.flat-map.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import moment from 'moment';
import { FEATURE_TOGGLES, getFeatureToggle } from '@clubhouse/feature-toggles';
import { capitalize } from '@clubhouse/shared/utils';
import * as GenericChartTooltipTemplate from 'app/client/core/views/templates/reports/genericChartTooltip.html?caveman';
import { CycleTimeReportCard } from 'components/reports/cycleTime/Report';
import { getOrgWorkingDays } from 'data/entity/organization';
import { NON_WORKING_DAYS_LABEL, formatIterationDateRange, importChartingLibraries, mergeNonWorkingData, renderChartMetrics } from 'utils/charts';
import { calcAverage, calcStandardDeviation, fromLogScale, toLogScale } from 'utils/math';
import { getChartConfig } from './config';
import { CHART_COLORS, CHART_ID, KEYS, ROW_KEYS, TRAILING_AVERAGE_LENGTH } from './constants';
import { findChartTooltipElement, findDateFromTooltipValue, formatTooltipName, formatValueLabel, getEndStateForType, getStartStateForType, getStoriesInRange, getTooltipRows, hideC3Tooltip, hideC3TooltipContainer, isToggledOnInLegend, setClassForElements, shouldNotAllowPointerEvents, showC3TooltipContainer, toggleState } from './utils';
import StoryModel from '../../../models/story';
import Utils from '../../../modules/utils';
import View from '../../../modules/view';
import DropdownController from '../../dropdown';
import StoryDialogController from '../../storyDialog';
export const renderChartComponent = ({
  chartData,
  errorMsg,
  options,
  onChangeFilter
}) => {
  const mountNode = document.getElementById(`${CHART_ID}-chart`);
  if (mountNode) {
    View.renderComponent({
      mountNode,
      component: CycleTimeReportCard,
      props: {
        chartId: CHART_ID,
        chartData,
        errorMsg,
        onChangeFilter,
        renderChart: renderData => renderChart({
          chartData: renderData,
          options
        })
      }
    });
  }
};

// TODO(calvin): this should be replaced once we moved to the new implementation
const initializeFormattedData = dateRange => {
  const data = {
    // This order affects the order that c3 renders items. Smaller indices will be rendered first,
    // so trailing average will render on top of features, for example. Unfortunately it also affects
    // the order of the legend items, which is not configurable on its own. So we cannot change this
    // to make features render on top of trailing average unless we want trailing average to appear
    // first in the legend (as an example). We also cannot manipulate the dom to change the order of
    // elements, because c3 relies on the initial ordering remaining the same, and changing it causes
    // bound events to be lost and/or incorrect.
    rows: [['days'], ['features_x'], ['bugs_x'], ['chores_x'], ['average_x'], ['std_dev_top_x'], ['std_dev_bottom_x'], ['hidden_x'], [KEYS.Features], [KEYS.Bugs], [KEYS.Chores], [KEYS.TrailingAverage], [KEYS.StandardDeviation], [KEYS.StandardDeviation2], [KEYS.HIDDEN_DATA_SO_DATES_ALWAYS_RENDER]],
    storyIDs: {
      feature: [],
      bug: [],
      chore: []
    },
    tooltips: {},
    average: 0,
    total: 0,
    min: 0,
    max: 0
  };
  const dateInRange = dateRange.start.clone();
  while (!dateInRange.isAfter(dateRange.end)) {
    const dateString = dateInRange.format('YYYY-MM-DD');
    data.rows[0].push(dateString);
    data.tooltips[dateString] = [];
    dateInRange.add(1, 'day');
  }
  return data;
};
const initializeFormattedDataNew = formattedDateStrings => {
  const data = {
    // This order affects the order that c3 renders items. Smaller indices will be rendered first,
    // so trailing average will render on top of features, for example. Unfortunately it also affects
    // the order of the legend items, which is not configurable on its own. So we cannot change this
    // to make features render on top of trailing average unless we want trailing average to appear
    // first in the legend (as an example). We also cannot manipulate the dom to change the order of
    // elements, because c3 relies on the initial ordering remaining the same, and changing it causes
    // bound events to be lost and/or incorrect.
    rows: [['days', ...formattedDateStrings], ['features_x'], ['bugs_x'], ['chores_x'], ['average_x'], ['std_dev_top_x'], ['std_dev_bottom_x'], ['hidden_x'], [KEYS.Features], [KEYS.Bugs], [KEYS.Chores], [KEYS.TrailingAverage], [KEYS.StandardDeviation], [KEYS.StandardDeviation2], [KEYS.HIDDEN_DATA_SO_DATES_ALWAYS_RENDER]],
    storyIDs: {
      feature: [],
      bug: [],
      chore: []
    },
    tooltips: {},
    average: 0,
    total: 0,
    min: 0,
    max: 0
  };
  return data;
};
const nextDay = date => {
  return date.add(1, 'day');
};
const formatDate = date => {
  return date.format('YYYY-MM-DD');
};
const generateDateRangeIntervals = (start, end) => {
  // NOTE: `endDate` should be treated as exclusive (like in the BE)
  const dates = [];
  let currDate = start.clone();
  while (!currDate.isAfter(end)) {
    dates.push({
      startDate: currDate.clone().startOf('day'),
      endDate: currDate.clone().endOf('day')
    });
    currDate = nextDay(currDate.clone());
  }
  return dates;
};

/**
 * Places stories to a date bucket
 * If the interval is iteration, we use a story's iteration end date to determine the bucket it belongs to.
 * If the interval is a fixed step (currently only day is supported), then we use the cycle time end date
 *   to determine the bucket it is assigned to.
 * We return
 *  - a mapping of date bucket -> stories
 *  - the date buckets, which are sorted from early to latest date.
 */
const createStoriesByDateBucket = (stories, {
  dateRange: {
    start,
    end
  },
  interval: {
    id: intervalId
  }
}) => {
  if (intervalId === 'iteration') {
    const storiesWithIterations = stories.filter(story => !!story.iteration && !!story.iteration.iteration_end);
    const storiesByIteration = storiesWithIterations.reduce((groups, story) => {
      const formattedEndDate = formatDate(moment(story.iteration.iteration_end));
      groups[formattedEndDate] = groups[formattedEndDate] || [];
      groups[formattedEndDate].push(story);
      return groups;
    }, {});
    const iterations = storiesWithIterations.map(story => story.iteration).filter((obj, index) => storiesWithIterations.findIndex(item => item.iteration.iteration_end === obj.iteration_end) === index).sort((a, b) => moment(a.iteration_end).diff(moment(b.iteration_end)));
    const dates = iterations.map(iteration => iteration.iteration_end);
    return {
      storiesByDateBucket: storiesByIteration,
      dates,
      iterations
    };
  } else {
    const dates = generateDateRangeIntervals(start, end);
    const storiesByDateBucket = {};
    dates.forEach(({
      startDate,
      endDate
    }) => {
      // find all stories that belong to this specific bucket.
      const storiesForBucket = stories.filter(story => {
        const storyEndDate = story.cycleTimeData.endDate;

        // The story's end date sit between the interval
        return storyEndDate > startDate && storyEndDate <= endDate;
      });
      // Update buckets with its stories
      // Using `endDate`, since that is when the story was completed
      const formattedEndDate = formatDate(endDate);
      storiesByDateBucket[formattedEndDate] = storiesForBucket;
    });
    return {
      storiesByDateBucket: storiesByDateBucket,
      dates: dates.map(({
        endDate
      }) => formatDate(endDate))
    };
  }
};
const formatDataNew = ({
  chartData,
  options,
  toggleStates
}) => {
  const type = getChartConfig('ct_type');
  const timeForType = {
    'cycle time': 'cycleTime',
    'lead time': 'leadTime',
    custom: 'cycleTime'
  };
  const cycleOrLeadTime = timeForType[type];
  const startingWFStateId = getStartStateForType(type);
  const endingWFStateId = getEndStateForType(type);
  const allTimes = [];

  // Ensure that the stories were marked as completed within the requested time range.
  // Additionally computes the cycle and lead time, earliest start and latest complete dates,
  // and fetches the story from collectionize.
  const stories = getStoriesInRange({
    chartData,
    options,
    startingWFStateId: startingWFStateId ? Number(startingWFStateId) : undefined,
    endingWFStateId: endingWFStateId ? Number(endingWFStateId) : undefined
  });
  let numStoriesNeverStarted = 0;
  const {
    dates,
    storiesByDateBucket,
    iterations
  } = createStoriesByDateBucket(stories, options);
  const data = initializeFormattedDataNew(dates);

  // Populate the chart data each story type
  // This will add feature/chore/bug data points and their tool tips on the chart
  // We also keep track of stories that were never started.
  dates.forEach(date => {
    const stories = storiesByDateBucket[date];
    data.tooltips[date] = data.tooltips[date] || [];
    stories.sort((a, b) => a.cycleTimeData.endDate.diff(b.cycleTimeData.endDate)).forEach(story => {
      const key = ROW_KEYS[story.story_type];

      // It is possible for stories to never move to a done state.
      // Because of that, it is possible for a story to not have a computed cycle time.
      const time = story.cycleTimeData[cycleOrLeadTime];
      if (!time) {
        numStoriesNeverStarted++;
        return;
      }

      // Additionally update `allTimes` which takes into consideration the
      // story type selected
      const typeIsToggledOn = toggleStates[`${capitalize(story.story_type)}s`];
      if (typeIsToggledOn) {
        allTimes.push(time);
      }

      // Categorize each story by their story type
      data.rows[key.x].push(date);
      data.rows[key.y].push(time);
      data.tooltips[date].push({
        value: time,
        name: formatTooltipName(story.name),
        type: story.story_type,
        story,
        startDate: cycleOrLeadTime === 'cycleTime' ? story.cycleTimeData.startDate : moment(story.created_at),
        endDate: story.cycleTimeData.endDate
      });
    });
  });

  // Update chart data with the computed average and standard deviation for each date.
  dates.forEach((date, n) => {
    data.rows[ROW_KEYS.hidden.x].push(date);
    data.rows[ROW_KEYS.average.x].push(date);
    data.rows[ROW_KEYS.stdDev.x].push(date);
    data.rows[ROW_KEYS.stdDev2.x].push(date);

    // Exclusive of current date
    const times = dates.slice(Math.max(0, n - TRAILING_AVERAGE_LENGTH + 1), n).flatMap(windowDate => {
      const times = storiesByDateBucket[windowDate].filter(story => {
        // We only want to consider stories that have cycleOrLeadTime
        return !!story.cycleTimeData[cycleOrLeadTime];
      }).filter(story => {
        // The story type is currently toggled on
        return toggleStates[`${capitalize(story.story_type)}s`];
      }).map(story => story.cycleTimeData[cycleOrLeadTime]);
      return times;
    });
    const average = times.length > 0 ? calcAverage(times) : 0;
    const standardDeviation = times.length > 0 ? calcStandardDeviation(times) : 0;
    data.tooltips[date].push({
      value: average,
      name: '7 Day Trailing Average',
      type: '7 Day Trailing Average'
    });
    data.rows[ROW_KEYS.hidden.y].push(-1);
    data.rows[ROW_KEYS.average.y].push(average);
    data.rows[ROW_KEYS.stdDev.y].push(standardDeviation * 2);
    data.rows[ROW_KEYS.stdDev2.y].push(average - standardDeviation);
  });

  // Metrics rendered above the chart.
  data.total = allTimes.length;
  data.average = calcAverage(allTimes);
  data.max = allTimes.length ? Math.max(...allTimes) : 0;
  data.min = allTimes.length ? Math.min(...allTimes) : 0;

  // This check the `url` to see the current scale set.
  const scale = getChartConfig('ct_scale');
  if (scale === 'log') {
    Object.entries(ROW_KEYS).forEach(([rowName, row]) => {
      data.rows[row.y] = data.rows[row.y].map((n, i) => {
        // Return name field untouched and ensure hidden data is always at -1
        if (i === 0 || rowName === 'hidden') return n;
        return toLogScale(n);
      });
    });
  }
  data.rows.forEach(row => row.length === 1 && row.push(null));
  return {
    data,
    // TODO (calvin): we can move this datapoint to `data` like it
    //   is done for total, average, max, and min
    numStoriesNeverStarted,
    iterations
  };
};
const formatData = ({
  chartData,
  options,
  toggleStates
}) => {
  const data = initializeFormattedData(options.dateRange);
  const type = getChartConfig('ct_type');
  const timeForType = {
    'cycle time': 'cycleTime',
    'lead time': 'leadTime',
    custom: 'cycleTime'
  };
  const cycleOrLeadTime = timeForType[type];
  const startingWFStateId = getStartStateForType(type);
  const endingWFStateId = getEndStateForType(type);
  const averages = [];
  const allTimes = [];
  let numStoriesNeverStarted = 0;
  const days = data.rows[0].slice(1);

  // Ensure that the stories were marked as completed within the requested time range.
  // Additionally computes the cycle and lead time, earliest start and latest complete dates,
  // and fetches the story from collectionize.
  const stories = getStoriesInRange({
    chartData,
    options,
    startingWFStateId: startingWFStateId ? Number(startingWFStateId) : undefined,
    endingWFStateId: endingWFStateId ? Number(endingWFStateId) : undefined
  });

  // Iterate through stories to populate variables with values.
  // - Populate `data` with chart data for each story type
  //   - Categorize each story based on their story type
  //   - Add tool tip information for those stories
  // - Store the cycle and lead times to be used later to compute the trailing average
  stories.sort((a, b) => a.cycleTimeData.endDate.diff(b.cycleTimeData.endDate)).forEach(story => {
    // `key` are the associated row indexes for the story type
    const key = ROW_KEYS[story.story_type];
    const day = story.cycleTimeData.endDate?.format('YYYY-MM-DD');
    const time = story.cycleTimeData[cycleOrLeadTime];
    if (!day) return;
    if (!time) {
      // The story moved from Unstarted to Done, never visiting a Started state
      numStoriesNeverStarted++;
      return;
    }
    const typeIsToggledOn = toggleStates[`${capitalize(story.story_type)}s`];
    if (typeIsToggledOn) allTimes.push(time);
    data.rows[key.x].push(day);
    data.rows[key.y].push(time);
    data.tooltips[day] = data.tooltips[day] || [];
    data.tooltips[day].push({
      value: time,
      name: formatTooltipName(story.name),
      type: story.story_type,
      story,
      startDate: cycleOrLeadTime === 'cycleTime' ? story.cycleTimeData.startDate : moment(story.created_at),
      endDate: story.cycleTimeData.endDate
    });

    // TODO(calvin): averages is not a great name here since we're not computing any average at all
    // and the `time` stored is not an average. This simply exists as a way to store values to compute
    // the trailing average.
    const endDateIndex = days.indexOf(story.cycleTimeData.endDate.format('YYYY-MM-DD'));
    averages.push({
      day: endDateIndex,
      time,
      storyType: story.story_type
    });

    // TODO(calvin): honestly, i don't think this is used for anything at all
    data.storyIDs[story.story_type].push(story.id);
  });

  // Update `data` with the computed average and standard deviation
  days.forEach((day, n) => {
    data.rows[ROW_KEYS.hidden.x].push(day);
    data.rows[ROW_KEYS.average.x].push(day);
    data.rows[ROW_KEYS.stdDev.x].push(day);
    data.rows[ROW_KEYS.stdDev2.x].push(day);

    // Exclusive of current date
    const times = averages.filter(average => toggleStates[`${capitalize(average.storyType)}s`] && average.day > n - TRAILING_AVERAGE_LENGTH && average.day <= n && average.day !== n).map(average => average.time);
    const average = times.length > 0 ? calcAverage(times) : 0;
    const standardDeviation = times.length > 0 ? calcStandardDeviation(times) : 0;
    if (day !== null) data.tooltips[day].push({
      value: average,
      name: '7 Day Trailing Average',
      type: '7 Day Trailing Average'
    });
    data.rows[ROW_KEYS.hidden.y].push(-1);
    data.rows[ROW_KEYS.average.y].push(average);
    data.rows[ROW_KEYS.stdDev.y].push(standardDeviation * 2);
    data.rows[ROW_KEYS.stdDev2.y].push(average - standardDeviation);
  });

  // Metrics rendered above the chart.
  data.total = allTimes.length;
  data.average = calcAverage(allTimes);
  data.max = allTimes.length ? Math.max(...allTimes) : 0;
  data.min = allTimes.length ? Math.min(...allTimes) : 0;

  // This check the `url` to see the current scale set.
  const scale = getChartConfig('ct_scale');
  if (scale === 'log') {
    Object.entries(ROW_KEYS).forEach(([rowName, row]) => {
      data.rows[row.y] = data.rows[row.y].map((n, i) => {
        // Return name field untouched and ensure hidden data is always at -1
        if (i === 0 || rowName === 'hidden') return n;
        return toLogScale(n);
      });
    });
  }
  data.rows.forEach(row => row.length === 1 && row.push(null));
  return {
    data,
    numStoriesNeverStarted
  };
};
const formatDataExperiment = (usesIterationIntervals, {
  chartData,
  options,
  toggleStates
}) => {
  if (usesIterationIntervals) {
    return formatDataNew({
      chartData,
      options,
      toggleStates
    });
  } else {
    return formatData({
      chartData,
      options,
      toggleStates
    });
  }
};
export const renderChart = async ({
  chartData,
  options,
  toggleStates = {
    [KEYS.Features]: true,
    [KEYS.Bugs]: true,
    [KEYS.Chores]: true,
    [KEYS.TrailingAverage]: true,
    [KEYS.StandardDeviation]: true
  }
}) => {
  const {
    c3
  } = await importChartingLibraries();
  const scale = getChartConfig('ct_scale');
  const type = getChartConfig('ct_type');
  const usesIterationIntervals = getFeatureToggle(FEATURE_TOGGLES.REPORTS_ITERATION_INTERVALS);
  const formatDataResult = formatDataExperiment(usesIterationIntervals, {
    chartData,
    options,
    toggleStates
  });
  const {
    data: formattedData,
    iterations,
    numStoriesNeverStarted
  } = formatDataResult;
  const days = formattedData.rows[0].slice(1);
  const isGroupByIteration = options?.interval?.id === 'iteration';
  const workingDays = getOrgWorkingDays();
  const c3Input = {
    bindto: options?.element || document.getElementById(CHART_ID),
    data: {
      // We use two areas here to generate the standard deviation area. The visible grey area goes from the top
      // standard deviation line down to the bottom standard deviation line. The invisible area goes from the
      // bottom line to the x-axis. Since C3 does not render areas as "stacked" on top of one another, we can
      // set the bottom areas opacity to 0 and then manage the on/off state of both areas together.
      xs: {
        [KEYS.HIDDEN_DATA_SO_DATES_ALWAYS_RENDER]: 'hidden_x',
        [KEYS.StandardDeviation2]: 'std_dev_top_x',
        [KEYS.StandardDeviation]: 'std_dev_bottom_x',
        [KEYS.Features]: 'features_x',
        [KEYS.Bugs]: 'bugs_x',
        [KEYS.Chores]: 'chores_x',
        [KEYS.TrailingAverage]: 'average_x',
        [NON_WORKING_DAYS_LABEL]: 'hidden_x'
      },
      groups: [[KEYS.StandardDeviation, KEYS.StandardDeviation2]],
      colors: CHART_COLORS,
      columns: formattedData.rows.slice(1),
      order: null,
      types: {
        [KEYS.HIDDEN_DATA_SO_DATES_ALWAYS_RENDER]: 'line',
        [KEYS.Features]: 'scatter',
        [KEYS.Bugs]: 'scatter',
        [KEYS.Chores]: 'scatter',
        [KEYS.TrailingAverage]: 'line',
        [KEYS.StandardDeviation]: 'area',
        [KEYS.StandardDeviation2]: 'area'
      },
      onmouseover: d => {
        // Even though the size of the Standard Deviation points is 0, C3 sets `cursor: pointer` in the CSS when
        // hovering over the areas where they would be. We manage this ourselves here to prevent that.
        if (shouldNotAllowPointerEvents(d.id)) return;
        setClassForElements({
          targetClass: 'c3-event-rect',
          className: 'hover-over-circle',
          isAdd: true
        });
      },
      onmouseout: d => {
        if (shouldNotAllowPointerEvents(d.id)) return;
        setClassForElements({
          targetClass: 'c3-event-rect',
          className: 'hover-over-circle',
          isAdd: false
        });
      },
      onclick: ({
        id,
        index
      }) => {
        const tooltipStoryElements = document.getElementById(CHART_ID).getElementsByClassName('auto-story-link');
        const tooltipStoryCount = tooltipStoryElements.length;
        if (tooltipStoryCount > 1) renderCloseablePopover({
          id,
          index,
          formattedData,
          toggleStates
        });
        if (tooltipStoryCount === 1) {
          const storyID = Utils.data(tooltipStoryElements[0], 'id');
          StoryDialogController.loadStory(StoryModel.getById(storyID));
        }
        return false;
      }
    },
    transition: {
      duration: 250
    },
    point: {
      r: d => {
        if (d.id === KEYS.StandardDeviation || d.id === KEYS.StandardDeviation2 || d.id === KEYS.HIDDEN_DATA_SO_DATES_ALWAYS_RENDER) {
          // This is actually a lie because c3 still renders them (invisibly) and handles mouse events for them
          return 0;
        } else if (d.id === KEYS.TrailingAverage) {
          return 2;
        }
        return 3;
      }
    },
    size: {
      height: 380
    },
    grid: {
      focus: {
        show: false
      },
      y: {
        lines: [{
          value: scale === 'log' ? toLogScale(formattedData.average) : formattedData.average,
          text: 'Average: ' + formatValueLabel(formattedData.average),
          class: 'average-cycle-time-line'
        }]
      }
    },
    tooltip: {
      grouped: false,
      contents: unfilteredData => {
        const data = unfilteredData.filter(x => x.id !== NON_WORKING_DAYS_LABEL);
        if (!data[0]) return;
        const date = moment(data[0].x);
        const value = data[0].value;
        const rows = shouldNotAllowPointerEvents(data[0].id) ? [] : getTooltipRows({
          date,
          value,
          formattedData,
          toggleStates
        });
        if (!rows.length) {
          // We must hide the container for empty tooltips so that a small empty tooltip isnt rendered
          hideC3TooltipContainer();
        } else {
          showC3TooltipContainer();
          const myDayOfWeek = moment(date).day();
          const longDate = date.format('dddd, MMM D, YYYY');
          // Use iteration name if group by iteration is enabled
          const title = isGroupByIteration && rows[0].story ? rows[0].story?.iteration?.iteration_name || 'No Iteration' : longDate;
          return GenericChartTooltipTemplate.render({
            title: !workingDays.includes(myDayOfWeek) ? `${title} (No Work)` : title,
            rows
          });
        }
      }
    },
    legend: {
      hide: [KEYS.StandardDeviation2, KEYS.HIDDEN_DATA_SO_DATES_ALWAYS_RENDER],
      item: {
        onclick: id => {
          // We handle legend states ourselves because we have to update metrics and averages when toggle states change
          toggleState({
            id,
            chartData,
            options,
            toggleStates,
            renderChart
          });
        },
        onmouseover: id => {
          if (!isToggledOnInLegend({
            toggleStates,
            id
          })) return;
          Object.values(KEYS).forEach(legendId => {
            const isToggledOn = isToggledOnInLegend({
              toggleStates,
              id: legendId
            });
            if (isToggledOn && legendId !== id) {
              if (legendId === KEYS.StandardDeviation && id === KEYS.StandardDeviation2) return;
              if (legendId === KEYS.StandardDeviation2 && id === KEYS.StandardDeviation) return;
              const classKey = legendId.replaceAll(' ', '-');
              setClassForElements({
                targetClass: `c3-target-${classKey}`,
                className: 'c3-defocused',
                isAdd: true
              });
              setClassForElements({
                targetClass: `c3-legend-item-${classKey}`,
                className: 'c3-defocused',
                isAdd: true
              });
            }
          });
        },
        onmouseout: id => {
          if (!isToggledOnInLegend({
            toggleStates,
            id
          })) return;
          Object.values(KEYS).forEach(legendId => {
            const classKey = legendId.replaceAll(' ', '-');
            setClassForElements({
              targetClass: `c3-target-${classKey}`,
              className: 'c3-defocused',
              isAdd: false
            });
            setClassForElements({
              targetClass: `c3-legend-item-${classKey}`,
              className: 'c3-defocused',
              isAdd: false
            });
          });
        }
      }
    },
    axis: {
      x: {
        type: 'timeseries',
        tick: {
          format: date => {
            if (isGroupByIteration) {
              const formattedDate = moment(date).format('YYYY-MM-DD');
              const iteration = (iterations || []).find(iteration => iteration.iteration_end === formattedDate);
              if (iteration) {
                const {
                  iteration_start,
                  iteration_end
                } = iteration;
                return formatIterationDateRange(iteration_start, iteration_end);
              }
            }
            const MAX_LABEL_COUNT = 5;
            const mod = Math.floor(days.length / MAX_LABEL_COUNT);
            const index = days.indexOf(moment(date).format('YYYY-MM-DD'));
            if (days.length <= MAX_LABEL_COUNT || index % mod === 0) {
              return moment(date).format('MMM D, YYYY');
            }
          },
          values: days
        }
      },
      y: {
        tick: {
          // Prevent duplication of y values for small ranges. When the max is < 10, by default c3 still renders
          // 10 y ticks, causing it to duplicate some day values, so we tell it to render fewer if the max is < 10.
          count: formattedData.max < 10 ? Math.ceil(formattedData.max) + 1 : undefined,
          format: d => {
            // C3 renders 0.0 as 0.3, but 0 as 0. To prevent this incorrect value displaying, we return 0.
            if (Math.ceil(formattedData.max) === 0) return 0;
            if (scale === 'log') {
              return Math.abs(fromLogScale(d)).toFixed(1);
            } else {
              return Math.abs(d).toFixed(0);
            }
          }
        },
        label: {
          text: 'Days',
          position: 'outer-middle'
        },
        min: 0,
        // Add space at the top for the word "Average" to fit in case the average line is at the top
        padding: {
          top: 20,
          bottom: 5
        }
      }
    }
  };
  const c3InputWithNonWorkingDays = mergeNonWorkingData(workingDays, options?.interval?.id, c3Input);
  const cycleTimeChart = c3.generate(c3InputWithNonWorkingDays);
  Object.keys(toggleStates).forEach(id => {
    if (!toggleStates[id]) {
      cycleTimeChart.hide([id]);
      if (id === KEYS.StandardDeviation) cycleTimeChart.hide([KEYS.StandardDeviation2]);
    }
  });
  const storiesLabel = 'Stories Shown';
  const unstartedStoriesLabel = type === 'cycle time' ? 'Stories Never Started' : undefined;
  renderChartMetrics({
    chartId: CHART_ID,
    metrics: {
      [storiesLabel]: formattedData.total,
      ...(unstartedStoriesLabel ? {
        [unstartedStoriesLabel]: numStoriesNeverStarted
      } : {}),
      Average: formatValueLabel(formattedData.average),
      'Min Time': formatValueLabel(formattedData.min),
      'Max Time': formatValueLabel(formattedData.max)
    },
    groups: [['Average', 'Min Time', 'Max Time'], unstartedStoriesLabel ? [storiesLabel, unstartedStoriesLabel] : [storiesLabel]]
  });
  return cycleTimeChart;
};

// This replicates the C3 Tooltip generated by the tooltip config above. This function
// renders a static version of the tooltip as a popover that can be manually closed.
function renderCloseablePopover({
  id,
  index,
  formattedData,
  toggleStates
}) {
  const {
    date,
    value
  } = findDateFromTooltipValue({
    formattedData,
    id,
    index
  });
  const rows = getTooltipRows({
    date,
    value,
    formattedData,
    toggleStates
  });
  const html = GenericChartTooltipTemplate.render({
    title: moment(date).format('dddd, MMM D, YYYY'),
    rows
  });
  DropdownController.open({
    target: findChartTooltipElement().getElementsByClassName('c3-tooltip-title')[0],
    items: [],
    animate: false,
    className: 'c3-tooltip-container cycle-time-tooltip',
    showCloseButton: true,
    footer: html,
    leftOffset: -12,
    // left offset from the target element (matches the position of c3 tooltips)
    topOffset: -41,
    // top offset from the target element (matches the position of c3 tooltips)
    width: 466 // width of c3 tooltips
  });
  hideC3Tooltip();
}