import Router from '../../../core/js/_frontloader/router';
import FlashController from '../../../core/js/controllers/flash';
import Url from '../../../core/js/modules/url';
import Utils from '../../../core/js/modules/utils';
import IntegrationModel from '../../../core/js/models/integration';
import PermissionModel from '../../../core/js/models/permission';
const exports = {};
const SLACK_PARENT_SELECTOR = '#manage-slack-notifications';
exports.handleSlackIdentify = () => {
  return new Promise(resolve => {
    const {
      code,
      state,
      error
    } = Url.parseLocationSearch();
    const [slug] = state.split('|'); // State format is workspace_slug|project_id or workspace_slug.
    const origin = Url.getCurrentOrigin();
    if (slug && slug !== Url.getCurrentSlug()) {
      const target_url = `${origin}/${slug}/settings/account/notifications${window.location.search}`;
      Utils.redirect(target_url);
      return resolve();
    }
    Router.replaceState(Url.getCurrentPathname());
    return resolve(updateSlackIdentity(origin, code, error).then(() => {
      return saveSuccessMessage();
    }).catch(err => {
      return saveErrorMessage(err);
    }));
  });
};
const updateSlackIdentity = (origin, code, error) => {
  return new Promise((resolve, reject) => {
    if (error) {
      reject(error);
    }
    const redirect_uri = IntegrationModel.getSlackRedirectUrl();
    PermissionModel.updateSlackIdentity({
      code,
      redirect_uri
    }).then(() => {
      // Automatically, enable Slack notifications when signing in
      // with slack since that it most likely the intended use
      // case. The sign in button is replaced with a toggle to
      // disable Slack notifications if needed.
      resolve(PermissionModel.updateSlackNotificationEnabled(true));
    }).catch(reject);
  });
};
const saveErrorMessage = err => {
  const title = 'There was an error signing into Slack.';
  let message;
  if (err) {
    message = `The message we got back was "${err}".`;
  }
  FlashController.saveErrorMessage(SLACK_PARENT_SELECTOR, title, message);
};
const saveSuccessMessage = () => {
  FlashController.saveSuccessMessage(SLACK_PARENT_SELECTOR, 'Signed into Slack.');
};
export { exports as default };