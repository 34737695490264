import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import Iterate from '../modules/iterate';
import Utils from '../modules/utils';
import _ from 'lodash';
const exports = {
  Promises: {}
};

/*

Example Icon entity:

{
  created_at: "2016-06-08T18:02:42Z",
  id: "57585dc1-f149-44e0-bb25-888a12fa4500",
  message: "Message",
  spin: true,
  type: "loading", // Added manually
  updated_at: "2016-06-08T18:02:42Z",
  url: "https://clubhouse-assets.s3.amazonaws.com/..."
}

*/

Collection.create('Icon', exports);
exports.TYPES = {
  LOADING: 'loading',
  ORGANIZATION: 'organization',
  USER: 'user'
};
exports.isValid = icon => {
  return icon && icon.id && icon.url;
};
exports.updateWithType = (icons, type) => {
  Iterate.each(icons, icon => {
    exports.updateIfValid(_.assign(icon, {
      type
    }));
  });
};
exports.hasLoadingIcons = () => {
  return !!exports.getLoadingIcons().length;
};
exports.getLoadingIcons = () => {
  return exports.filter(icon => {
    return icon.type === exports.TYPES.LOADING;
  });
};
exports.getLoadingIconIDs = () => {
  return _.map(exports.getLoadingIcons(), 'id');
};
exports.targetIsIcon = target => {
  return _.isString(Utils.data(target, 'icon'));
};
exports.getEntityFromTarget = target => {
  return Utils.data(target, 'icon-entity');
};
exports.getTypeFromTarget = target => {
  return Utils.data(target, 'icon-type');
};
exports.saveLoadingIcon = (icons, callback) => {
  const icon_ids = exports.getLoadingIconIDs().concat(_.map(icons, 'id'));
  exports.updateLoadingIcons(icon_ids, callback);
};
exports.deleteLoadingIcon = (icon, callback) => {
  const icon_ids = _.pull(exports.getLoadingIconIDs(), icon.id);
  exports.updateLoadingIcons(icon_ids, (err, res) => {
    if (!err) {
      exports.remove({
        id: icon.id
      });
    }
    callback(err, res);
  });
};
exports.updateLoadingIcons = (icon_ids, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.put('/api/private/workspace2/loading-icons', {
    data: {
      loading_icon_ids: icon_ids
    },
    onComplete: (res, xhr) => {
      if (xhr.status === 200) {
        exports.updateWithType(res.loading_icons, exports.TYPES.LOADING);
        callback(null, res);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.updateOrgIcon = (icon_id, callback) => {
  exports.updateDisplayIcons(icon_id, 'workspace2', callback);
};
exports.updateUserIcon = (icon_id, callback) => {
  exports.updateDisplayIcons(icon_id, 'user', callback);
};
exports.updateDisplayIcons = (icon_id, entity, callback) => {
  Backend.put('/api/private/' + entity, {
    data: {
      display_icon_id: icon_id
    },
    onComplete: (res, xhr) => {
      if (xhr.status === 200) {
        if (icon_id) {
          exports.updateIfValid(res.display_icon);
          callback(null, exports.getById(res.display_icon.id));
        } else {
          callback(null);
        }
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.Promises.updateDisplayIcons = (icon_id, entity) => {
  return new Promise((resolve, reject) => {
    Backend.put('/api/private/' + entity, {
      data: {
        display_icon_id: icon_id
      },
      onComplete: response => {
        if (response.error) {
          reject(response);
        } else {
          if (icon_id) {
            exports.updateIfValid(response.display_icon);
            resolve(exports.getById(response.display_icon?.id));
          } else {
            resolve();
          }
        }
      }
    });
  });
};
exports.save = (icon, data, callback) => {
  Backend.put('/api/private/workspace2/icons/' + icon.id, {
    data,
    onComplete: res => {
      exports.defaultGetHandler(res, callback, 'iconUpdated');
    }
  });
};
exports.delete = (icon, callback) => {
  Backend.delete('/api/private/workspace2/icons/' + icon.id, {
    onComplete: (res, xhr) => {
      exports.defaultDeleteHandler(icon, res, xhr, callback);
    }
  });
};
export { exports as default };