import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import * as Event from '../../../core/js/_frontloader/event';
import ProjectController from '../controllers/project';
import ProjectModel from '../../../core/js/models/project';
import StoriesView from '../modules/storiesView';
import SpaceModel from '../../../core/js/models/space';
const exports = {};
exports.initEventBindings = () => {
  ProjectModel.on('beforeAdd', project => {
    project.active = SpaceModel.isModelActiveInActiveSpace('Project', project);
  });
  ProjectModel.on('added updated moved removed flushed', () => {
    if (ProjectModel.isNotBulk()) {
      ProjectController.render();
      StoriesView.showColumnView();
    }
  });
};
exports.toggle = project => {
  project.active = !project.active;
  ProjectModel.update(project);
  Event.trigger('spaceUpdate');
};
exports.setAllActive = () => {
  ProjectModel.each(x => {
    x.active = true;
  });
};
exports.showAll = () => {
  exports.setAllActive();
  ProjectModel.trigger('updated');
  Event.trigger('spaceUpdate');
};
exports.hideAll = () => {
  ProjectModel.each(project => {
    project.active = false;
  });
  ProjectModel.trigger('updated');
  Event.trigger('spaceUpdate');
};
exports.onlyCurrentProjectActive = project => {
  const activeProjects = ProjectModel.filter({
    active: true
  });
  return activeProjects.length === 1 && activeProjects[0].id === project.id;
};
export { exports as default };