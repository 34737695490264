import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.reduce.js";
import StorySearchController from 'app/client/core/js/controllers/storySearch';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  const StorySearchController = exports;
  [[['Controller', 'StorySearch'], StorySearchController]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import * as StorySearchResultTemplate from 'app/client/core/views/templates/storySearch/storySearchResult.html?caveman';
import AutocompleteController from './autocomplete';
import DropdownController from './dropdown';
import DropdownModel from '../models/dropdown';
import QuickSearchController from './quickSearch';
import StoryModel from '../models/story';
import Utils from '../modules/utils';
import Search from '../modules/search';
import _ from 'lodash';
const exports = {};
exports.filterUsingElasticSearch = _.debounce((query, story) => {
  const loadingItem = [{
    html: '<span class="loading-search-results"><span class="fa fa-spin fa-spinner"></span> Loading...</span>'
  }];
  AutocompleteController.updateItems(loadingItem);
  exports.queryElasticSearchForResults(query, story, (results, nextUrl) => {
    exports.addLoadMoreButton(results, nextUrl, story);
    AutocompleteController.updateItems(results);
  });
}, 50);
exports.addLoadMoreButton = (results, nextUrl, story, options) => {
  const loadMoreButton = Search.getLoadMoreButton(nextUrl, {
    ...options,
    attributes: `
      data-controller="StorySearch"
      ${story ? `data-story-id=${story.id}` : ''}
    `
  });
  if (loadMoreButton) {
    results.push({
      html: loadMoreButton
    });
  }
};
exports.queryElasticSearchForResults = (query, story, callback) => {
  // Guard against empty query.
  // Ref: https://app.shortcut.com/internal/story/31513
  if (!query) {
    return callback([]);
  }
  Search.elasticSearchStories(query, (err, res) => {
    const stories = _.get(res, 'data');
    if (err || !res) {
      return callback([]);
    }

    // We only need to remove the current story in cases where this
    // is a "Add story relationship" action in a story dialog.
    // In the other cases this function is used (textarea helper,
    // Add New Story dialog) it isn't a big deal.
    if (story) {
      _.remove(stories, {
        id: story.id
      });
    }
    const results = stories.reduce((results, story) => {
      // Guard against invalid story objects.
      // Ref: https://app.shortcut.com/internal/story/25992/nulled-out-entity-in-search-results
      if (StoryModel.isValid(story)) {
        results.push(_convertStoryToSearchResult(StoryModel.normalize(story)));
      }
      return results;
    }, []);
    callback(results, res.next);
  });
};
function _convertStoryToSearchResult(story) {
  return {
    name: StorySearchResultTemplate.render(story),
    displayValue: '#' + story.id,
    value: story.id
  };
}
exports.loadMoreSearchResultsIntoAutocomplete = function () {
  return _loadMoreSearchResults(this, _convertStoryToSearchResult, (results, nextUrl, story) => {
    const items = AutocompleteController.getItems();
    items.pop(); // Remove old Load More button

    exports.addLoadMoreButton(results, nextUrl, story);
    AutocompleteController.updateItems(items.concat(results));
  });
};
exports.loadMoreSearchResultsIntoDropdown = function () {
  return _loadMoreSearchResults(this, _convertStoryToSearchResult, (results, nextUrl, story) => {
    const context = $('.load-more-search-results-button');
    const dropdown = DropdownModel.findByChildElement(context);
    if (!dropdown) {
      return;
    }
    const items = DropdownController.getItems(dropdown);
    items.pop(); // Remove old Load More button

    exports.addLoadMoreButton(results, nextUrl, story, {
      isDropdown: true
    });
    DropdownController.updateItems(dropdown, items.concat(results));
  });
};
exports.loadMoreSearchResultsIntoQuickSearch = function () {
  const callback = (results, nextUrl, story) => {
    const context = $('.load-more-search-results-button');
    const dropdown = DropdownModel.findByChildElement(context);
    const items = DropdownController.getItems(dropdown);
    items.pop(); // Remove old Load More button

    exports.addLoadMoreButton(results, nextUrl, story, {
      isQuickSearch: true
    });
    DropdownController.updateItems(dropdown, items.concat(results));
  };
  return _loadMoreSearchResults(this, QuickSearchController.storyToResultTemplate, callback);
};
function _loadMoreSearchResults(context, convertStoryFn, callback) {
  const nextUrl = Utils.data(context, 'next-url');
  const story = StoryModel.getById(Utils.data(context, 'story-id'));
  $(context).removeAttr('data-on-click').removeClass('flat-white').addClass('light-gray disabled').html('<span class="fa fa-spin fa-star"></span> Loading...');
  Search.elasticSearchStories(null, (err, res) => {
    if (err) {
      return;
    }
    const stories = _.get(res, 'data');

    // We only need to remove the current story in cases where this
    // is a "Add story relationship" action in a story dialog.
    // In the other cases this function is used (textarea helper,
    // Add New Story dialog) it isn't a big deal.
    if (story) {
      _.remove(stories, {
        id: story.id
      });
    }
    const results = stories.map(story => convertStoryFn(StoryModel.normalize(story)));
    callback(results, res.next, story);
  }, {
    url: nextUrl
  });
  return false;
}
export { exports as default };