import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.some.js";
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import ColumnModel from './column';
import * as Event from '../_frontloader/event';
import Format from '../modules/format';
import TeamModel from './team';
import GroupModel from './group';
import StoryTemplateModel from './storyTemplate';
import StoryModel from './story';
import Url from '../modules/url';
import _ from 'lodash';
const exports = {};

/*

Example Workflow:

{
  "id": 1488,
  "created_at": "2015-05-28T21:14:12Z",
  "updated_at": "2016-12-01T01:56:19Z",
  "name": "Tracker",
  "description": "",
  "default_state_id": 1492,
  "states": [{
    "description": "",
    "color": "",
    "verb": "",
    "name": "Ideas",
    "num_stories": 254,
    "type": "unstarted",
    "updated_at": "2016-08-31T15:25:14Z",
    "id": 500005575,
    "position": 0,
    "created_at": "2016-06-13T17:43:33Z"
  }, {
    "description": "",
    "color": "",
    "verb": "",
    "name": "Backlog",
    "num_stories": 703,
    "type": "unstarted",
    "updated_at": "2016-06-13T17:44:00Z",
    "id": 1492,
    "position": 1,
    "created_at": "2015-05-28T21:14:12Z"
  }, {
    "description": "",
    "color": "",
    "verb": "",
    "name": "Ready for Dev",
    "num_stories": 223,
    "type": "unstarted",
    "updated_at": "2016-03-02T18:33:36Z",
    "id": 1493,
    "position": 2,
    "created_at": "2015-05-28T21:14:12Z"
  }, {
    "description": "",
    "color": "",
    "verb": "start",
    "name": "In Development",
    "num_stories": 23,
    "type": "started",
    "updated_at": "2015-06-16T17:53:09Z",
    "id": 1491,
    "position": 3,
    "created_at": "2015-05-28T21:14:12Z"
  }, {
    "description": "",
    "color": "",
    "verb": "",
    "name": "Ready for Review",
    "num_stories": 12,
    "type": "started",
    "updated_at": "2015-08-17T19:02:03Z",
    "id": 2199,
    "position": 4,
    "created_at": "2015-08-17T19:01:59Z"
  }, {
    "description": "",
    "color": "",
    "verb": "finish",
    "name": "Ready for Deploy",
    "num_stories": 29,
    "type": "started",
    "updated_at": "2015-07-30T15:49:24Z",
    "id": 1495,
    "position": 5,
    "created_at": "2015-05-28T21:14:12Z"
  }, {
    "description": "",
    "color": "",
    "verb": null,
    "name": "Completed",
    "num_stories": 1766,
    "type": "done",
    "updated_at": "2015-06-22T20:26:49Z",
    "id": 1494,
    "position": 6,
    "created_at": "2015-05-28T21:14:12Z"
  }]
}

*/

Collection.create('Workflow', exports);
exports.isValid = obj => {
  return obj && obj.id && obj.states;
};
exports.fetchAll = callback => {
  Backend.get('/api/private/workflows', {
    onComplete: res => {
      exports.fetchAllHandler(res, callback);
    }
  });
};
exports.settingsUrl = () => {
  return Url.getSlugPath() + '/settings/teams';
};
exports.refreshDetails = workflow => {
  return new Promise((resolve, reject) => {
    Backend.get('/api/private/workflows/' + workflow.id, {
      onComplete: res => {
        if (exports.isValid(res)) {
          exports.updateById(res);
          resolve(res);
        } else {
          reject(res);
        }
      }
    });
  });
};
exports.fetchAllHandler = (res, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  if (_.isArray(res)) {
    exports.trigger('bulkStart');
    ColumnModel.trigger('bulkStart');
    let hasWorkflowChanges = false;
    for (const workflow of res) {
      if (!exports.isValid(workflow)) continue;
      hasWorkflowChanges = hasWorkflowChanges || _detectWorkflowStateChanges(workflow);
      workflow.states = _.sortBy(workflow.states, 'position');
      for (const state of workflow.states) {
        state.workflow_id = workflow.id;
        if (ColumnModel.isValid(state)) ColumnModel.update(state);
      }
      exports.update(workflow);
    }
    _removeDeletedWorkflowStatesFromClientSide(res);
    ColumnModel.sortByPosition();
    if (hasWorkflowChanges) Event.trigger('workflowUpdated');
    ColumnModel.trigger('bulkEnd');
    exports.trigger('bulkEnd');
  }
  callback();
};
function _removeDeletedWorkflowStatesFromClientSide(allWorkflows) {
  const allColumns = ColumnModel.all();
  const workflowStatesByWorkflowId = new Map(allWorkflows.map(w => [w.id, new Set(w.states.map(s => s.id))]));
  for (const column of allColumns) {
    const workflowStates = workflowStatesByWorkflowId.get(column.workflow_id);
    if (!workflowStates || !workflowStates.has(column.id)) ColumnModel.remove(column);
  }
}
function _detectWorkflowStateChanges(workflow) {
  if (exports.size() === 0) return false;
  const newStates = workflow.states;
  const existingWorkflow = exports.getById(workflow.id);
  if (!existingWorkflow) return false;
  for (let i = 0; i < existingWorkflow.states.length; ++i) {
    const state = existingWorkflow.states[i];
    const newState = newStates[i];
    if (!newState || state.id !== newState.id || state.name !== newState.name || state.type !== newState.type) return true;
  }
  return false;
}
exports.getActive = teamContext => {
  const team = TeamModel.getActive(teamContext);
  return exports.getById(_.get(team, 'workflow.id')) || exports.first();
};
exports.updateDefaultState = (workflow, id, callback) => {
  Backend.put('/api/private/workflows/' + workflow.id, {
    data: {
      default_state_id: id
    },
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.updateAutoAssignOwner = (workflow, auto_assign_owner, callback) => {
  Backend.put('/api/private/workflows/' + workflow.id, {
    data: {
      auto_assign_owner
    },
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.isDefaultState = state => {
  const workflow = exports.getActive();
  return state.id === workflow.default_state_id;
};
exports.getDefaultState = () => {
  const workflow = exports.getActive();
  return ColumnModel.getById(workflow.default_state_id) || ColumnModel.firstFromActiveWorkflow();
};
exports.getDefaultStateForTeam = team => {
  const workflow = exports.getById(team.workflow.id);
  return ColumnModel.getById(workflow.default_state_id) || ColumnModel.filter({
    workflow_id: workflow.id
  })[0];
};
exports.getDefaultStateForWorkflow = workflowId => {
  const workflow = exports.getById(workflowId);
  return ColumnModel.getById(workflow.default_state_id) || ColumnModel.filter({
    workflow_id: workflow.id
  })[0];
};
exports.hasStateWithId = (workflow, stateId) => Boolean(workflow?.states?.some(state => state.id === stateId));
exports.getStateWithName = (workflow, stateName) => {
  return _.find(workflow.states, state => {
    return state.name.toLowerCase() === stateName.toLowerCase();
  });
};
exports.validateVerb = (workflow, str, column) => {
  if (!str) {
    return false;
  }
  const existing = _.find(workflow.states, {
    verb: str.toLowerCase()
  });
  let notCurrentColumn = true;
  if (existing && column) {
    notCurrentColumn = existing.id !== column.id;
  }
  if (str.toLowerCase() === 'branch') {
    return ['Branch is a reserved keyword which links all commits in a branch to a story.', 'Please use a different word.'].join(' ');
  } else if (existing && notCurrentColumn) {
    return 'This verb is already in use by the <strong>' + Format.sanitize(existing.name) + '</strong> state. ' + 'Please use a unique keyword.';
  } else if (/[^a-zA-Z_]/.test(str)) {
    return 'Verbs can only include alphabetical characters.';
  }
  return false;
};
exports.permittedWorkflowsForGroup = group => {
  const all = exports.all();
  if (!group) return all;
  return all.filter(workflow => group.workflow_ids.includes(workflow.id));
};
exports.getLabel = ({
  isPlural = false
}) => {
  return isPlural ? 'Workflow States' : 'Workflow State';
};
exports.getAvailableGroups = workflowId => {
  const groups = GroupModel.all();
  return groups.filter(group => !group.workflow_ids.includes(workflowId));
};
exports.getAssociatedGroups = workflowId => {
  const groups = GroupModel.all();
  return groups.filter(group => group.workflow_ids.includes(workflowId));
};
exports.hasStories = async workflowId => {
  const {
    pageInfo: {
      totalSize
    }
  } = await StoryModel.Promises.fetchRecentStoriesForWorkflowCount({
    id: workflowId
  });
  return totalSize > 0;
};
exports.hasStoryTemplates = workflowId => {
  const templates = StoryTemplateModel.all();
  const workflow = exports.getById(workflowId);
  return templates.some(template => {
    const templateWorkflowStateId = template.story_contents?.workflow_state_id;
    return exports.hasStateWithId(workflow, templateWorkflowStateId);
  });
};
export { exports as default };