import * as RecoveryCodeFormTemplate from 'app/client/login/views/templates/recoveryCodeForm.html?caveman';
import * as Event from '../../../core/js/_frontloader/event';
import LoginController from './login';
import Utils from '../../../core/js/modules/utils';
const exports = {};
exports.init = () => {
  exports.render();
  Utils.autoTabIndex($('#login-form'));
  $('#login-form input').eq(0).focus();
  Utils.fadePageIn();
};
exports.render = () => {
  const html = RecoveryCodeFormTemplate.render();
  $('#form-container').html(html);
  Event.trigger('pageRendered', 'RecoveryCodeForm');
};
exports.login = function () {
  const data = Utils.formData($('#login-form'));
  if (!data.username) {
    $('#login-name').focus();
    return false;
  } else {
    data.username = data.username.toLowerCase();
  }
  if (!data.password) {
    $('#login-password').focus();
    return false;
  }
  if (!data.recovery_code) {
    $('#login-recovery-code').focus();
    return false;
  }
  const button = $(this);
  LoginController.disableRecoveryButton(button);
  LoginController.attemptRecovery(data, {
    button
  });
  return false;
};
export { exports as default };