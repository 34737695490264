import Settings from 'app/client/core/js/modules/settings';
import Url from 'app/client/core/js/modules/url';
import View from 'app/client/core/js/modules/view';
import { ZohoCliq } from 'pages/integrations/ZohoCliq';
const exports = {};
exports.title = `Zoho Cliq Integration | ${BRAND.NAME}`;
exports.route = () => {
  return Url.getSlugPath() + '/settings/integrations/zoho-cliq';
};
exports.open = async () => {
  Settings.open({
    template: View.componentToTemplate(ZohoCliq, 'integrations-zoho-cliq'),
    route: exports.route,
    title: exports.title
  });
  return false;
};
export { exports as default };