import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.reduce.js";
/*
 * Don't use this module directly. Usage should happen via utils/monitoring (when possible).
 */

import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import { nanoid } from 'nanoid';
import { v3 as uuidv3 } from 'uuid';
import * as FeatureToggles from '@clubhouse/feature-toggles';
import * as ServiceWorkerUtils from 'components/shared/utils/serviceWorker';
import Backend from './backend';
import Is from './is';
import LocalStorage from './localStorage';
import * as PerformanceModule from './performance';
import Globals from '../_frontloader/globals';
import EpicModel from '../models/epic';
import GroupModel from '../models/group';
import IterationModel from '../models/iteration';
import LabelModel from '../models/label';
import MilestoneModel from '../models/milestone';
import ProfileModel from '../models/profile';
const exports = {};
const INSTRUMENT_STATE_KEY = 'instrument.stateKey';
const INSTRUMENT_LAST_DEPLOY_KEY = 'instrument.lastDeploy';
let wasDataCachedForInitialPageLoad;
const toValidValue = value => {
  if (isObject(value) || isArray(value)) {
    return JSON.stringify(value);
  }
  return value;
};
const prefixObjKeys = (prefix, obj, separator = '.') => {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    acc[prefix.concat(separator, key)] = toValidValue(value);
    return acc;
  }, {});
};
const handleVisibilityChange = () => {
  Object.values(getTraces()).forEach(trace => {
    if (trace.backgrounded === true) {
      return;
    }
    trace.backgrounded = document.hidden;
  });
};
exports.setViewConfig = (id, data) => {
  const trace = getTrace(id);
  if (!trace) {
    return;
  }
  setTrace(id, {
    ...trace,
    viewConfig: {
      ...data
    }
  });
};
const checkForNewDeploy = deployId => {
  const lastDeploy = LocalStorage.get(INSTRUMENT_LAST_DEPLOY_KEY);
  Globals.set('hasDeployIdChanged', lastDeploy !== deployId);
  LocalStorage.set(INSTRUMENT_LAST_DEPLOY_KEY, deployId);
};
exports.init = async ({
  deployId = process.env.DEPLOY_ID
} = {}) => {
  document.removeEventListener('visibilitychange', handleVisibilityChange);
  document.addEventListener('visibilitychange', handleVisibilityChange);
  if (deployId) {
    checkForNewDeploy(deployId);
  }
  wasDataCachedForInitialPageLoad = await ServiceWorkerUtils.hasCachedEntitiesOnInitialLoad();
};
const setTrace = (id, value) => {
  const traces = getTraces();
  traces[id] = value;
};
const getTrace = id => {
  return getTraces()[id];
};
const getAndRemoveTrace = id => {
  const traces = getTraces();
  const trace = traces[id];
  delete traces[id];
  return trace;
};
const getTraces = () => {
  return Globals.setOnlyIfMissing(INSTRUMENT_STATE_KEY, {});
};
exports.reset = () => {
  Globals.set(INSTRUMENT_STATE_KEY, {});
};
const start = (id, {
  chPage,
  hotReload,
  ...rest
}) => {
  const startTime = Math.round(window.performance.now());
  const traceId = uuidv3('' + startTime, uuidv3.URL);
  const trace = {
    startTime,
    traceId,
    backgrounded: document.hidden,
    hotReload,
    chPage,
    ...rest
  };
  setTrace(id, trace);
  return trace;
};

/**
 * Use startTimedBlock from utils/monitoring instead.
 * @deprecated
 */
exports.startIfUnstarted = (id, attributes) => {
  if (getTrace(id)) {
    return undefined;
  }
  return start(id, attributes);
};
const getMemoryObject = () => {
  if (!window.performance || !window.performance.memory) {
    return {};
  }
  const {
    usedJSHeapSize,
    totalJSHeapSize,
    jsHeapSizeLimit
  } = window.performance.memory;
  return {
    usedJSHeapSize,
    totalJSHeapSize,
    jsHeapSizeLimit
  };
};
const getConnectionObject = () => {
  if (!window.navigator || !window.navigator.connection) {
    return {};
  }
  const {
    downlink,
    effectiveType,
    rtt
  } = window.navigator.connection;
  return {
    downlink,
    effectiveType,
    rtt
  };
};
const adblockDetected = () => {
  return !window.ga; // google analytics
};

/**
 * Use startTimedBlock from utils/monitoring instead.
 * @deprecated
 */
exports.end = (id, extraData = {}) => {
  const trace = getAndRemoveTrace(id);
  if (!trace) {
    return;
  }
  const {
    startTime,
    hotReload,
    chPage,
    viewConfig,
    ...rest
  } = {
    ...trace,
    ...extraData
  };
  const timeSincePageLoad = PerformanceModule.getDurationSincePageLoad({
    name: chPage
  });
  const data = {
    startTime,
    hotReload,
    chPage,
    durationMs: hotReload === false ? timeSincePageLoad : Math.round(window.performance.now() - startTime),
    timeSincePageLoad,
    ...(hotReload === false ? PerformanceModule.getMeasuresToLog() : {}),
    ...(viewConfig && prefixObjKeys('viewConfig', viewConfig, '_')),
    ...rest
  };
  exports.log({
    data
  });
};
const getCommonLogData = () => {
  return {
    ...prefixObjKeys('featureToggles', FeatureToggles.getAllFeatureToggles()),
    ...prefixObjKeys('connection', getConnectionObject()),
    ...getMemoryObject(),
    appInstanceId: Globals.setOnlyIfMissing('instrument.appInstanceId', nanoid()),
    deployId: window._CLUBHOUSE_DEPLOY_ID,
    hasDeployIdChanged: Globals.get('hasDeployIdChanged'),
    adblockDetected: adblockDetected(),
    path: window.location.pathname,
    mobile: Is.mobile(),
    hostname: window.location.hostname,
    ...prefixObjKeys('entityCounts', getEntityCounts(), '_'),
    wasDataCachedForInitialPageLoad: toValidValue(wasDataCachedForInitialPageLoad)
  };
};
const getEntityCounts = () => {
  return {
    members: ProfileModel.size(),
    epics: EpicModel.size(),
    groups: GroupModel.size(),
    milestones: MilestoneModel.size(),
    labels: LabelModel.size(),
    iterations: IterationModel.size()
  };
};
exports.log = ({
  data,
  onComplete
}) => {
  Backend.post('/log', {
    data: {
      ...getCommonLogData(),
      ...data
    },
    onComplete
  });
};
export { exports as default };