import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import EventDefinitionModel from 'app/client/core/js/models/eventDefinition';
import { useEntities } from './collection';
export const saveNew = state => new Promise((resolve, reject) => {
  EventDefinitionModel.saveNew(state, (err, res) => {
    if (err) reject(err);else resolve(res);
  });
});
export const getTypesForProvider = type => {
  return EventDefinitionModel.typesForProvider(type);
};
export const updateWorkflowState = (eventDefinition, workflowStateId) => new Promise((resolve, reject) => {
  EventDefinitionModel.updateWorkflowState(eventDefinition, workflowStateId, (err, res) => {
    if (err) reject(err);else resolve(res);
  });
});
export const useDefinitionsForWorkflowState = workflowStateId => {
  const {
    entities: eventDefinitions
  } = useEntities({
    model: EventDefinitionModel
  });
  return eventDefinitions.filter(definition => definition.workflow_state_id === workflowStateId);
};
export const useDefinitionsForType = type => {
  const {
    entities: eventDefinitions
  } = useEntities({
    model: EventDefinitionModel
  });
  return eventDefinitions.filter(def => def.type.match('^' + type)).sort((a, b) => a.type.localeCompare(b.type));
};
export const getDefinitionsForWorkflowState = workflowState => EventDefinitionModel.getDefinitionsForWorkflowState(workflowState);
export const deleteEventDefinition = eventDef => new Promise((resolve, reject) => {
  EventDefinitionModel.deleteEventDefinition(eventDef, err => {
    if (err) reject(err);else resolve();
  });
});
export const deleteEventDefinitions = async eventDefs => {
  for (const eventDef of eventDefs) {
    await deleteEventDefinition(eventDef);
  }
};